import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import logo from "../assets/manyseeds-logo.png";
import { CircularProgress, Grid, Button } from "@material-ui/core";
import {
  AlertBar,
  LoginBoxContainer,
  WelcomeText,
} from "../components/common";
import { useAdminAuth } from "../hooks/useAdminAuth";
import { useError } from "../hooks/useError";

export function ResetPassword() {
  const { hashed, token, source } = useParams<{ token: string, hashed: string, source?: string }>();
  const { resetPassword, isAdminLoggedIn } = useAdminAuth()
  const history = useHistory();

  const { register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    }
  });

  const { addError } = useError();

  const [resetAppPasswordComplete, setResetAppPasswordComplete] = useState<boolean>(false);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmError, setShowConfirmError] = useState(false)
  const [showPasswordError, setShowPasswordError] = useState(false)
  const [showPasswordWarning, setShowPasswordWarning] = useState(false)
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [resetSuccess, setResetSuccess] = useState<Boolean | undefined>()

  useEffect(() => {
    if (isAdminLoggedIn) {
      history.push('/admin/dashboard')
    }
  }, [isAdminLoggedIn])

  const handleClick = async (data: any) => {

    console.log('Data', data);

    setShowPasswordError(false)
    setShowConfirmError(false)
    setShowPasswordWarning(false)

    let hasErrors = false

    if (!data.password || data.password.length < 6) {
      hasErrors = true
      setShowPasswordError(true)
    }

    if (!data.confirmPassword) {
      hasErrors = true
      setShowConfirmError(true)
    }

    if (data.password !== data.confirmPassword) {
      hasErrors = true
      setShowPasswordWarning(true)
    }

    if (hasErrors) {
      return
    }

    if (data.password && data.confirmPassword && data.password === data.confirmPassword) {
      setShowLoading(true)
      const results = await resetPassword(token, hashed, data.password)
      setShowLoading(false)

      if (results.data && results.data.passwordReset !== undefined) {
        if (results.data.passwordReset) {
          if (source && source === "app") {
            setResetAppPasswordComplete(true);
            setResetSuccess(true);
            return;
          }
          addError("success", "Your password has been reset. You can now login using your new credentials.");
          history.push('/admin/login/')
          return
        }
        setResetSuccess(results.data.passwordReset)
      }
      setPassword("")
      setConfirmPassword("")
    }
  };

  if (!token) {
    return (
      <LoginBoxContainer>
        <div className="login-box">
          <img src={logo} className="logo" alt="Many Seeds" />
          <WelcomeText>This is embarrassing!</WelcomeText>
          <p>It seems that an error was encountered. Please contact and administrator for assistance.</p>
        </div>
      </LoginBoxContainer>
    )
  }

  return (
    <form name="ResetPassword" onSubmit={handleSubmit(handleClick)}>
      <LoginBoxContainer>
        <div className="login-box">
          <img src={logo} className="logo" alt="Many Seeds" />
          <WelcomeText>Reset Password</WelcomeText>
          {resetSuccess === true ?
            <AlertBar type="success" message="Password reset successful. You can now log in using your new password" />
            :
            (resetSuccess === false &&
              <AlertBar
                type="error"
                message="Failed to reset password."
              />
            )
          }
          {!resetAppPasswordComplete &&
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <label htmlFor="input-new-pw">New Password</label>
                  <input id="input-new-pw" type="password" {...register("password", { minLength: { value: 6, message: "Password must be a minimum of 6 characters long" } })} />
                  {errors?.password && <p className="error">Your password must be a minimum of 6 characters long.</p>}
                </Grid>
                <Grid item xs={12}>
                  <label htmlFor="input-new-pw">Confirm New Password</label>
                  <input id="input-new-pw" type="password" {...register("confirmPassword", { minLength: { value: 6, message: "Password must be a minimum of 6 characters long" } })} />
                  {errors?.confirmPassword && <p className="error">Your password must be a minimum of 6 characters long.</p>}
                </Grid>
              </Grid>
              <div style={{ position: "relative", margin: "4em 0", textAlign: "center" }}>
                <Button disabled={showLoading} type="submit" variant="contained" color="primary">
                  {showLoading && <CircularProgress size={12} color="secondary" style={{ marginRight: "10px" }} />}<span>Submit</span>
                </Button>
              </div>
            </>
          }
        </div>
      </LoginBoxContainer>
    </form>
  );
}
