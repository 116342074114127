import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
} from '@material-ui/core';
import { useError } from '../../hooks/useError';
import { useForm, Controller } from 'react-hook-form';
import { FileUploader } from '../../components/common/FileUploader'
import { ContentHeader } from '../../components/common/ContentHeader'

import {
    GetTagsQuery, GetTagsQueryVariables,
    GetMyNotForProfitQuery, GetMyNotForProfitQueryVariables,
    UpdateNotForProfitMutation, UpdateNotForProfitMutationVariables,
    ChangeMyPasswordMutation, ChangeMyPasswordMutationVariables
} from '../../generated/graphql';
import { AlertBar } from '../../components/common';
import { Clear } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const GET_TAGS = loader('../../graphql/Tags/GetTags.graphql')
const GET_NFP_PROFILE = loader('../../graphql/NotForProfits/GetMyNotForProfit.graphql')
const UPDATE_PROFILE = loader('../../graphql/NotForProfits/UpdateNotForProfit.graphql')
const CHANGE_MY_PASSWORD = loader('../../graphql/Users/ChangeMyPassword.graphql')

export function NotForProfitProfile() {
    const { addError } = useError()
    const history = useHistory()

    const [changePasswordError, setChangePasswordError] = useState<{ "type": string, "message": string }>();

    const { data: nfpData, loading: nfpDataLoading, error: nfpDataError } = useQuery<GetMyNotForProfitQuery, GetMyNotForProfitQueryVariables>(GET_NFP_PROFILE, { fetchPolicy: "network-only" });
    const { data: nfpTagsData, loading: nfpTagsLoading } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS, { variables: { tagType: "nfp" } });
    //const { data: advertisementTagsData, loading: advertisementTagsLoading } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS, { variables: { tagType: "advertisement" } });
    const [updateProfile, { data: updateProfileResponse, loading: updateProfileLoading }] = useMutation<UpdateNotForProfitMutation, UpdateNotForProfitMutationVariables>(UPDATE_PROFILE,
        {
            refetchQueries: [GET_NFP_PROFILE],
            awaitRefetchQueries: true
        }
    );
    const [changeMyPassword, { loading: changePasswordLoading }] = useMutation<ChangeMyPasswordMutation, ChangeMyPasswordMutationVariables>(CHANGE_MY_PASSWORD);

    const [keywords, setKeywords] = useState<string[]>([]);
    const [currentKeyword, setCurrentKeyword] = useState<string>("");

    const { handleSubmit, control, setError } = useForm()
    const { handleSubmit: handleChangeSubmit, control: handleChangeControl, reset: resetChange } = useForm({
        shouldUnregister: false,
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: ""
        }
    })

    const [uploadFile, setUploadFile] = useState<File>();

    useEffect(() => {
        if (nfpDataError) {
            addError("error", "Error fetching data: " + nfpDataError.message);
            return;
        }
    }, [nfpDataError, addError])

    useEffect(() => {
        if (nfpData && nfpData.getMyNfp.keywords) {
            setKeywords(nfpData.getMyNfp.keywords);
        }
    }, [nfpData, setKeywords])

    const handleUpdateProfile = async (data: any) => {
        let builtTags: Array<{ tagId: number }> = [];
        if (data.tag) {
            data.tag.forEach((value: boolean, index: number) => {
                if (value) {
                    builtTags.push({ tagId: index })
                }
            })
        }

        // let builtAdvertisementTags: Array<{ tagId: number }> = [];
        // if (data.advertisementTag) {
        //     data.advertisementTag.forEach((value: boolean, index: number) => {
        //         if (value) {
        //             builtAdvertisementTags.push({ tagId: index })
        //         }
        //     })
        // }

        updateProfile({
            variables: {
                nfpData: {
                    name: data.name,
                    description: data.description,
                    city: data.city,
                    website: data.website,
                    facebookUrl: data.facebookUrl,
                    twitterUrl: data.twitterUrl,
                    instagramUrl: data.instagramUrl,
                    contactPhone: data.contactPhone,
                    contactEmail: data.contactEmail,
                    charitableNumber: data.charitableNumber,
                    donateLink: data.donateLink,
                    paypalEmail: data.paypalEmail,
                    keywords
                },
                logo: uploadFile,
                userData: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    email: data.email,
                },
                nfpTags: builtTags,
                //advertisementTags: builtAdvertisementTags
            },
            refetchQueries: [GET_NFP_PROFILE]
        })

    }

    const submitPasswordChange = (data: any) => {
        if (!data.currentPassword || !data.newPassword || !data.newPasswordConfirm || data.newPassword !== data.newPasswordConfirm) {
            setChangePasswordError(value => { return { type: "error", message: "Please be sure to complete all fields." } });
            return;
        }

        changeMyPassword({
            variables: {
                password: data.currentPassword,
                newPassword: data.newPassword
            }
        }).then(resp => {
            if (resp.data?.changeMyPassword === true) {
                setChangePasswordError(value => { return { type: "success", message: "Password updated successfully" } });
                resetChange();
                return;
            }
            setChangePasswordError(value => { return { type: "error", message: "Error updating password" } });
            resetChange();
        }).catch(error => {
            setChangePasswordError(value => { return { type: "error", message: error.message } });
            resetChange();
        });
    }

    useEffect(() => {
        if (updateProfileResponse) {
            if (updateProfileResponse.updateNfp.response === "success") {
                addError("success", "Successfully updated Profile");
                history.push('/admin/dashboard')
                return
            }

            addError("error", "Unable to save Profile. Please check errors and try again.")
            if (updateProfileResponse.updateNfp.errors) {
                updateProfileResponse.updateNfp.errors.forEach((value: any, index: number) => {
                    setError(value.field, { message: value.message })
                })
            }
        }
    }, [updateProfileResponse, history, addError, setError])

    const addKeyword = (e: any) => {
        if (e.keyCode !== 13) { return }

        e.preventDefault();
        if (!e.target.value.trim()) { return }

        setKeywords(kws => {
            const tempArray = [...kws, e.target.value.trim()];
            return [...tempArray];
        });
        setCurrentKeyword("");
    }

    const removeKeyword = (index: number) => {
        setKeywords(kws => {
            const tempKws: string[] = [...kws];
            tempKws.splice(index, 1);
            return [...tempKws];
        });
    }

    if (!nfpDataLoading && !nfpData) {
        return <>Error Loading Profile</>
    }

    return <>
        <Grid container>
            <Grid item xs={12}>
                <ContentHeader
                    title="Update Profile"
                    loading={nfpDataLoading || nfpTagsLoading}
                />
                {nfpData && nfpData.getMyNfp.registrationComplete === 0 &&
                    <AlertBar
                        type="info"
                        message="You must complete your Organization profile before continuing."
                    />
                }
            </Grid>
            {nfpData && nfpTagsData &&
                <Paper className="form-paper">
                    <form onSubmit={handleSubmit(handleUpdateProfile)} name="CreateBanner">
                        <h3>Profile</h3>
                        <Grid item xs={12} container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={nfpData?.getMyNfp.name}
                                    render={({ field }) =>
                                        <TextField label="Organization Name" margin="dense" fullWidth required {...field} />
                                    }
                                />
                                <Controller
                                    name="charitableNumber"
                                    control={control}
                                    defaultValue={nfpData?.getMyNfp.charitableNumber}
                                    render={({ field }) =>
                                        <TextField label="Registered Not-for-Profit #" margin="dense" fullWidth {...field} />
                                    }
                                />
                                <Controller
                                    name="city"
                                    control={control}
                                    defaultValue={nfpData?.getMyNfp.city}
                                    render={({ field }) =>
                                        <TextField label="Region" margin="dense" fullWidth {...field} />
                                    }
                                />
                                <Controller
                                    name="description"
                                    control={control}
                                    defaultValue={nfpData?.getMyNfp.description}
                                    render={({ field }) =>
                                        <TextField label="Description" margin="dense" fullWidth multiline rows={5} maxRows={10} {...field} />
                                    }
                                />
                                <Controller
                                    name="contactPhone"
                                    control={control}
                                    defaultValue={nfpData?.getMyNfp.contactPhone}
                                    render={({ field }) => <TextField label="Public Contact Phone" inputMode="tel" fullWidth margin="dense" {...field} />}
                                />
                                <Controller
                                    name="contactEmail"
                                    control={control}
                                    defaultValue={nfpData?.getMyNfp.contactEmail}
                                    render={({ field }) => <TextField label="Public Contact Email" inputMode="email" fullWidth margin="dense" {...field} />}
                                />
                                <Controller
                                    name="website"
                                    control={control}
                                    defaultValue={nfpData?.getMyNfp.website}
                                    render={({ field }) => <TextField label="Website" fullWidth margin="dense" {...field} />}
                                />
                                <Controller
                                    name="facebookUrl"
                                    control={control}
                                    defaultValue={nfpData?.getMyNfp.facebookUrl}
                                    render={({ field }) => <TextField label="Facebook URL" fullWidth margin="dense" {...field} />}
                                />
                                <Controller
                                    name="twitterUrl"
                                    control={control}
                                    defaultValue={nfpData?.getMyNfp.twitterUrl}
                                    render={({ field }) => <TextField label="Twitter URL" fullWidth margin="dense" {...field} />}
                                />
                                <Controller
                                    name="instagramUrl"
                                    control={control}
                                    defaultValue={nfpData?.getMyNfp.instagramUrl}
                                    render={({ field }) => <TextField label="Instagram URL" fullWidth margin="dense" {...field} />}
                                />
                                <Controller
                                    name="donateLink"
                                    control={control}
                                    defaultValue={nfpData?.getMyNfp.donateLink}
                                    render={({ field }) => <TextField label="Donate Link" fullWidth margin="dense" {...field} />}
                                />
                                <TextField
                                    label="Keywords"
                                    value={currentKeyword}
                                    onChange={e => setCurrentKeyword(e.currentTarget.value)}
                                    onKeyDown={addKeyword}
                                    fullWidth
                                    margin="dense"
                                    helperText="Type a keyword, and press enter to add to list"
                                />
                                <div className="keyword-list">
                                    {
                                        keywords.map((value: any, index: number) => {
                                            return <div key={index}>
                                                {value} <IconButton size="small" onClick={e => removeKeyword(index)}><Clear htmlColor="#ff0000" /></IconButton>
                                            </div>
                                        })
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <h2>Logo</h2>
                                <FormControl style={{ marginBottom: "1em" }}>
                                    {nfpData && nfpData.getMyNfp.logo &&
                                        <div className="profile-image">
                                            <img
                                                src={`${process.env.REACT_APP_S3_ENDPOINT}/charities/${nfpData.getMyNfp.logo}`}
                                                alt=""
                                                style={{ maxWidth: "100%", maxHeight: "350px" }}
                                            />
                                        </div>
                                    }
                                    <FileUploader
                                        accept={"image/png,image/jpeg,image/gif"}
                                        handleDrop={(files: FileList) => {
                                            setUploadFile(files[0])
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <h3>Account and Contact Details</h3>
                        <Grid item xs={12} container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={nfpData?.getMyNfp.user?.firstName}
                                    render={({ field }) =>
                                        <TextField label="Contact First Name" margin="dense" fullWidth {...field} />
                                    }
                                />
                                <Controller
                                    name="lastName"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={nfpData?.getMyNfp.user?.lastName}
                                    render={({ field }) =>
                                        <TextField label="Contact Last Name" margin="dense" fullWidth {...field} />
                                    }
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={nfpData?.getMyNfp.user?.userEmail}
                                    render={({ field }) =>
                                        <TextField label="User Email" margin="dense" fullWidth {...field} />
                                    }
                                />
                            </Grid>
                        </Grid>
                        <h3>PayPal Information</h3>
                        <Grid item xs={12} container spacing={4}>
                            <Grid item xs={12}>
                                <Controller
                                    name="paypalEmail"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={nfpData?.getMyNfp.paypalEmail}
                                    render={({ field }) =>
                                        <TextField label="Paypal Email" margin="dense" fullWidth {...field} />
                                    }
                                />
                            </Grid>
                        </Grid>
                        <div style={{ display: "none" }}>
                            <h3>Categorize Your Organization</h3>
                            <Grid item xs={12} container spacing={4}>
                                <Grid item xs={12}>
                                    {nfpTagsData?.getTags.map((tag: any) => {
                                        let searchMyData: any;
                                        if (nfpData?.getMyNfp.tags) {
                                            searchMyData = nfpData?.getMyNfp.tags.filter((value: any, index: any) => {
                                                return (value.id === tag.id)
                                            })
                                        }

                                        return <FormControl>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name={`tag[${tag.id}]`}
                                                        control={control}
                                                        defaultValue={searchMyData.length > 0}
                                                        render={({ field: props }) =>
                                                            <Checkbox
                                                                {...props}
                                                                checked={props.value}
                                                                onChange={e => props.onChange(e.target.checked)}
                                                            />
                                                        }
                                                    />
                                                }
                                                label={tag.name}
                                            />
                                        </FormControl>
                                    })}
                                </Grid>
                            </Grid>
                        </div>
                        {/* <h3>Advertisement Interests</h3>
                        <Grid item xs={12} container spacing={4}>
                            <Grid item xs={12}>
                                {advertisementTagsData?.getTags.map((tag: any) => {
                                    let searchMyData: any;
                                    if (nfpData?.getMyNfp.advertisementTags) {
                                        searchMyData = nfpData?.getMyNfp.advertisementTags.filter((value: any, index: any) => {
                                            return (value.id === tag.id)
                                        })
                                    }

                                    return <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name={`advertisementTag[${tag.id}]`}
                                                    control={control}
                                                    defaultValue={searchMyData.length > 0}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => props.onChange(e.target.checked)}
                                                        />
                                                    }
                                                />
                                            }
                                            label={tag.name}
                                        />
                                    </FormControl>
                                })}
                            </Grid>
                        </Grid> */}
                        <div style={{ marginTop: "2rem" }}>
                            <Button disabled={updateProfileLoading} type="submit" color="primary" variant="contained"
                            >{updateProfileLoading && <CircularProgress size={11} style={{ marginRight: "11px" }} />}Submit</Button>
                        </div>
                    </form>
                    <div style={{ marginTop: "4em" }}>
                        <form onSubmit={handleChangeSubmit(submitPasswordChange)} name="UpdatePassword">
                            <h3>Security</h3>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <p style={{ marginBottom: 0 }}>You can change your password using the fields below.</p>
                                    {
                                        changePasswordError ?
                                            (changePasswordError.type === "error" ?
                                                <div style={{ border: "2px solid red", borderRadius: "3px", fontSize: "1.125em", padding: "5px", backgroundColor: "#ffeeee;" }}>
                                                    {changePasswordError.message}
                                                </div>
                                                :
                                                <div style={{ border: "2px solid green", borderRadius: "3px", fontSize: "1.125em", padding: "5px", backgroundColor: "#eeffee;" }}>
                                                    {changePasswordError.message}
                                                </div>
                                            )
                                            :
                                            ""
                                    }
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Controller
                                        name="currentPassword"
                                        defaultValue={""}
                                        control={handleChangeControl}
                                        render={({ field }) =>
                                            <TextField
                                                label="Current Password"
                                                fullWidth
                                                type="password"
                                                {...field}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Controller
                                        name="newPassword"
                                        defaultValue={""}
                                        control={handleChangeControl}
                                        render={({ field }) =>
                                            <TextField
                                                label="New Password"
                                                fullWidth
                                                type="password"
                                                {...field}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Controller
                                        name="newPasswordConfirm"
                                        defaultValue={""}
                                        control={handleChangeControl}
                                        render={({ field }) =>
                                            <TextField
                                                label="Confirm New Password"
                                                fullWidth
                                                type="password"
                                                {...field}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit" disabled={changePasswordLoading}>
                                        {changePasswordLoading && <CircularProgress size={11} style={{ marginRight: "11px" }} />}Change Password
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Paper>
            }
        </Grid>
    </>
}
