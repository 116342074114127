import gql from 'graphql-tag'

export const GetActiveUsers = gql`
query AdminDashboardActiveUsers($dateRangeStart: DateTime, $dateRangeEnd: DateTime) {
    adminDashboardActiveUsers(dateRangeStart: $dateRangeStart, dateRangeEnd: $dateRangeEnd) {
        all,
        inactive,
        active,
        banned,
        awaitingApproval,
        new
    }
}`;

export const GetAdvertisers = gql`
query AdminDashboardAdvertisers($dateRangeStart: DateTime, $dateRangeEnd: DateTime) {
    adminDashboardAdvertisers(dateRangeStart: $dateRangeStart, dateRangeEnd: $dateRangeEnd) {
        all,
        inactive,
        active,
        banned,
        awaitingApproval,
        new
    }
}`;

export const GetNotForProfits = gql`
query AdminDashboardNotForProfits($dateRangeStart: DateTime, $dateRangeEnd: DateTime) {
    adminDashboardNotForProfits(dateRangeStart: $dateRangeStart, dateRangeEnd: $dateRangeEnd) {
        all,
        inactive,
        active,
        banned,
        awaitingApproval,
        new
    }
}`;

export const GetAdPerformance = gql`
    query AdminDashboardAdPerformance($dateRangeStart: DateTime, $dateRangeEnd: DateTime) {
        adminDashboardAdPerformance(dateRangeStart: $dateRangeStart, dateRangeEnd: $dateRangeEnd) {
            dates,
            labels,
            stats {
                start,
                #midpoint,
                #firstquartile,
                #thirdquartile,
                complete,
                #mute,
                replay,
                #fullscreen,
                #stop,
                #unmute,
                #resume,
                #pause
            }
        }
    }
`;