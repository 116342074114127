import React from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import logoDark from '../assets/manyseeds-logo-dkblue.png';
import { NavLink } from 'react-router-dom';
import { ExitToApp } from '@material-ui/icons';
import { useAdminAuth } from '../hooks/useAdminAuth';
import { UserRole } from '../graphql/Fragments/User.enum';
import { loader } from 'graphql.macro';

import { AppIsAppActiveQuery, AppIsAppActiveQueryVariables } from '../generated/graphql';
import { useQuery } from '@apollo/client';
const GET_APP_STATUS_QUERY = loader('../graphql/Dashboard/GetAppActive.graphql')

export function HeaderBar() {
    const { me } = useAdminAuth()

    const { data: appStatus, loading: appStatusLoading } = useQuery<AppIsAppActiveQuery, AppIsAppActiveQuery>(GET_APP_STATUS_QUERY);

    return (
        <header>
            <Grid className="header-grid" container>
                <Grid className="logo-container" item>
                    <NavLink to="/admin/dashboard"><img src={logoDark} style={{ height: 30 }} alt="Many Seeds" /></NavLink>
                </Grid>
                <Grid className="right-container" item container>
                    <Grid item xs={6}>
                        {/* {document.title.replace(/manyseeds|::| - -/i, '').trim()} */}
                        {me?.userRole === UserRole.ADMIN &&
                            <div style={{ color: "white" }}>App Status: {
                                appStatus ?
                                    (
                                        appStatus.appIsAppActive === true ?
                                            <span style={{ color: "green" }}><b>Active</b></span>
                                            :
                                            <span style={{ color: "red" }}><b>Inactive</b></span>
                                    )
                                    :
                                    'Checking...'
                            }
                            </div>
                        }
                    </Grid>
                    <Grid item xs={6} className="options-container">
                        <div className="logged-in-as">
                            <span>{me?.userEmail}</span> <div className={`account-type ${me?.userRole}`}>
                                {me?.userRole === UserRole.ADMIN && "Administrator"}
                                {me?.userRole === UserRole.ADVERTISER && "Advertiser"}
                                {me?.userRole === UserRole.NFP && "Not For Profit"}
                            </div>
                        </div>
                        <Tooltip title="Log Out"><NavLink to="/logout"><ExitToApp /></NavLink></Tooltip>
                    </Grid>
                </Grid>
            </Grid >
        </header >
    )
}