import React from 'react'
import {
    Box,
    Tab as MuiTab,
    Tabs as MuiTabs,
    Typography,
    withStyles,
} from '@material-ui/core'

interface TabPanelProps {
    children?: React.ReactNode
    index: any
    value: any
}

export const Tab = withStyles({
    root: {
        
    }
    //Just doing this pre-emptively, as I predict they will get styled somehow
})(MuiTab);

export const Tabs = withStyles({
    root: {
        borderBottom: 'solid #999999 1px',
        margin:"-10px -25px 15px -25px",
        "&.no-margin": {
            margin:0
        }
    },
})(MuiTabs)

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}