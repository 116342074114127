import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useError } from '../../hooks/useError';
import { useForm, Controller } from 'react-hook-form';

import {
    CreateAdvertiserMutation, CreateAdvertiserMutationVariables,
    GetTagsQuery, GetTagsQueryVariables
} from '../../generated/graphql';
import { ContentHeader } from '../../components/common/ContentHeader';
import { UserStatus } from '../../graphql/Fragments/User.enum';

const CREATE_ADVERTISER_MUTATION = loader('../../graphql/Advertisers/CreateAdvertiser.graphql')
const GET_TAGS_QUERY = loader('../../graphql/Tags/GetTags.graphql')

export function CreateAdvertiser() {
    document.title = "Advertiser Details - ManySeeds"
    const history = useHistory()
    const [addMode, setAddMode] = useState<"add" | "disabled">("add");

    const { addError } = useError()
    const { control, handleSubmit, reset, clearErrors, setError } = useForm();

    const [showPassword, setShowPassword] = useState(false);

    const [createAdvertiser, { data: createAdvertiserData, loading: createAdvertiserLoading, error: createAdvertiserError }] = useMutation<CreateAdvertiserMutation, CreateAdvertiserMutationVariables>(CREATE_ADVERTISER_MUTATION);
    const { data: tagsData, loading: tagsLoading } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS_QUERY, { variables: { tagType: "advertiser" } });

    useEffect(() => {
        //Unmount - destroy the data object
        return () => {
            setAddMode("add");
            reset()
        }
    }, [])

    const handleAddAdvertiser = async (data: any) => {
        console.log('handleAddAdvertiser', data);

        if (addMode === "disabled") {
            addError("error", "Save is disabled due to an encountered error.")
            return
        }

        let builtTags: Array<{ tagId: number }> = [];
        if (data.tag) {
            data.tag.forEach((value: boolean, index: number) => {
                if (value) {
                    builtTags.push({ tagId: index })
                }
            })
        }

        // creating
        createAdvertiser({
            variables: {
                name: data.advertiserName,
                contactPhone: data.contactPhone,
                contactEmail: data.contactEmail,
                costPerThousandImpressions: parseFloat(data.costPerThousandImpressions),
                manyseedsPortion: parseFloat(data.manyseedsPortion),
                email: data.userEmail,
                firstName: data.firstName,
                lastName: data.lastName,
                password: data.password,
                status: data.status,
                sendEmail: (data.sendEmail ? data.sendEmail : false),
                tags: builtTags
            }
        })
    }

    useEffect(() => {
        console.log('createUser Responses', createAdvertiserLoading, createAdvertiserError, createAdvertiserData)
    }, [createAdvertiserLoading, createAdvertiserError, createAdvertiserData])

    const handleComplete = (method?: string) => {
        clearErrors();
        reset();
        history.push('/admin/advertisers/')
    }

    useEffect(() => {
        if (createAdvertiserData) {
            if (createAdvertiserData.createAdvertiser.response === "success") {
                addError("success", "Successfully saved Advertiser");
                handleComplete();
                return
            }

            addError("error", "Unable to save Advertiser. Please check errors and try again.")
            if (createAdvertiserData.createAdvertiser.errors) {
                createAdvertiserData.createAdvertiser.errors.forEach((value: any, index: number) => {
                    setError(value.field, { message: value.message });
                })
            }
        }
    }, [createAdvertiserData, addError])

    return <>
        <form style={{ width: "100%" }} onSubmit={handleSubmit(handleAddAdvertiser)} name="CreateAdvertiser">
            <Grid container>
                <Grid item xs={12}>
                    <ContentHeader
                        title="Create Advertiser"
                        options={[
                            { text: "Return to Advertiser", action: (e: any) => history.go(-1) }
                        ]}
                        loading={tagsLoading}
                    />
                </Grid>
                <Paper className="form-paper">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="advertiserName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField label="Advertiser Name" required fullWidth {...field} />}
                            />
                            <Controller
                                name="userEmail"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField label="User Email" required fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <TextField label="Contact First Name" required fullWidth margin="dense" {...field} />
                                }
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <TextField label="Contact Last Name" required fullWidth margin="dense" {...field} />
                                }
                            />
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: true, minLength: 6 }}
                                render={({ field }) =>
                                    <TextField label="Password" required
                                        type={showPassword ? "text" : "password"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={(e) => setShowPassword((p) => !p)}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                    >
                                                        {showPassword ? (
                                                            <Visibility htmlColor="#AEAEAE" />
                                                        ) : (
                                                            <VisibilityOff htmlColor="#2E2E2E" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth margin="dense" {...field} />
                                }
                            />
                            {addMode === "add" &&
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name="sendEmail"
                                                control={control}
                                                defaultValue={true}
                                                render={({ field }) =>
                                                    <Checkbox
                                                        color="primary"
                                                        {...field}
                                                    />
                                                }
                                            />
                                        }
                                        label="Send Account Email"
                                    />
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="contactEmail"
                                defaultValue={""}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        label="Public Contact Email"
                                        inputMode="email"
                                        fullWidth
                                        {...field}
                                    />
                                }
                            />
                            <Controller
                                name="contactPhone"
                                defaultValue={""}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        label="Public Contact Phone"
                                        inputMode="tel"
                                        fullWidth
                                        margin="dense"
                                        {...field}
                                    />
                                }
                            />
                            <Controller
                                name="costPerThousandImpressions"
                                defaultValue={0.25}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        label="Default Amount Paid Per Ad View ($)"
                                        inputMode="decimal"
                                        fullWidth
                                        margin="dense"
                                        {...field}
                                    />
                                }
                            />
                            <Controller
                                name="manyseedsPortion"
                                defaultValue={20}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        label="Default Many Seeds Portion(%)"
                                        inputMode="decimal"
                                        fullWidth
                                        margin="dense"
                                        {...field}
                                    />
                                }
                            />
                            <Controller
                                name="status"
                                defaultValue={UserStatus.ACTIVE}
                                control={control}
                                render={({ field }) => <TextField select label="Status" fullWidth margin="dense" {...field}
                                    onChange={e => field.onChange(e.target.value)}
                                >
                                    <MenuItem key={UserStatus.ACTIVE} value={UserStatus.ACTIVE}>Active</MenuItem>
                                    <MenuItem key={UserStatus.INACTIVE} value={UserStatus.INACTIVE}>Inactive</MenuItem>
                                    <MenuItem key={UserStatus.SUSPENDED} value={UserStatus.SUSPENDED}>Suspended</MenuItem>
                                    <MenuItem key={UserStatus.BANNED} value={UserStatus.BANNED}>Banned</MenuItem>
                                    <MenuItem key={UserStatus.AWAITING_APPROVAL} value={UserStatus.AWAITING_APPROVAL}>Awaiting Approval</MenuItem>
                                </TextField>
                                }
                            />
                        </Grid>
                    </Grid>
                    <h3>Tags</h3>
                    <Grid container xs={12} spacing={4}>
                        <Grid item xs={12}>
                            {tagsData?.getTags.map((tag: any) => {
                                return <FormControl key={tag.id}>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={`tag[${tag.id}]`}
                                                control={control}
                                                defaultValue={false}
                                                render={({ field: props }) =>
                                                    <Checkbox
                                                        {...props}
                                                        checked={props.value}
                                                        onChange={e => {
                                                            props.onChange(e.target.checked)
                                                        }}
                                                    />
                                                }
                                            />
                                        }
                                        label={tag.name}
                                    />
                                </FormControl>
                            })}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button disabled={
                                createAdvertiserLoading || addMode === "disabled"
                            } type="submit" color="primary" variant="contained">{createAdvertiserLoading && <CircularProgress size={11} style={{ marginRight: "10px" }} />}Submit</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </form>
    </>
}