import React, { useEffect } from "react";
import logo from "../assets/manyseeds-logo.png";
import { Button } from "@material-ui/core";
import { AlertBar, LoginBoxContainer, WelcomeText } from "../components/common";
import { useAdminAuth } from "../hooks/useAdminAuth";

export function Logout() {
    const { logout, isAdminLoggedIn, logoutLoading } = useAdminAuth()

    useEffect(() => {
        if(isAdminLoggedIn) {
            logout()
        }
    }, [isAdminLoggedIn, logout])

    return (
        <LoginBoxContainer>
            <div className="login-box">
                <img src={logo} className="logo" alt="Many Seeds" />
                <WelcomeText>Goodbye!</WelcomeText>
                {/* <img alt="Logo" src={logo} /> */}
                {isAdminLoggedIn || logoutLoading ? 
                    <AlertBar type="info" title="Logging Out" message="Please Wait" showLoader={true} />
                    :
                    <>
                        <AlertBar type="success" title="Logged Out" message="You are now logged out" />
                        <Button href="/login" color="primary" variant="contained">Return to Login</Button>
                    </>
                }
            </div>
        </LoginBoxContainer>
    )
}
