import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat', 
      'sans-serif'
    ].join(','),
  },
  palette: {
    background:{
      default:"#e4e4e4"
    },
    primary: {
      main: '#022e3f'
    }, 
    secondary: {
      main: '#00c98f',
    },
    text: {
      primary: "rgba(0,0,0,0.9)",
      secondary: "#00c98f"
    },
    error: {
      main: '#CE0E2D'
    },
    divider: "#00000040"
  },
})