import React, { FormEvent, useEffect, useState } from "react";
import logo from "../assets/manyseeds-logo.png";
import { Button, CircularProgress, Link, Grid, Tooltip, FormHelperText, Input } from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  AlertBar,
  LoginBoxContainer,
  LoginBoxFooter,
  WelcomeText,
} from "../components/common";
import { useAdminAuth } from "../hooks/useAdminAuth";
import { isEmail } from "../utils/validation";
import { useError } from "../hooks/useError";

export function Login() {
  const history = useHistory();
  const { addError } = useError();
  const [error, setError] = useState(false)
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const { adminLogin, isAdminLoggedIn, adminLoginFailed, adminLoginLoading } = useAdminAuth()

  const [emailError, setEmailError] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<boolean>(false)

  useEffect(() => {
    setError(adminLoginFailed)
  }, [adminLoginFailed])

  useEffect(() => {
    setShowLoading(adminLoginLoading)
  }, [adminLoginLoading])

  if (isAdminLoggedIn) {
    history.push('/admin/dashboard')
  }

  const handleClick = (e: FormEvent) => {
    e.preventDefault()
    setEmailError(false)
    setPasswordError(false)

    let hasErrors = false
    if (!email || !isEmail(email)) {
      setEmailError(true)
      hasErrors = true
    }
    if (!password) {
      setPasswordError(true)
      hasErrors = true
    }

    if (email && password && !hasErrors) {
      try {
        adminLogin(email, password)
      } catch (error) {
        //console.log('Login Error', error)
      }
    }
  };

  return (
    <form name="LoginForm" onSubmit={handleClick}>
      <LoginBoxContainer>
        <div className="login-box">
          <WelcomeText>Welcome!</WelcomeText>
          {error && <AlertBar type="error" message="Invalid email or password" />}
          <div>
            <label htmlFor="username">Email</label>
            <input
              type="text"
              name="username"
              id="username"
              value={email}
              onChange={(e) => {
                const newEmail = e.target.value.trim().toLowerCase();
                setEmail(newEmail);
              }}
              autoFocus={true}
              required={true}
            />
            {emailError && <FormHelperText error={emailError}>You must enter a valid email address</FormHelperText>}
          </div>
          <div>
            <label htmlFor="passwd">Password</label>
            <input
              type="password"
              id="passwd"
              required={true}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
            {passwordError && <FormHelperText error={passwordError}>You must enter a password.</FormHelperText>}
          </div>
          <div style={{ position: "relative", margin: "3em 0", textAlign: "center" }}>
            <Button disabled={showLoading} type="submit" variant="contained" color="primary">
              {showLoading && <CircularProgress size={12} color="secondary" style={{ marginRight: "10px" }} />}<span>Log In</span>
            </Button>
            <p style={{ textAlign: "center", position: "relative" }}>
              <span style={{ display: "inline-block", background: "black", verticalAlign: "middle", height: "1px", width: "20px", margin: "0 5px" }}></span> OR <span style={{ display: "inline-block", background: "black", verticalAlign: "middle", height: "1px", width: "20px", margin: "0 5px" }}></span>
            </p>
            <Button variant="outlined" disabled={showLoading} onClick={() => history.push('/admin/register')} >Register</Button>
          </div>
          <LoginBoxFooter>
            <Link
              component={RouterLink}
              to="/admin/forgot-password"
              style={{ color: "#AEAEAE" }}
            >Forgot password?
            </Link>
          </LoginBoxFooter>
        </div>
      </LoginBoxContainer>
    </form>
  );
}
