import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Clear } from '@material-ui/icons';
import { useError } from '../../hooks/useError';

import {
    GetTagsQuery, GetTagsQueryVariables,
    UpdateNotForProfitMutation, UpdateNotForProfitMutationVariables,
    GetNotForProfitByIdQuery, GetNotForProfitByIdQueryVariables,
} from '../../generated/graphql';

import { UserStatus } from '../../graphql/Fragments/User.enum'

import { ContentHeader } from '../../components/common/ContentHeader';
import { useForm, Controller } from 'react-hook-form';
import { FileUploader } from '../../components/common/FileUploader';

const GET_TAGS_QUERY = loader('../../graphql/Tags/GetTags.graphql')
const UPDATE_NFP_MUTATION = loader('../../graphql/NotForProfits/UpdateNotForProfit.graphql')
const GET_NFP_BY_ID_QUERY = loader('../../graphql/NotForProfits/GetNotForProfit.graphql')

export function EditNotForProfit() {
    document.title = "Edit Not For Profit - ManySeeds"

    const history = useHistory()
    const { nfpId } = useParams<{ nfpId: string }>()
    const { addError } = useError()

    const [showPassword, setShowPassword] = useState(false);

    const { clearErrors, control, formState: { errors }, handleSubmit, reset, setError } = useForm();

    const { data: nfpTagsData, loading: nfpTagsLoading } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS_QUERY, { variables: { tagType: "nfp" } });
    //const { data: advertisementTagsData, loading: advertisementTagsLoading } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS_QUERY, {variables:{tagType: "advertisement"}});   
    const [updateNfp, { data: updateNfpData, loading: updateNfpLoading }] = useMutation<UpdateNotForProfitMutation, UpdateNotForProfitMutationVariables>(UPDATE_NFP_MUTATION);

    const [editNfpId, setEditNfpId] = useState<number | undefined>(parseInt(nfpId))
    const { data: getNfpData, loading: getNfpLoading, error: getNfpError } = useQuery<GetNotForProfitByIdQuery, GetNotForProfitByIdQueryVariables>(GET_NFP_BY_ID_QUERY, {
        variables: {
            nfpId: parseInt(nfpId.replace(/[^0-9]/i, ''))
        },
        fetchPolicy: "network-only"
    });

    const [keywords, setKeywords] = useState<string[]>([]);
    const [currentKeyword, setCurrentKeyword] = useState<string>("");
    const [uploadFile, setUploadFile] = useState<File>();

    useEffect(() => {
        //Unmount - destroy the data object
        return () => {
            setEditNfpId(undefined)
            reset()
        }
    }, [])

    useEffect(() => {
        if (getNfpError) {
            addError("error", "Error fetching Not For Profit data: " + getNfpError.message);
            return;
        }
    }, [addError, getNfpError])

    useEffect(() => {
        if (getNfpData && getNfpData.getNfpById.keywords) {
            setKeywords(getNfpData.getNfpById.keywords);
        }
    }, [getNfpData, setKeywords])

    const handleUpdateNfp = async (data: any) => {
        let builtTags: Array<{ tagId: number }> = [];
        if (data.tag) {
            data.tag.forEach((value: boolean, index: number) => {
                if (value) {
                    builtTags.push({ tagId: index })
                }
            })
        }

        let builtAdvertisementTags: Array<{ tagId: number }> = [];
        if (data.advertisementTag) {
            data.advertisementTag.forEach((value: boolean, index: number) => {
                if (value) {
                    builtAdvertisementTags.push({ tagId: index })
                }
            })
        }

        if (!editNfpId) {
            addError("error", "Error saving Not For Profit: Invalid or no item specified.")
            return
        }

        // updating
        updateNfp({
            variables: {
                nfpData: {
                    id: editNfpId,
                    name: data.name,
                    city: data.city,
                    description: data.description,
                    logo: data.logo,
                    website: data.website,
                    facebookUrl: data.facebookUrl,
                    twitterUrl: data.twitterUrl,
                    instagramUrl: data.instagramUrl,
                    contactPhone: data.contactPhone,
                    contactEmail: data.contactEmail,
                    charitableNumber: data.charitableNumber,
                    donateLink: data.donateLink,
                    paypalEmail: data.paypalEmail,
                    verified: (data.verified && data.verified === true ? 1 : 0),
                    keywords
                },
                logo: uploadFile,
                userData: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phone: data.phone,
                    password: data.password,
                    status: data.status
                },
                nfpTags: builtTags,
                advertisementTags: builtAdvertisementTags
            }
        })
    }

    const handleComplete = (method?: string) => {
        reset()
        clearErrors()
        history.push('/admin/not-for-profits/')
    }

    useEffect(() => {
        if (updateNfpData) {
            if (updateNfpData.updateNfp.response === "success") {
                addError("success", "Successfully saved Not For Profit");
                handleComplete();
                return
            }

            addError("error", "Unable to save Not For Profit. Please check errors and try again.")
            if (updateNfpData.updateNfp.errors) {
                updateNfpData.updateNfp.errors.forEach((value: any, index: number) => {
                    setError(value.field, { message: value.message })
                })
            }
        }
    }, [updateNfpData, addError])

    const addKeyword = (e: any) => {
        if (e.keyCode !== 13) { return }

        e.preventDefault();
        if (!e.target.value.trim()) { return }

        setKeywords(kws => {
            const tempArray = [...kws, e.target.value.trim()];
            return [...tempArray];
        });
        setCurrentKeyword("");
    }

    const removeKeyword = (index: number) => {
        setKeywords(kws => {
            const tempKws: string[] = [...kws];
            tempKws.splice(index, 1);
            return [...tempKws];
        });
    }

    return <>
        <Grid container>
            <Grid item xs={12}>
                <ContentHeader
                    title="Edit Not For Profit"
                    options={[
                        { text: "Return to List", action: (e: any) => history.go(-1) }
                    ]}
                    //loading={getNfpLoading || nfpTagsLoading || advertisementTagsLoading}
                    loading={getNfpLoading || nfpTagsLoading}
                />
            </Grid>
            {/* {getNfpData && nfpTagsData && advertisementTagsData &&  */}
            {getNfpData && nfpTagsData &&
                <>
                    <form onSubmit={handleSubmit(handleUpdateNfp)} name="CreateNfp">
                        <Paper className="form-paper">
                            <Grid item xs={12} container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <h2>User Details</h2>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        defaultValue={getNfpData.getNfpById.user?.firstName}
                                        rules={{ required: true }}
                                        render={({ field }) =>
                                            <TextField label="User First Name" required fullWidth margin="none" {...field} />
                                        }
                                    />
                                    {errors.firstName && <FormHelperText error={true}>{errors.firstName.message}</FormHelperText>}
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        defaultValue={getNfpData.getNfpById.user?.lastName}
                                        rules={{ required: true }}
                                        render={({ field }) => <TextField label="User Last Name" required fullWidth margin="dense" {...field} />}
                                    />
                                    {errors.lastName && <FormHelperText error={true}>{errors.lastName.message}</FormHelperText>}
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue={getNfpData.getNfpById.user?.userEmail}
                                        rules={{ required: true }}
                                        render={({ field }) => <TextField label="User Email" inputMode="email" required fullWidth margin="dense" {...field} />}
                                    />
                                    {errors.email && <FormHelperText error={true}>{errors.email.message}</FormHelperText>}
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{ minLength: 6 }}
                                        render={({ field }) => <TextField label="Change Password"
                                            type={showPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={(e) => setShowPassword((p) => !p)}
                                                            onMouseDown={(e) => e.preventDefault()}
                                                        >
                                                            {showPassword ? (
                                                                <Visibility htmlColor="#AEAEAE" />
                                                            ) : (
                                                                <VisibilityOff htmlColor="#2E2E2E" />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth margin="dense" {...field} />}
                                    />
                                    <Controller
                                        name="contactPhone"
                                        control={control}
                                        defaultValue={getNfpData.getNfpById.contactPhone}
                                        render={({ field }) => <TextField label="Public Contact Phone" inputMode="tel" fullWidth margin="dense" {...field} />}
                                    />
                                    {errors.contactPhone && <FormHelperText error={true}>{errors.contactPhone.message}</FormHelperText>}
                                    <Controller
                                        name="contactEmail"
                                        control={control}
                                        defaultValue={getNfpData.getNfpById.contactEmail}
                                        render={({ field }) => <TextField label="Public Contact Email" inputMode="email" fullWidth margin="dense" {...field} />}
                                    />
                                    {errors.contactEmail && <FormHelperText error={true}>{errors.contactEmail.message}</FormHelperText>}
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name="verified"
                                                control={control}
                                                defaultValue={getNfpData.getNfpById.verified}
                                                render={({ field }) => <Checkbox checked={field.value} {...field} />}
                                            />
                                        }
                                        label="Charity Verified?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <h2>Organization Details</h2>
                                    <Controller
                                        name="name"
                                        control={control}
                                        defaultValue={getNfpData.getNfpById.name}
                                        rules={{ required: true }}
                                        render={({ field }) => <TextField label="Organization Name" required fullWidth margin="dense" {...field} />}
                                    />
                                    {errors.name && <FormHelperText error={true}>{errors.name.message}</FormHelperText>}
                                    <Controller
                                        name="charitableNumber"
                                        control={control}
                                        defaultValue={getNfpData.getNfpById.charitableNumber}
                                        rules={{ required: true }}
                                        render={({ field }) => <TextField label="Charitable #" required fullWidth margin="dense" {...field} />}
                                    />
                                    <Controller
                                        name="city"
                                        control={control}
                                        defaultValue={getNfpData.getNfpById.city}
                                        rules={{ required: true }}
                                        render={({ field }) => <TextField label="Region" required fullWidth margin="dense" {...field} />}
                                    />
                                    <Controller
                                        name="description"
                                        control={control}
                                        defaultValue={getNfpData.getNfpById.description}
                                        render={({ field }) => <TextField label="Description" fullWidth margin="dense" {...field} />}
                                    />
                                    <Controller
                                        name="website"
                                        defaultValue={getNfpData.getNfpById.website}
                                        control={control}
                                        render={({ field }) => <TextField label="Website" fullWidth margin="dense" {...field} />}
                                    />
                                    <Controller
                                        name="facebookUrl"
                                        defaultValue={getNfpData.getNfpById.facebookUrl}
                                        control={control}
                                        render={({ field }) => <TextField label="Facebook URL" fullWidth margin="dense" {...field} />}
                                    />
                                    <Controller
                                        name="twitterUrl"
                                        defaultValue={getNfpData.getNfpById.twitterUrl}
                                        control={control}
                                        render={({ field }) => <TextField label="Twitter URL" fullWidth margin="dense" {...field} />}
                                    />
                                    <Controller
                                        name="instagramUrl"
                                        defaultValue={getNfpData.getNfpById.instagramUrl}
                                        control={control}
                                        render={({ field }) => <TextField label="Instagram URL" fullWidth margin="dense" {...field} />}
                                    />
                                    <Controller
                                        name="donateLink"
                                        defaultValue={getNfpData.getNfpById.donateLink}
                                        control={control}
                                        render={({ field }) => <TextField label="Donate Link" fullWidth margin="dense" {...field} />}
                                    />
                                    <Controller
                                        name="status"
                                        control={control}
                                        defaultValue={getNfpData.getNfpById.user?.status}
                                        render={({ field }) => <TextField select label="Status" fullWidth margin="dense" {...field}
                                            onChange={e => field.onChange(e.target.value)}
                                        >
                                            <MenuItem key={UserStatus.ACTIVE} value={UserStatus.ACTIVE}>Active</MenuItem>
                                            <MenuItem key={UserStatus.INACTIVE} value={UserStatus.INACTIVE}>Inactive</MenuItem>
                                            <MenuItem key={UserStatus.SUSPENDED} value={UserStatus.SUSPENDED}>Suspended</MenuItem>
                                            <MenuItem key={UserStatus.BANNED} value={UserStatus.BANNED}>Banned</MenuItem>
                                            <MenuItem key={UserStatus.AWAITING_APPROVAL} value={UserStatus.AWAITING_APPROVAL}>Awaiting Approval</MenuItem>
                                        </TextField>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Keywords"
                                        value={currentKeyword}
                                        onChange={e => setCurrentKeyword(e.currentTarget.value)}
                                        onKeyDown={addKeyword}
                                        fullWidth
                                        margin="dense"
                                        helperText="Type a keyword, and press enter to add to list"
                                    />
                                    <div className="keyword-list">
                                        {
                                            keywords.map((value: any, index: number) => {
                                                return <div key={index}>
                                                    {value} <IconButton size="small" onClick={e => removeKeyword(index)}><Clear htmlColor="#ff0000" /></IconButton>
                                                </div>
                                            })
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <h2>Logo</h2>
                                    <FormControl style={{ marginBottom: "1em" }}>
                                        {getNfpData && getNfpData.getNfpById.logo &&
                                            <div className="profile-image">
                                                <img
                                                    src={`${process.env.REACT_APP_S3_ENDPOINT}/charities/${getNfpData.getNfpById.logo}`}
                                                    alt=""
                                                    style={{ maxWidth: "100%", maxHeight: "350px" }}
                                                />
                                            </div>
                                        }
                                        <FileUploader
                                            accept={"image/png,image/jpeg,image/gif"}
                                            handleDrop={(files: FileList) => {
                                                setUploadFile(files[0])
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <h3>Not For Profit Category</h3>
                                    {nfpTagsData?.getTags.map((tag: any) => {
                                        let searchMyData: any;
                                        if (getNfpData.getNfpById.tags) {
                                            searchMyData = getNfpData.getNfpById.tags.filter((value: any, index: any) => {
                                                return (value.id === tag.id)
                                            })
                                        }

                                        return <FormControl key={tag.id}>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name={`tag[${tag.id}]`}
                                                        control={control}
                                                        defaultValue={searchMyData && searchMyData.length > 0}
                                                        render={({ field: props }) =>
                                                            <Checkbox
                                                                {...props}
                                                                checked={props.value}
                                                                onChange={e => props.onChange(e.target.checked)}
                                                            />
                                                        }
                                                    />
                                                }
                                                label={tag.name}
                                            />
                                        </FormControl>
                                    })}
                                </Grid>
                                <Grid item xs={12}>
                                    <h3>Paypal Details</h3>
                                    <Controller
                                        name="paypalEmail"
                                        defaultValue={getNfpData.getNfpById.paypalEmail}
                                        control={control}
                                        render={({ field }) => <TextField label="Paypal Email" fullWidth margin="dense" {...field} />}
                                    />
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <h3>Advertisement Interest</h3>
                                    {advertisementTagsData?.getTags.map((tag: any) => {
                                        let searchMyData: any;
                                        if(getNfpData.getNfpById.advertisementTags) {
                                            searchMyData = getNfpData.getNfpById.advertisementTags.filter((value: any, index:any) => {
                                                return (value.id === tag.id)
                                            })
                                        }

                                        return <FormControl key={tag.id}>
                                            <FormControlLabel
                                                control={
                                                    <Controller 
                                                        name={`advertisementTag[${tag.id}]`} 
                                                        control={control} 
                                                        defaultValue={searchMyData && searchMyData.length > 0}
                                                        render={({field: props}) => 
                                                            <Checkbox
                                                                {...props}
                                                                checked={props.value}
                                                                onChange={e => props.onChange(e.target.checked)}
                                                            />                                            
                                                        }
                                                    />
                                                }
                                                label={tag.name}
                                            />
                                        </FormControl>
                                    })}
                                </Grid> */}
                                <Grid item xs={12}>
                                    <Button disabled={updateNfpLoading} type="submit" color="primary" variant="contained"
                                    >{updateNfpLoading && <CircularProgress size={11} style={{ marginRight: "10px" }} />}Submit</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </form>
                </>
            }
        </Grid>
    </>
}