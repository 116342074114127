import XLSX from 'xlsx';

type ExporterProps = {
    headersTop: Array<string | number>,
    headersBottom: Array<string | number>,
    rowData?: any[]
}

export async function ExportCSV(filename: string, { headersTop, headersBottom, rowData = [] }: ExporterProps) {
    const outputRowData = processRowData(rowData);
    /**
     * End building data
     */

    //Now let's trigger the download
    //https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
    const csvContent = [
        headersTop,
        ...outputRowData,
        headersBottom
    ];

    const encodedUri = "data:text/csv;charset=utf-8," + encodeURI(csvContent.map(e => '"' + e.join("\",\"") + '"').join("\n"));
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); //Apparently this is needed for cross-browser (FF, Some Safari vers)
    link.click();
}

export async function ExportXLSX(filename: string, { headersTop, headersBottom, rowData = [] }: ExporterProps) {

    const outputRowData = processRowData(rowData);
    const csvContent = [
        headersTop,
        ...outputRowData,
        headersBottom
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(csvContent);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, filename);
}

function processRowData(rowData: any[]) {
    let outputRowData: Array<string[]> = [];
    for (const row of rowData) {
        //Make sure that data is all strings and sich
        outputRowData.push(row.map((item: any) => String(item)))
    }
    return outputRowData;
}