import { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Button,
    Card,
    CardMedia,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useError } from '../../hooks/useError';
import { useForm, Controller } from 'react-hook-form';
import { Tab, Tabs, TabPanel } from '../../components/common'

import {
    ReviveBanner,
    UpdateAdvertiserMutation, UpdateAdvertiserMutationVariables,
    GetAdvertiserByIdQuery, GetAdvertiserByIdQueryVariables,
    GetBannersByAdvertiserQuery, GetBannersByAdvertiserQueryVariables,
    GetTagsQuery, GetTagsQueryVariables,
    GetAdvertiserTransactionsQuery, GetAdvertiserTransactionsQueryVariables,
    AddBalanceMutation, AddBalanceMutationVariables,
    RemoveBalanceMutation, RemoveBalanceMutationVariables
} from '../../generated/graphql';
import { ContentHeader } from '../../components/common/ContentHeader';
import { UserStatus } from '../../graphql/Fragments/User.enum';
import { BannerStatus } from '../../graphql/Fragments/Banner.enum';
import { FileUploader } from '../../components/common/FileUploader';

const UPDATE_ADVERTISER_MUTATION = loader('../../graphql/Advertisers/UpdateAdvertiser.graphql')
const GET_ADVERTISER_BY_ID_QUERY = loader('../../graphql/Advertisers/GetAdvertiser.graphql')
const GET_ADVERTISEMENTS_QUERY = loader('../../graphql/Advertisements/GetBannersByAdvertiser.graphql')
const GET_TRANSACTIONS_QUERY = loader('../../graphql/Advertisers/GetTransactions.graphql')
const GET_TAGS_QUERY = loader('../../graphql/Tags/GetTags.graphql')

const ADD_BALANCE = loader('../../graphql/Advertisers/AddBalance.graphql')
const REMOVE_BALANCE = loader('../../graphql/Advertisers/RemoveBalance.graphql')

export function EditAdvertiser() {
    document.title = "Edit Advertiser - ManySeeds"
    const history = useHistory()
    const { advertiserId } = useParams<{ advertiserId: string }>()

    const [currentTab, setCurrentTab] = useState(0)
    const [editAdvertiserId, setEditAdvertiserId] = useState<number>(parseInt(advertiserId.replace(/[^0-9]/i, '')))
    const [activeMode, setActiveMode] = useState<"edit" | "disabled">(editAdvertiserId ? "edit" : "disabled");

    const [previewBanner, setPreviewBanner] = useState<ReviveBanner | undefined>()
    const [showPreviewDialog, setShowPreviewDialog] = useState<boolean>(false)
    const [uploadFile, setUploadFile] = useState<File>();

    const { addError } = useError()
    const { control, handleSubmit, reset, formState: { errors }, clearErrors, setError } = useForm();

    const [showPassword, setShowPassword] = useState(false);

    const [changeAmount, setChangeAmount] = useState<number>(50.00);
    const [showAddBalance, setShowAddBalance] = useState<boolean>(false)
    const [balanceDirection, setBalanceDirection] = useState<"deposit" | "withdraw">('deposit');

    const { data: tagsData, loading: tagsLoading } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS_QUERY, { variables: { tagType: "advertiser" } });
    const [updateAdvertiser, { data: updateAdvertiserData, loading: updateAdvertiserLoading }] = useMutation<UpdateAdvertiserMutation, UpdateAdvertiserMutationVariables>(UPDATE_ADVERTISER_MUTATION);

    const [getAdvertisementsQuery, { data: advertisementsData, loading: advertisementsLoading }] = useLazyQuery<GetBannersByAdvertiserQuery, GetBannersByAdvertiserQueryVariables>(GET_ADVERTISEMENTS_QUERY, {
        variables: {
            advertiserId: editAdvertiserId
        }
    })

    const [getTransactionsQuery, { data: transactionsData, loading: transactionsLoading }] = useLazyQuery<GetAdvertiserTransactionsQuery, GetAdvertiserTransactionsQueryVariables>(GET_TRANSACTIONS_QUERY, {
        variables: {
            advertiserId: editAdvertiserId
        }
    })

    const [addBalance, { data: addBalanceResponse, loading: addBalanceLoading }] = useMutation<AddBalanceMutation, AddBalanceMutationVariables>(ADD_BALANCE, {
        refetchQueries: [
            { query: GET_TRANSACTIONS_QUERY, variables: { advertiserId: editAdvertiserId } },
            { query: GET_ADVERTISER_BY_ID_QUERY, variables: { advertiserId: editAdvertiserId } }
        ]
    });
    const [removeBalance, { data: removeBalanceResponse, loading: removeBalanceLoading }] = useMutation<RemoveBalanceMutation, RemoveBalanceMutationVariables>(REMOVE_BALANCE, {
        refetchQueries: [
            { query: GET_TRANSACTIONS_QUERY, variables: { advertiserId: editAdvertiserId } },
            { query: GET_ADVERTISER_BY_ID_QUERY, variables: { advertiserId: editAdvertiserId } }
        ]
    });

    useEffect(() => {
        //Unmount - destroy the data object
        return () => {
            setEditAdvertiserId(0)
            setActiveMode("disabled");
            reset()
        }
    }, [])

    //only used for Edit
    const { data: getAdvertiserData, loading: getAdvertiserLoading, error: getAdvertiserError } = useQuery<GetAdvertiserByIdQuery, GetAdvertiserByIdQueryVariables>(GET_ADVERTISER_BY_ID_QUERY, {
        variables: {
            advertiserId: editAdvertiserId
        },
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (getAdvertiserError) {
            setActiveMode("disabled");
            addError("error", "Error fetching Advertiser data: " + getAdvertiserError.message);
            return;
        }
    }, [getAdvertiserError])

    useEffect(() => {
        if (!advertisementsData && currentTab === 1) {
            //trigger loading the advertisement
            getAdvertisementsQuery({
                variables: {
                    advertiserId: editAdvertiserId
                }
            })
        }
        if (!transactionsData && currentTab === 2) {
            //trigger loading transactions
            getTransactionsQuery({
                variables: {
                    advertiserId: editAdvertiserId
                }
            })
        }
    }, [currentTab])

    const handleUpdateAdvertiser = (data: any) => {
        if (activeMode === "disabled") {
            addError("error", "Save is disabled due to an encountered error.")
            return
        }

        if (!editAdvertiserId) {
            addError("error", "Error saving advertiser: Invalid or no item specified.")
            return
        }

        let builtTags: Array<{ tagId: number }> = [];

        if (data.tag) {
            data.tag.forEach((value: boolean, index: number) => {
                if (value) {
                    builtTags.push({ tagId: index })
                }
            })
        }

        // updating
        updateAdvertiser({
            variables: {
                advertiserData: {
                    id: editAdvertiserId,
                    name: data.advertiserName,
                    contactPhone: data.contactPhone,
                    contactEmail: data.contactEmail,
                    costPerThousandImpressions: parseFloat(data.costPerThousandImpressions),
                    manyseedsPortion: parseFloat(data.manyseedsPortion),
                },
                logo: uploadFile,
                userData: {
                    email: data.userEmail,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    password: data.password,
                    status: data.status
                },
                tags: builtTags
            }
        })
    }

    const handleComplete = (method?: string) => {
        clearErrors();
        reset();
        history.push('/admin/advertisers/')
    }

    useEffect(() => {
        if (updateAdvertiserData) {
            if (updateAdvertiserData.updateAdvertiser.response === "success") {
                addError("success", "Successfully saved Advertiser");
                handleComplete();
                return
            }

            addError("error", "Unable to save Advertiser. Please check errors and try again.")
            if (updateAdvertiserData.updateAdvertiser.errors) {
                updateAdvertiserData.updateAdvertiser.errors.forEach((value: any, index: number) => {
                    setError(value.field, { message: value.message });
                })
            }
        }
    }, [updateAdvertiserData, addError])

    const getStatus = (status: number) => {
        switch (status) {
            case BannerStatus.ACTIVE:
                return "Active";
            case BannerStatus.INACTIVE:
                return "Inactive";
            case BannerStatus.AWAITING_APPROVAL:
                return "Awaiting Approval";
            case BannerStatus.DELETED:
                return "Deleted";
        }
    }

    const showPreview = (banner: any) => {
        setPreviewBanner(banner)
        setShowPreviewDialog(true)
    }

    const previewDialog = useMemo(() => {
        return <DialogContent>
            {previewBanner && previewBanner.videoPath ?
                <CardMedia
                    height={200}
                    component="video"
                    image={previewBanner.videoPath}
                    title="Video Preview"
                    controls
                    style={{ marginBottom: "1em" }}
                />
                :
                'No Advertisement preview to display'
            }
        </DialogContent>
    }, [previewBanner])

    const handleClickAddBalance = () => {
        if (balanceDirection === "deposit") {
            addBalance({
                variables: {
                    advertiserId: editAdvertiserId,
                    amount: changeAmount
                }
            })
        } else {
            removeBalance({
                variables: {
                    advertiserId: editAdvertiserId,
                    amount: changeAmount
                },
                refetchQueries: [{
                    query: GET_TRANSACTIONS_QUERY,
                    variables: {
                        advertiserId: editAdvertiserId
                    }
                },]
            })
        }
    }

    useEffect(() => {
        if (addBalanceResponse) {
            if (addBalanceResponse.addBalance) {
                setShowAddBalance(false)
                addError("success", "Balance Added")
            } else {
                addError("error", "Error while adjusting balance.")
            }
        }
        if (removeBalanceResponse) {
            if (removeBalanceResponse.removeBalance) {
                setShowAddBalance(false)
                addError("success", "Balance Removed")
            } else {
                addError("error", "Error while adjusting balance.")
            }
        }
    }, [addBalanceResponse, removeBalanceResponse, addError])

    if (!editAdvertiserId) {
        return <>Invalid or no item specified</>
    }

    return <>
        <Grid container>
            <Grid item xs={12}>
                <ContentHeader
                    title="Edit Advertiser"
                    options={[
                        { text: "Return to Advertiser", action: (e: any) => history.go(-1) }
                    ]}
                    loading={getAdvertiserLoading || advertisementsLoading || tagsLoading}
                />
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setCurrentTab(newValue)
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        className={"no-margin"}
                    >
                        <Tab label="Details" key={0} />
                        <Tab label="Advertisements" key={1} />
                        <Tab label="Balance" key={2} />
                    </Tabs>
                    <TabPanel value={currentTab} index={0}>
                        {getAdvertiserData && tagsData &&
                            <form style={{ width: "100%" }} onSubmit={handleSubmit(handleUpdateAdvertiser)} name="EditAdvertiser">
                                <Paper className="form-paper">
                                    <Grid item xs={12} container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="advertiserName"
                                                control={control}
                                                rules={{ required: true }}
                                                defaultValue={getAdvertiserData?.getAdvertiserById.name}
                                                render={({ field }) => <TextField label="Advertiser Name" required fullWidth {...field} />}
                                            />
                                            {errors.advertiserName && <FormHelperText error={true}>{errors.advertiserName.message}</FormHelperText>}
                                            <Controller
                                                name="userEmail"
                                                control={control}
                                                rules={{ required: true }}
                                                defaultValue={getAdvertiserData?.getAdvertiserById.user?.userEmail}
                                                render={({ field }) => <TextField label="Contact Email" required fullWidth margin="dense" {...field} />}
                                            />
                                            {errors.userEmail && <FormHelperText error={true}>{errors.userEmail.message}</FormHelperText>}
                                            <Controller
                                                name="firstName"
                                                control={control}
                                                rules={{ required: true }}
                                                defaultValue={getAdvertiserData?.getAdvertiserById.user?.firstName}
                                                render={({ field }) =>
                                                    <TextField label="Contact First Name" required fullWidth margin="dense" {...field} />
                                                }
                                            />
                                            {errors.firstName && <FormHelperText error={true}>{errors.firstName.message}</FormHelperText>}
                                            <Controller
                                                name="lastName"
                                                control={control}
                                                rules={{ required: true }}
                                                defaultValue={getAdvertiserData?.getAdvertiserById.user?.lastName}
                                                render={({ field }) =>
                                                    <TextField label="Contact Last Name" required fullWidth margin="dense" {...field} />
                                                }
                                            />
                                            {errors.lastName && <FormHelperText error={true}>{errors.lastName.message}</FormHelperText>}
                                            <Controller
                                                name="password"
                                                control={control}
                                                defaultValue=""
                                                rules={{ minLength: 6 }}
                                                render={({ field }) =>
                                                    <TextField label="Change Password"
                                                        type={showPassword ? "text" : "password"}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={(e) => setShowPassword((p) => !p)}
                                                                        onMouseDown={(e) => e.preventDefault()}
                                                                    >
                                                                        {showPassword ? (
                                                                            <Visibility htmlColor="#AEAEAE" />
                                                                        ) : (
                                                                            <VisibilityOff htmlColor="#2E2E2E" />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth margin="dense" {...field}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                name="contactEmail"
                                                defaultValue={getAdvertiserData.getAdvertiserById.contactEmail}
                                                control={control}
                                                render={({ field }) =>
                                                    <TextField
                                                        label="Public Contact Email"
                                                        inputMode="email"
                                                        fullWidth
                                                        {...field}
                                                    />
                                                }
                                            />
                                            <Controller
                                                name="contactPhone"
                                                defaultValue={getAdvertiserData.getAdvertiserById.contactPhone}
                                                control={control}
                                                render={({ field }) =>
                                                    <TextField
                                                        label="Public Contact Phone"
                                                        inputMode="tel"
                                                        fullWidth
                                                        margin="dense"
                                                        {...field}
                                                    />
                                                }
                                            />
                                            <Controller
                                                name="costPerThousandImpressions"
                                                defaultValue={(getAdvertiserData.getAdvertiserById.costPerThousandImpressions ? getAdvertiserData.getAdvertiserById.costPerThousandImpressions / 1000 : 0)}
                                                control={control}
                                                render={({ field }) =>
                                                    <TextField
                                                        label="Default Amount Paid Per Ad View"
                                                        fullWidth
                                                        margin="dense"
                                                        {...field}
                                                    />
                                                }
                                            />
                                            <Controller
                                                name="manyseedsPortion"
                                                defaultValue={getAdvertiserData.getAdvertiserById.manyseedsPortion}
                                                control={control}
                                                render={({ field }) =>
                                                    <TextField
                                                        label="Default Many Seeds Portion(%)"
                                                        fullWidth
                                                        margin="dense"
                                                        {...field}
                                                    />
                                                }
                                            />
                                            <Controller
                                                name="status"
                                                control={control}
                                                defaultValue={getAdvertiserData?.getAdvertiserById.status}
                                                render={({ field }) => <TextField select label="Status" fullWidth margin="dense" {...field}
                                                    onChange={e => field.onChange(e.target.value)}
                                                >
                                                    <MenuItem key={UserStatus.ACTIVE} value={UserStatus.ACTIVE}>Active</MenuItem>
                                                    <MenuItem key={UserStatus.INACTIVE} value={UserStatus.INACTIVE}>Inactive</MenuItem>
                                                    <MenuItem key={UserStatus.SUSPENDED} value={UserStatus.SUSPENDED}>Suspended</MenuItem>
                                                    <MenuItem key={UserStatus.BANNED} value={UserStatus.BANNED}>Banned</MenuItem>
                                                    <MenuItem key={UserStatus.AWAITING_APPROVAL} value={UserStatus.AWAITING_APPROVAL}>Awaiting Approval</MenuItem>
                                                </TextField>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <h2>Logo</h2>
                                        <FormControl style={{ marginBottom: "1em" }}>
                                            {getAdvertiserData && getAdvertiserData.getAdvertiserById.logo &&
                                                <div className="profile-image">
                                                    <img
                                                        src={`${process.env.REACT_APP_S3_ENDPOINT}/advlogos/${getAdvertiserData.getAdvertiserById.logo}`}
                                                        alt=""
                                                        style={{ maxWidth: "100%", maxHeight: "350px" }}
                                                    />
                                                </div>
                                            }
                                            <FileUploader
                                                accept={"image/png,image/jpeg,image/gif"}
                                                handleDrop={(files: FileList) => {
                                                    setUploadFile(files[0])
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <h3>Tags</h3>
                                    <Grid item xs={12} container spacing={4}>
                                        <Grid item xs={12}>
                                            {tagsData?.getTags.map((tag: any) => {
                                                let searchMyData: any;
                                                if (getAdvertiserData?.getAdvertiserById.tags) {
                                                    searchMyData = getAdvertiserData.getAdvertiserById.tags.filter((value: any, index: any) => {
                                                        return (value.id === tag.id)
                                                    })
                                                }

                                                return <FormControl key={tag.id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Controller
                                                                name={`tag[${tag.id}]`}
                                                                control={control}
                                                                defaultValue={searchMyData && searchMyData.length > 0}
                                                                render={({ field: props }) =>
                                                                    <Checkbox
                                                                        {...props}
                                                                        checked={props.value}
                                                                        onChange={e => {
                                                                            props.onChange(e.target.checked)
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        label={tag.name}
                                                    />
                                                </FormControl>
                                            })}
                                        </Grid>
                                    </Grid>
                                    <div style={{ marginTop: "1em" }}>
                                        <Button disabled={
                                            updateAdvertiserLoading || activeMode === "disabled"
                                        } type="submit" color="primary" variant="contained">{updateAdvertiserLoading && <CircularProgress size={11} style={{ marginRight: "10px" }} />}Submit</Button>
                                    </div>
                                </Paper>
                            </form>
                        }
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Last Updated</TableCell>
                                        <TableCell>Preview</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {advertisementsData && advertisementsData.getAllReviveBanners.length > 0 ?
                                        advertisementsData.getAllReviveBanners.map((banner: any) => {
                                            return <TableRow>
                                                <TableCell>{banner.name}</TableCell>
                                                <TableCell>{new Date(banner.updated).toDateString()}</TableCell>
                                                <TableCell><Button variant="contained" color="primary" onClick={e => showPreview(banner)}>View</Button></TableCell>
                                                <TableCell>{getStatus(banner.status)}</TableCell>
                                            </TableRow>
                                        })
                                        :
                                        !advertisementsLoading &&
                                        <TableRow>
                                            <TableCell colSpan={4}>No advertisements to display</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={currentTab} index={2}>
                        <Paper className="form-paper">
                            <h3>Current Balance</h3>
                            <div>$<b>{getAdvertiserData?.getAdvertiserById.currentBalance}</b></div>
                            <Button variant="contained" onClick={e => { setBalanceDirection("deposit"); setShowAddBalance(true) }} style={{ marginRight: "15px" }}>Add Balance</Button>
                            <Button variant="contained" onClick={e => { setBalanceDirection("withdraw"); setShowAddBalance(true) }}>Remove Balance</Button>
                            <h3>Transaction History</h3>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={20}>Type</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                            <TableCell>Notes</TableCell>
                                            <TableCell>Process Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactionsData && transactionsData.getAdvertiserTransactions.length > 0 ?
                                            transactionsData.getAdvertiserTransactions.map((transx: any) => {
                                                return <TableRow>
                                                    <TableCell>{transx.transactionType.toLocaleUpperCase()}</TableCell>
                                                    <TableCell align="right" style={{
                                                        color:
                                                            (transx.transactionType === "deposit" ? "green" : "red")
                                                    }}>
                                                        ${transx.amount.toFixed(2)}
                                                    </TableCell>
                                                    <TableCell>{transx.note}</TableCell>
                                                    <TableCell>{new Date(transx.createdAt).toDateString()}</TableCell>
                                                </TableRow>
                                            })
                                            :
                                            !transactionsLoading &&
                                            <TableRow>
                                                <TableCell colSpan={4}>No advertisements to display</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </TabPanel>
                </Card>
            </Grid>
        </Grid>
        <Dialog open={showAddBalance} title="Add Balance">
            <DialogTitle>{balanceDirection === "deposit" ? "Add" : "Remove"} Balance</DialogTitle>
            <DialogContent>
                <TextField
                    disabled={addBalanceLoading}
                    label="Add Amount"
                    type="number"
                    value={changeAmount}
                    onChange={e => {
                        setChangeAmount(parseFloat(e.currentTarget.value))
                    }}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={addBalanceLoading || removeBalanceLoading} variant="outlined" color="secondary" onClick={e => {
                    handleClickAddBalance()
                }}>{(addBalanceLoading || removeBalanceLoading) && <CircularProgress size={20} style={{ marginRight: "20px" }} />}Submit</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showPreviewDialog} title="Preview Advertisement">
            <DialogTitle>Preview Advertisement</DialogTitle>
            {previewDialog}
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={e => {
                    setPreviewBanner(undefined)
                    setShowPreviewDialog(false)
                }}>Close Preview
                </Button>
            </DialogActions>
        </Dialog>
    </>
}
