import React, { useEffect, useMemo } from 'react';
import logo from "../assets/manyseeds-logo.png";
import "../assets/css/layout.frontend.css";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import { useError } from "../hooks/useError";

const GET_SERVER_VERSION = loader('../graphql/Dashboard/GetServerVersion.graphql')

export default function FrontendLayout({ children }: React.PropsWithChildren<{}>) {
    const { addError } = useError();
    //console.log('children', children);

    const { data: serverVersion, error: serverVersionError } = useQuery(GET_SERVER_VERSION)

    const clientVersionNumber = useMemo(() => `v${process.env.REACT_APP_VERSION}`, [])
    const serverVersionNumber = useMemo(() => {
        if (serverVersion) {
            return `v${serverVersion.getServerVersion}`;
        }
    }, [serverVersion])

    useEffect(() => {
        if (serverVersion) {
            addError("error", "Connection issue detected...");
        }
    }, [serverVersionError, addError])

    return (<div className="front-end">
        <header className="header">
            <div className="fluid-wrapper">
                <div className="header-title-nav-wrapper">
                    <div className="header-title">
                        <div className="header-title-logo">
                            <img src={logo} className="logo" alt="Many Seeds" />
                        </div>
                    </div>
                    <div className="header-nav">
                        <a href="https://many-seeds.com" data-animation-role="header-element">Return to Website</a>
                    </div>
                </div>
            </div>
        </header>
        <div className="body-container">
            {children}
        </div>
        <section className="footer">
            <div className="fluid-wrapper">
                <div>&copy; 2022 Many Seeds</div>
                <div><a href="https://many-seeds.com//privacy-policy">Privacy Policy</a> and <a href="https://many-seeds.com/terms-and-conditions">Terms and Conditions</a></div>
            </div>
            <div className="fluid-wrapper" style={{ alignItems: "flex-start", textAlign: "center" }}>
                <div style={{ fontSize: "0.875em" }}>
                    <span>{clientVersionNumber}</span>
                    {serverVersionNumber && <span>&nbsp;|&nbsp;</span>}
                    <span>{serverVersionNumber}</span>
                </div>
            </div>
        </section>
    </div>);
}