import React, { FormEvent, useEffect, useState } from "react";
import logo from "../assets/manyseeds-logo.png";
import { CircularProgress, Link, Typography, Button, FormHelperText } from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  AlertBar,
  LoginBoxContainer,
  LoginTextField,
  WelcomeText,
  LoginBoxFooter,
} from "../components/common";
import { useAdminAuth } from "../hooks/useAdminAuth";
import { isEmail } from "../utils/validation";
import { useError } from "../hooks/useError";
import { ArrowBack } from "@material-ui/icons";

export function ForgotPassword() {
  const { forgotPassword } = useAdminAuth()
  const { addError } = useError()

  const history = useHistory();

  const [email, setEmail] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)

  const [forgotPasswordSent, setForgotPasswordSent] = useState<boolean>(false)

  const { isAdminLoggedIn } = useAdminAuth()

  useEffect(() => {
    if (isAdminLoggedIn) {
      history.push('/admin/dashboard');
    }
  }, [isAdminLoggedIn])

  const handleClick = (e: FormEvent) => {
    e.preventDefault()

    setEmailError(false)
    setForgotPasswordSent(false)

    let hasErrors = false
    if (!email || !isEmail(email)) {
      hasErrors = true
      setEmailError(true)
    }

    if (!email || hasErrors) {
      return
    }

    setShowLoading(true)
    forgotPassword(email)
      .finally(() => {
        setShowLoading(false)
        setEmail("");
        setForgotPasswordSent(true)
      })
      .catch((error: any) => {
        setEmailError(true)
        addError("error", "Invalid email address entered. Please check and try again.")
      })
  };

  return (
    <form name="ForgotPasswordForm" onSubmit={handleClick}>
      <LoginBoxContainer>
        <div className="login-box">
          <WelcomeText>Forgot Password</WelcomeText>
          <Typography align="center" style={{ marginBottom: "2em", fontSize: "calc(1.1 * 1rem)", color: "#01c38a", fontWeight: "bold" }}>If you have lost your password, please enter the email address and we will send you instructions on how to reset your password.</Typography>
          {forgotPasswordSent && <AlertBar type="success" message="If an account exists with that email, a password reminder email will be sent." />}
          <div>
            <label htmlFor="username">Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => {
                const newEmail = e.target.value.trim().toLowerCase();
                setEmail(newEmail);
              }}
              autoFocus={true}
              required={true}
            />
            {emailError && <FormHelperText error={emailError}>You must enter a valid email</FormHelperText>}
          </div>
          <div style={{ position: "relative", margin: "4em 0", textAlign: "center" }}>
            <Button disabled={showLoading} type="submit" variant="contained" color="primary">
              {showLoading && <CircularProgress size={12} color="secondary" style={{ marginRight: "10px" }} />}<span>Submit</span>
            </Button>
          </div>
          <LoginBoxFooter>
            <Link
              component={RouterLink}
              to="/admin/login/"
              style={{ color: "#AEAEAE" }}
            ><ArrowBack fontSize="small" style={{ verticalAlign: "middle" }} /> Return to Login</Link>
          </LoginBoxFooter>
        </div>
      </LoginBoxContainer>
    </form>
  );
}
