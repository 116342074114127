import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import { AdminRouter } from './AdminRouter';

import FrontendLayout from './layouts/FrontendLayout';

import { Login } from "./pages/Login";
import { Register } from './pages/Register'
import { Logout } from "./pages/Logout";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";


export function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/admin/login" render={(props) => <FrontendLayout><Login /></FrontendLayout>} />
        <Route exact path="/admin/register" render={(props) => <FrontendLayout><Register /></FrontendLayout>} />

        <Route exact path="/admin/forgot-password" render={(props) => <FrontendLayout><ForgotPassword /></FrontendLayout>} />
        <Route path="/admin/reset-password/:hashed/:token/:source?" render={(props) => <FrontendLayout><ResetPassword /></FrontendLayout>} />

        <Route path="/logout" component={Logout} />
        <Route path="/admin" component={AdminRouter} />

        <Redirect from="*" to="/admin/login" />
      </Switch>
    </BrowserRouter>
  );
}
