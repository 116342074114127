import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client';

import { CardHeader, CircularProgress, Grid, Paper } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Line } from "react-chartjs-2";

import {
    GetAdPerformance
} from "../../graphql/Dashboard/GetAdvertiserDashboard";

import {
    AdvertiserDashboardAdPerformanceQuery, AdvertiserDashboardAdPerformanceQueryVariables
} from '../../generated/graphql';

export function AdvertiserDashboard() {
    document.title = "Administrative Dashboard - ManySeeds";

    const baseDate = new Date();
    const [dateRangeStart, setDateRangeStart] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth(), 1));
    const [dateRangeEnd, setDateRangeEnd] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 0))

    const { data: adData } = useQuery<AdvertiserDashboardAdPerformanceQuery, AdvertiserDashboardAdPerformanceQueryVariables>(GetAdPerformance, { variables: { dateRangeStart, dateRangeEnd } });

    const [adChartData, setAdChartData] = useState<any>();

    useEffect(() => {
        if (adData) {
            setAdChartData({
                labels: adData.advertiserDashboardAdPerformance.dates,
                datasets: [
                    { data: adData.advertiserDashboardAdPerformance.stats.start, label: "Ad Starts", fill: false, backgroundColor: "rgb(255, 205, 86)", borderColor: "rgb(255, 205, 86)" },
                    { data: adData.advertiserDashboardAdPerformance.stats.complete, label: "Complete Views", fill: false, backgroundColor: "rgb(75, 192, 192)", borderColor: "rgb(75, 192, 192)" },
                    //{ data: adData.advertiserDashboardAdPerformance.stats.replay, label: "Replays", fill: false, backgroundColor: "rgb(255, 99, 132)", borderColor: "rgb(255, 99, 132)" },
                ]
            })
        }
    }, [adData])

    const handleDateChange = (source: "start" | "end", newDate: any) => {
        //console.log('Clicked change date', source, newDate);
        switch (source) {
            case "start":
                setDateRangeStart(newDate)
                break;
            case "end":
                setDateRangeEnd(newDate)
                break;
        }
    }

    return (
        <div className="admin-dashboard">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h2>Overview</h2>
                <div style={{ alignSelf: "center" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div>
                            <DatePicker
                                disableToolbar
                                variant="inline"
                                label="Range Start"
                                value={dateRangeStart}
                                onChange={e => handleDateChange('start', e)}
                            />&nbsp;
                            <DatePicker
                                disableToolbar
                                variant="inline"
                                label="Range End"
                                value={dateRangeEnd}
                                onChange={e => handleDateChange('end', e)}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <Grid container style={{ marginTop: "2em" }}>
                <Grid item xs={12}>
                    <Paper variant="outlined">
                        <CardHeader title="Ad Performance" />
                        {adChartData ?
                            <Line
                                data={adChartData}
                                options={{
                                    responsive: true,
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                            ticks: {
                                                stepSize: 1
                                            }
                                        }
                                    },
                                    maintainAspectRatio: true,
                                }}
                            />
                            :
                            <CircularProgress style={{ margin: "0 auto" }} size={40} />
                        }
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}