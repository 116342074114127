import React, { useEffect, useState } from 'react'
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import {
    GetMyNotForProfitQuery, GetMyNotForProfitQueryVariables,
    NotForProfitDashboardAdPerformanceQuery, NotForProfitDashboardAdPerformanceQueryVariables, NotForProfitDashboardFavouritesQuery, NotForProfitDashboardFavouritesQueryVariables,

} from '../../generated/graphql';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, CardHeader, CircularProgress, Grid, Paper } from '@material-ui/core';
import { Doughnut, Line } from 'react-chartjs-2';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { GetFavourites, GetAdPerformance } from "../../graphql/Dashboard/GetNotForProfitDashboard";
const GET_NFP = loader('../../graphql/NotForProfits/GetMyNotForProfit.graphql')

export function NotForProfitDashboard() {
    document.title = "Organization Dashboard - ManySeeds";
    const history = useHistory()

    const { data: nfpData } = useQuery<GetMyNotForProfitQuery, GetMyNotForProfitQueryVariables>(GET_NFP)

    const baseDate = new Date();
    const [dateRangeStart, setDateRangeStart] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth(), 1));
    const [dateRangeEnd, setDateRangeEnd] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 0))

    const { data: nfpsData } = useQuery<NotForProfitDashboardFavouritesQuery, NotForProfitDashboardFavouritesQueryVariables>(GetFavourites, { variables: { dateRangeStart, dateRangeEnd } });

    const { data: adData } = useQuery<NotForProfitDashboardAdPerformanceQuery, NotForProfitDashboardAdPerformanceQueryVariables>(GetAdPerformance, { variables: { dateRangeStart, dateRangeEnd } });

    const [favouritesChartData, setFavouritesChartData] = useState<any>();
    const [adChartData, setAdChartData] = useState<any>();

    useEffect(() => {
        if (nfpData) {
            //check if they've completed their profile, if not - send them to the profile page to finalize it
            if (nfpData.getMyNfp.registrationComplete === 0) {
                history.push('/admin/profile/')
            }
        }
    }, [nfpData, history])

    useEffect(() => {
        if (nfpsData) {
            setFavouritesChartData({
                labels: ["Total Favourited", "New"],
                datasets: [{
                    label: "Users",
                    data: [
                        nfpsData.notForProfitDashboardFavourites.all,
                        nfpsData.notForProfitDashboardFavourites.new
                    ],
                    backgroundColor: ['green', 'blue'],
                    hoverOffset: 4
                }]
            })
        }
    }, [nfpsData])

    useEffect(() => {
        if (adData) {
            setAdChartData({
                labels: adData.notForProfitDashboardAdPerformance.dates,
                datasets: [
                    { data: adData.notForProfitDashboardAdPerformance.stats.start, label: "Ad Starts", fill: false, backgroundColor: "rgb(255, 205, 86)", borderColor: "rgb(255, 205, 86)" },
                    { data: adData.notForProfitDashboardAdPerformance.stats.complete, label: "Complete Views", fill: false, backgroundColor: "rgb(75, 192, 192)", borderColor: "rgb(75, 192, 192)" },
                    //{ data: adData.notForProfitDashboardAdPerformance.stats.replay, label: "Replays", fill: false, backgroundColor: "rgb(255, 99, 132)", borderColor: "rgb(255, 99, 132)" },
                ]
            })
        }
    }, [adData])

    const handleDateChange = (source: "start" | "end", newDate: any) => {
        switch (source) {
            case "start":
                setDateRangeStart(newDate)
                break;
            case "end":
                setDateRangeEnd(newDate)
                break;
        }
    }

    return (
        <div className="admin-dashboard">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h2>Overview</h2>
                <div style={{ alignSelf: "center" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div>
                            <DatePicker
                                disableToolbar
                                variant="inline"
                                label="Range Start"
                                value={dateRangeStart}
                                onChange={e => handleDateChange('start', e)}
                            />&nbsp;
                            <DatePicker
                                disableToolbar
                                variant="inline"
                                label="Range End"
                                value={dateRangeEnd}
                                onChange={e => handleDateChange('end', e)}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Card variant="outlined">
                        <CardHeader title="User Favourites" />
                        <CardContent>
                            {favouritesChartData ?
                                <Doughnut
                                    data={favouritesChartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: true
                                    }}
                                />
                                :
                                <CircularProgress style={{ margin: "0 auto" }} size={40} />
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Paper variant="outlined">
                        <CardHeader title="Ad Performance" />
                        {adChartData ?
                            <Line
                                data={adChartData}
                                options={{
                                    responsive: true,
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                            ticks: {
                                                stepSize: 1
                                            }
                                        }
                                    },
                                    maintainAspectRatio: true
                                }}
                            />
                            :
                            <CircularProgress style={{ margin: "0 auto" }} size={40} />
                        }
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}