import gql from 'graphql-tag'

export const GetFavourites = gql`
    query NotForProfitDashboardFavourites($dateRangeStart: DateTime, $dateRangeEnd: DateTime) {
        notForProfitDashboardFavourites(dateRangeStart: $dateRangeStart, dateRangeEnd: $dateRangeEnd) {
            all,
            new
        }
    }
`;

export const GetAdPerformance = gql`
    query NotForProfitDashboardAdPerformance($dateRangeStart: DateTime, $dateRangeEnd: DateTime) {
        notForProfitDashboardAdPerformance(dateRangeStart: $dateRangeStart, dateRangeEnd: $dateRangeEnd) {
            dates,
            labels,
            stats {
                start,
                #midpoint,
                #firstquartile,
                #thirdquartile,
                complete,
                #mute,
                replay,
                #fullscreen,
                #stop,
                #unmute,
                #resume,
                #pause
            }
        }
    }
`;