import React, { useEffect, useMemo, useState } from "react"
import { useMutation, useQuery } from '@apollo/client'
import {
    Button,
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
} from '@material-ui/core'

import { GetNewsQuery, GetNewsQueryVariables, DeleteNewsMutation, DeleteNewsMutationVariables } from '../../generated/graphql'
import { loader } from 'graphql.macro'
import { ContentHeader } from "../../components/common/ContentHeader"
import { useError } from "../../hooks/useError"
import { useHistory } from "react-router-dom"
import { Delete, Edit } from "@material-ui/icons"

const GET_NEWS_QUERY = loader('../../graphql/News/GetNews.graphql');
const DELETE_NEWS_MUTATION = loader('../../graphql/News/DeleteNews.graphql');

export function NotForProfitManageVolunteers() {
    document.title = "Manage Volunteers - ManySeeds"

    const { addError } = useError();
    const history = useHistory()

    const { data: newsData, loading: newsDataLoading, error: newsDataError, refetch: refetchNewsData } = useQuery<GetNewsQuery, GetNewsQueryVariables>(GET_NEWS_QUERY, {
        variables: {
            types: ["volunteers"]
        },
        fetchPolicy: "network-only"
    })
    const [deleteNews, { data: deleteNewsResponse, loading: deleteNewsLoading }] = useMutation<DeleteNewsMutation, DeleteNewsMutationVariables>(DELETE_NEWS_MUTATION)

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [deleteThisNews, setDeleteThisNews] = useState<any>()

    useEffect(() => {
        if (newsDataError) {
            addError("error", "Error fetching data: " + newsDataError.message)
        }
    }, [newsDataError, addError])

    const handleOpenDeleteNews = (newsItem?: any) => {
        setDeleteThisNews(newsItem)
        setShowDeleteDialog(true)
    }

    const handleDeleteNews = async () => {
        console.log('Trigger Delete', deleteThisNews)
        if (!deleteThisNews) {
            addError("error", "An error was encountered while trying to delete the selected item.")
        }

        deleteNews({
            variables: {
                id: deleteThisNews.id
            },
        })
        setDeleteThisNews(undefined)
    }

    useEffect(() => {
        if (deleteNewsResponse) {
            if (deleteNewsResponse.deleteNews) {
                addError('success', "Successfully deleted News item")
                refetchNewsData()
                return
            }
            addError('error', "Error encountered while trying to delete the selected item.")
        }
    }, [deleteNewsResponse, addError, refetchNewsData])

    const deleteDailogText = useMemo(() => {
        if (deleteThisNews) {
            return <DialogContentText color="primary">
                Are you sure you want to delete <b>{deleteThisNews.name}</b>?<br /><br /><strong>This action cannot be undone</strong>
            </DialogContentText>
        }
    }, [deleteThisNews])

    return <>
        <ContentHeader
            title="Manage Volunteer Events"
            loading={newsDataLoading}
            options={[
                { text: "Refresh Data", action: (e: any) => { refetchNewsData() }, devOnly: true },
                { text: "Create VOlunteer Event", action: (e: any) => history.push('/admin/volunteers/add/') }
            ]}
        />
        <TableContainer component={Paper}>
            <Table
                style={{ width: '100%' }}
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Event Date</TableCell>
                        <TableCell>Updated</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="center" width={240}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        newsDataLoading &&
                        <TableRow>
                            <TableCell align="center" colSpan={9}>
                                Fetching Data...
                            </TableCell>
                        </TableRow>
                    }
                    {!newsDataLoading && newsData &&
                        (
                            newsData.getNews.length <= 0 ?
                                <TableRow>
                                    <TableCell align="center" colSpan={9}>No Volunteer Events to display</TableCell>
                                </TableRow>
                                :
                                newsData.getNews.map((newsItem) => {
                                    return <TableRow key={newsItem.id}>
                                        <TableCell>{newsItem.name}</TableCell>
                                        <TableCell>{(newsItem.ongoing === true ? "Ongoing" : new Date(newsItem.eventDate).toLocaleDateString())}</TableCell>
                                        <TableCell>{new Date(newsItem.updatedAt).toDateString()}</TableCell>
                                        <TableCell>{newsItem.status}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Edit">
                                                <IconButton disabled={deleteNewsLoading} onClick={e => history.push('/admin/volunteers/edit/' + newsItem.id)}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton disabled={deleteNewsLoading} onClick={e => handleOpenDeleteNews(newsItem)}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                })
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <Dialog open={showDeleteDialog} title={"Delete News Item"}>
            <DialogTitle>Confirm Volunteer Event Deletion</DialogTitle>
            <DialogContent>
                {deleteDailogText}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary"
                    onClick={e => {
                        setShowDeleteDialog(false)
                        setDeleteThisNews(null)
                    }}
                >Cancel</Button>
                <Button color="primary" variant="contained"
                    onClick={e => {
                        setShowDeleteDialog(false);
                        addError("info", "Deleting Volunteer item...");
                        handleDeleteNews()
                    }}
                >Delete</Button>
            </DialogActions>
        </Dialog>
    </>
}