import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Clear } from '@material-ui/icons';
import { useError } from '../../hooks/useError';

import {
    GetTagsQuery, GetTagsQueryVariables,
    CreateNotForProfitMutation, CreateNotForProfitMutationVariables
} from '../../generated/graphql';

import { UserStatus } from '../../graphql/Fragments/User.enum'

import { ContentHeader } from '../../components/common/ContentHeader';
import { useForm, Controller } from 'react-hook-form';

const GET_TAGS = loader('../../graphql/Tags/GetTags.graphql')
const CREATE_NFP_MUTATION = loader('../../graphql/NotForProfits/CreateNotForProfit.graphql')

export function CreateNotForProfit() {
    document.title = "Not For Profit Details- ManySeeds"

    const history = useHistory()
    const { addError } = useError()

    const { control, handleSubmit, reset, clearErrors, setValue, setError } = useForm();

    const [showPassword, setShowPassword] = useState(false);

    const { data: nfpTagsData, loading: nfpTagsLoading } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS, { variables: { tagType: "nfp" } });
    //const { data: advertisementTagsData, loading: advertisementTagsLoading } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS, { variables: { tagType: "advertisement" } });
    const [createNfp, { data: createNfpData, loading: createNfpLoading, error: createNfpError }] = useMutation<CreateNotForProfitMutation, CreateNotForProfitMutationVariables>(CREATE_NFP_MUTATION);

    const [keywords, setKeywords] = useState<string[]>([]);
    const [currentKeyword, setCurrentKeyword] = useState<string>("");

    const handleAddNfp = async (data: any) => {
        //console.log('handleAddNfp', data);
        let builtNfpTags: Array<{ tagId: number }> = [];
        if (data.tag) {
            data.tag.forEach((value: boolean, index: number) => {
                if (value) {
                    builtNfpTags.push({ tagId: index })
                }
            })
        }

        // let builtAdvertisementTags: Array<{ tagId: number }> = [];
        // if (data.advertisementTag) {
        //     data.advertisementTag.forEach((value: boolean, index: number) => {
        //         if (value) {
        //             builtAdvertisementTags.push({ tagId: index })
        //         }
        //     })
        // }

        // creating
        createNfp({
            variables: {
                nfpData: {
                    name: data.name,
                    description: data.description,
                    city: data.city,
                    logo: data.logo,
                    website: data.website,
                    facebookUrl: data.facebookUrl,
                    twitterUrl: data.twitterUrl,
                    instagramUrl: data.instagramUrl,
                    contactPhone: data.contactPhone,
                    contactEmail: data.contactEmail,
                    charitableNumber: data.charitableNumber,
                    donateLink: data.donateLink,
                    paypalEmail: data.paypalEmail,
                    verified: (data.verified && data.verified === true ? 1 : 0),
                    keywords
                },
                nfpTags: builtNfpTags,
                //advertisementTags: builtAdvertisementTags,
                userData: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phone: data.phone,
                    password: data.password,
                    status: data.status
                }
            }
        })
    }

    const handleComplete = (method?: string) => {
        reset()
        clearErrors()
        history.push('/admin/not-for-profits/')
    }

    useEffect(() => {
        if (createNfpData) {
            if (createNfpData.createNfp.response === "success") {
                addError("success", "Successfully saved Not For Profit");
                handleComplete();
                return
            }

            addError("error", "Unable to save Not For Profit. Please check errors and try again.")
            if (createNfpData.createNfp.errors) {
                createNfpData.createNfp.errors.forEach((value: any, index: number) => {
                    setError(value.field, { message: value.message })
                })
            }
        }
    }, [createNfpData, addError])

    const addKeyword = (e: any) => {
        if (e.keyCode !== 13) { return }

        e.preventDefault();
        if (!e.target.value.trim()) { return }

        setKeywords(kws => {
            const tempArray = [...kws, e.target.value.trim()];
            return [...tempArray];
        });
        setCurrentKeyword("");
    }

    const removeKeyword = (index: number) => {
        setKeywords(kws => {
            const tempKws: string[] = [...kws];
            tempKws.splice(index, 1);
            return [...tempKws];
        });
    }

    return <>
        <Grid container>
            <Grid item xs={12}>
                <ContentHeader
                    title="Create Not For Profit"
                    options={[
                        { text: "Return to List", action: (e: any) => history.go(-1) }
                    ]}
                    //loading={nfpTagsLoading || advertisementTagsLoading}
                    loading={nfpTagsLoading}
                />
            </Grid>
            <form onSubmit={handleSubmit(handleAddNfp)} name="CreateNfp">
                <Paper className="form-paper">
                    <Grid item xs={12} container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <h2>User Details</h2>
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <TextField label="User First Name" required fullWidth margin="none" {...field} />
                                }
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField label="User Last Name" required fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="email"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField label="User Email" required inputMode="email" fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: true, minLength: 6 }}
                                render={({ field }) => <TextField label="Password"
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={(e) => setShowPassword((p) => !p)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                >
                                                    {showPassword ? (
                                                        <Visibility htmlColor="#AEAEAE" />
                                                    ) : (
                                                        <VisibilityOff htmlColor="#2E2E2E" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    required fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="contactPhone"
                                control={control}
                                render={({ field }) => <TextField label="Public Contact Phone" inputMode="tel" fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="contactEmail"
                                control={control}
                                render={({ field }) => <TextField label="Public Contact Email" inputMode="email" fullWidth margin="dense" {...field} />}
                            />
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="verified"
                                        control={control}
                                        render={({ field }) => <Checkbox {...field} />}
                                    />
                                }
                                label="Charity Verified?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h2>Organization Details</h2>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField label="Organization Name" required fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="charitableNumber"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField label="Charitable #" required fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="city"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField label="Region" required fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => <TextField label="Description" fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="logo"
                                control={control}
                                render={({ field }) => <TextField label="Logo" fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="website"
                                control={control}
                                render={({ field }) => <TextField label="Website" fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="facebookUrl"
                                control={control}
                                render={({ field }) => <TextField label="Facebook URL" fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="twitterUrl"
                                control={control}
                                render={({ field }) => <TextField label="Twitter URL" fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="instagramUrl"
                                control={control}
                                render={({ field }) => <TextField label="Instagram URL" fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="donateLink"
                                control={control}
                                render={({ field }) => <TextField label="Donate Link" fullWidth margin="dense" {...field} />}
                            />
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => <TextField select label="Status" fullWidth margin="dense" {...field}
                                    onChange={e => field.onChange(e.target.value)}
                                >
                                    <MenuItem key={UserStatus.ACTIVE} value={UserStatus.ACTIVE}>Active</MenuItem>
                                    <MenuItem key={UserStatus.INACTIVE} value={UserStatus.INACTIVE}>Inactive</MenuItem>
                                    <MenuItem key={UserStatus.SUSPENDED} value={UserStatus.SUSPENDED}>Suspended</MenuItem>
                                    <MenuItem key={UserStatus.BANNED} value={UserStatus.BANNED}>Banned</MenuItem>
                                    <MenuItem key={UserStatus.AWAITING_APPROVAL} value={UserStatus.AWAITING_APPROVAL}>Awaiting Approval</MenuItem>
                                </TextField>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Keywords"
                                value={currentKeyword}
                                onChange={e => setCurrentKeyword(e.currentTarget.value)}
                                onKeyDown={addKeyword}
                                fullWidth
                                margin="dense"
                                helperText="Type a keyword, and press enter to add to list"
                            />
                            <div className="keyword-list">
                                {
                                    keywords.map((value: any, index: number) => {
                                        return <div key={index}>
                                            {value} <IconButton size="small" onClick={e => removeKeyword(index)}><Clear htmlColor="#ff0000" /></IconButton>
                                        </div>
                                    })
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <h3>Not For Profit Category</h3>
                            {nfpTagsData?.getTags.map((tag: any) => {
                                return <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={`tag[${tag.id}]`}
                                                control={control}
                                                defaultValue={false}
                                                render={({ field: props }) =>
                                                    <Checkbox
                                                        {...props}
                                                        onChange={e => props.onChange(e.target.checked)}
                                                    />
                                                }
                                            />
                                        }
                                        label={tag.name}
                                    />
                                </FormControl>
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <h3>Paypal Details</h3>
                            <Controller
                                name="paypalEmail"
                                control={control}
                                render={({ field }) => <TextField label="Paypal Email" fullWidth margin="dense" {...field} />}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <h3>Advertisement Interest</h3>
                            {advertisementTagsData?.getTags.map((tag: any) => {
                                return <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={`advertisementTag[${tag.id}]`}
                                                control={control}
                                                defaultValue={false}
                                                render={({ field: props }) =>
                                                    <Checkbox
                                                        {...props}
                                                        onChange={e => props.onChange(e.target.checked)}
                                                    />
                                                }
                                            />
                                        }
                                        label={tag.name}
                                    />
                                </FormControl>
                            })}
                        </Grid> */}
                        <Grid item xs={12}>
                            <Button disabled={createNfpLoading} type="submit" color="primary" variant="contained"
                            >{createNfpLoading && <CircularProgress size={11} style={{ marginRight: "10px" }} />}Submit</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Grid>
    </>
}