import { Box, Button, LinearProgress, Typography } from "@material-ui/core"

export function ContentHeader(props: { title: string, options?: {text: string, action: (e: any) => void, devOnly?: boolean}[], loading?: boolean }) {
    return <>
        <Box className="content-header" display="grid" gridGap="1em" gridTemplateColumns="1fr auto" margin="1em 0em" alignItems="center">
            <Typography className="title" color="primary" variant="h2">{props.title}</Typography>
            {props.options && props.options.length > 0 &&
                <div className="options">
                    {
                        props.options.map((value: {text: string, action: (e: any) => void, devOnly?: boolean}, index: number) => {
                            if(value.devOnly && process.env.NODE_ENV !== "development") {
                                return <></>
                            }

                            return <Button 
                                variant="contained"
                                color="primary"
                                onClick={value.action}
                                key={index}
                            >{value.text}</Button>
                        })
                    }
                </div>
            }
        </Box>
        {props.loading && <LinearProgress style={{position:"relative",top:"-30px"}} />}
    </>
}