import React, { useState } from 'react'
import { Tab, Tabs, TabPanel } from '../../components/common'
import { ManageUsersTab } from '../../components/ManageUsers/ManageUsersTab'

export function ManageUsers() {
    document.title = "Manage Users - ManySeeds"
    const [currentTab, setCurrentTab] = useState(0)

    return (
        <div>
            <Tabs
                value={currentTab}
                onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                    setCurrentTab(newValue)
                }}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="Users" key={0} />
                <Tab label="Administrators" key={1} />
            </Tabs>
            <TabPanel value={currentTab} index={0}>
                <ManageUsersTab userRole="user" label="Users" />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <ManageUsersTab userRole="admin" label="Administrators" />
            </TabPanel>
        </div>
    )
}
