import React, { useEffect, useState } from 'react'
import { useQueryParameters } from '../../AdminRouter';
import { useQuery } from '@apollo/client';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CardContent, CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ExpandMore } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { Line } from "react-chartjs-2";
import { loader } from 'graphql.macro';
import { ExportCSV, ExportXLSX } from '../../utils/exporter';

import {NotForProfitGetReportsQuery, NotForProfitGetReportsQueryVariables} from '../../generated/graphql'
import { ContentHeader } from '../../components/common/ContentHeader';

const GET_REPORT_DATA = loader('../../graphql/Reporting/NotForProfitGetReports.graphql')

export function NotForProfitReporting() {
    document.title = "Reporting - ManySeeds"

    const urlParams = useQueryParameters()

    const [showFilterAccordion, setShowFilterAccordion] = useState<boolean>(false);

    const [showExportDialog, setShowExportDialog] = useState<boolean>(false)
    const [exportFormat, setExportFormat] = useState<string>("excel");
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const baseDate = new Date();
    const [dateRangeStart, setDateRangeStart] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth(), 1));
    const [dateRangeEnd, setDateRangeEnd] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 0));

    const [searchFilter, setSearchFilter] = useState<{
        dateRangeStart?: Date,
        dateRangeEnd?: Date,
        banners?: number[]
    }>({
        dateRangeStart,
        dateRangeEnd,
        banners: (urlParams.get("banner") ? [parseInt(urlParams.get("banner")!)] : undefined),
    });

    const { data: reportData, loading: reportLoading, error: reportError, refetch: refetchReportData } = useQuery<NotForProfitGetReportsQuery, NotForProfitGetReportsQueryVariables>(GET_REPORT_DATA, {
        variables: searchFilter
    });

    const [adChartData, setAdChartData] = useState<any>();

    const handleDateChange = (source: "start" | "end", newDate: any) => {
        switch (source) {
            case "start":
                setSearchFilter(filters => ({ ...filters, dateRangeStart: newDate }));
                setDateRangeStart(newDate)
                break;
            case "end":
                setSearchFilter(filters => ({ ...filters, dateRangeEnd: newDate }));
                setDateRangeEnd(newDate)
                break;
        }
    }

    const refetchStats = () => {
        refetchReportData();
    }

    const handleSubmit = () => {
        refetchReportData({ ...searchFilter })
    }

    useEffect(() => {
        if (reportError) {
            console.log('reportError', JSON.stringify(reportError));
        }
    }, [reportLoading, reportError])

    useEffect(() => {
        if (reportData) {
            setAdChartData({
                labels: reportData.getMyCharityReports.dates,
                datasets: [
                    { data: reportData.getMyCharityReports.stats.complete, label: "Complete Views", fill: false, backgroundColor: "rgb(105, 70, 174)", borderColor: "rgb(105, 70, 174)" },
                    { data: reportData.getMyCharityReports.stats.charityReceives, label: "Charity Receives", fill: false, backgroundColor: "rgb(253, 151, 174)", borderColor: "rgb(253, 151, 174)" },
                ]
            })
        }
    }, [reportData])

    const exportData = () => {
        if (!reportData || !["csv", "excel"].includes(exportFormat)) {
            return;
        }

        setExportLoading(true);

        const headersTop = ["Advertiser", "Ad Views", "Charity Portion", "MS Portion", "Advertiser Pays"];
        const headersBottom = [
                    "Totals",
                    reportData.getMyCharityReports.totals.complete.toString(),
            "$" + reportData.getMyCharityReports.totals.charityReceives.toFixed(2)
        ];
        
        if (exportFormat === "csv") {
            ExportCSV(
                "financial_report_" + new Date().toDateString().replaceAll(' ', '-') + ".csv",
                { headersTop, headersBottom }
            );
        } else if (exportFormat === "excel") {
            ExportXLSX(
                "financial_report_" + new Date().toDateString().replaceAll(' ', '-') + ".xlsx",
                { headersTop, headersBottom }
            );
            }
        setExportLoading(false);
        setShowExportDialog(false);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ContentHeader
                    title="Financial Reporting"
                    loading={reportLoading}
                    options={[
                        { text: "Refresh Stats", action: (e: any) => { refetchStats() }, devOnly: true },
                        { text: "Export Data", action: (e: any) => { setShowExportDialog(true) } }
                    ]}
                />
            </Grid>
            <Grid item xs={12}>
                <Accordion expanded={showFilterAccordion} onChange={e => setShowFilterAccordion(sfa => !sfa)}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography style={{ fontSize: 20, fontWeight: "bold" }}>Filter</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container style={{ marginTop: "2em" }} spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DatePicker
                                        disableToolbar
                                        variant="inline"
                                        label="Range Start"
                                        value={dateRangeStart}
                                        style={{ width: "100%" }}
                                        onChange={e => handleDateChange('start', e)}
                                    />&nbsp;
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DatePicker
                                        disableToolbar
                                        variant="inline"
                                        label="Range End"
                                        value={dateRangeEnd}
                                        style={{ width: "100%" }}
                                        onChange={e => handleDateChange('end', e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} style={{ alignSelf: "center" }}>
                                    <Button variant="contained" color="primary" onClick={e => handleSubmit()}>Submit Filters</Button>
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Paper variant="outlined">
                    <CardHeader title="Performance" />
                    <CardContent>
                        {adChartData ?
                            <Line
                                data={adChartData}
                                options={{
                                    responsive: true,
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                            ticks: {
                                                stepSize: 1
                                            }
                                        }
                                    },
                                    maintainAspectRatio: true
                                }}
                            />
                            :
                            <div>Loading...</div>
                        }
                    </CardContent>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right">Ad Views</TableCell>
                                <TableCell align="right">Charity Receives</TableCell>
                            </TableRow>
                        </TableHead>
                        {reportData && reportData.getMyCharityReports.totals &&
                            <TableBody>
                                <TableRow>
                                    <TableCell><b>Totals</b></TableCell>
                                    <TableCell align="right"><b>{reportData.getMyCharityReports.totals.complete}</b></TableCell>
                                    <TableCell align="right"><b>${reportData.getMyCharityReports.totals.charityReceives.toFixed(2)}</b></TableCell>
                                </TableRow>
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </Grid>
            <Dialog open={showExportDialog} title="Export Format" onClose={() => setShowExportDialog(show => !show)} maxWidth="sm">
                <DialogTitle>Export Format</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        disabled={exportLoading}
                        label="Export Format"
                        type="number"
                        value={exportFormat}
                        onChange={e => {
                            setExportFormat(e.target.value);
                        }}
                        fullWidth
                    >
                        <MenuItem
                            key="exportExcel"
                            value={"excel"}
                        >Excel (XLSX)</MenuItem>
                        <MenuItem
                            key="exportCsv"
                            value={"csv"}
                        >CSV</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button disabled={exportLoading} variant="outlined" color="secondary" onClick={e => {
                        exportData()
                    }}>{exportLoading && <CircularProgress size={20} style={{ marginRight: "20px" }} />}Export</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
