import React, { useEffect, useMemo, useState } from "react"
import { useMutation, useQuery } from '@apollo/client'
import {
    Button,
    CardMedia,
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Typography,
} from '@material-ui/core'

import {
    ReviveBanner,
    GetBannersQuery, GetBannersQueryVariables,
    DeleteBannerMutation, DeleteBannerMutationVariables
} from '../../generated/graphql'
import { loader } from 'graphql.macro'
import { ContentHeader } from "../../components/common/ContentHeader"
import { useError } from "../../hooks/useError"
import { useHistory } from "react-router-dom"
import { BarChartTwoTone, Delete, Edit, PlayArrow } from "@material-ui/icons"

import { BannerStatus } from "../../graphql/Fragments/Banner.enum"

const GET_BANNERS_QUERY = loader('../../graphql/Advertisements/GetBanners.graphql')
const DELETE_BANNER_MUTATION = loader('../../graphql/Advertisements/DeleteBanner.graphql')

export function AdvertiserManageAdvertisements() {
    document.title = "Manage Advertisements - ManySeeds"

    const { addError } = useError();
    const history = useHistory()

    const [previewBanner, setPreviewBanner] = useState<ReviveBanner | undefined>()
    const [showPreviewDialog, setShowPreviewDialog] = useState<boolean>(false)

    const { data: bannerData, loading: bannerDataLoading, error: bannerDataError, refetch: refetchBannerData } = useQuery<GetBannersQuery, GetBannersQueryVariables>(GET_BANNERS_QUERY, {
        nextFetchPolicy: "network-only"
    })
    const [deleteBanner, { data: deleteBannerResponse, loading: deleteBannerLoading }] = useMutation<DeleteBannerMutation, DeleteBannerMutationVariables>(DELETE_BANNER_MUTATION)

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [deleteThisBanner, setDeleteThisBanner] = useState<any>()

    // useEffect(() => {
    //     //Refetch on component re-mounting
    //     if (bannerData && !bannerDataLoading) {
    //         console.log('Refreshing');
    //         refetchBannerData();
    //     }
    // }, [refetchBannerData, bannerData, bannerDataLoading])

    useEffect(() => {
        if (bannerData && !bannerDataLoading) {
            refetchBannerData()
        }
    }, []);

    useEffect(() => {
        if (bannerDataError) {
            addError("error", "Error fetching data: " + bannerDataError.message)
        }
    }, [bannerDataError, addError])

    const handleOpenDeleteAdvertisement = (banner?: any) => {
        setDeleteThisBanner(banner)
        setShowDeleteDialog(true)
    }

    const handleDeleteAdvertisement = async () => {
        console.log('Trigger Delete', deleteThisBanner)
        if (!deleteThisBanner) {
            addError("error", "An error was encountered while trying to delete the selected item.")
        }

        deleteBanner({
            variables: {
                bannerId: deleteThisBanner.bannerId
            },
        })
        setDeleteThisBanner(undefined)
    }

    useEffect(() => {
        if (deleteBannerResponse) {
            if (deleteBannerResponse.deleteBanner) {
                addError('success', "Successfully deleted Advertisement")
                refetchBannerData()
                return
            }
            addError('error', "Error encountered while trying to delete the selected item.")
        }
    }, [deleteBannerResponse, addError, refetchBannerData])

    const deleteDailogText = useMemo(() => {
        if (deleteThisBanner) {
            return <DialogContentText color="primary">
                Are you sure you want to delete <b>{deleteThisBanner.name}</b>?<br /><br /><strong>This action cannot be undone</strong>
            </DialogContentText>
        }
    }, [deleteThisBanner])

    const getStatus = (status: number) => {
        switch (status) {
            case BannerStatus.ACTIVE:
                return "Active";
            case BannerStatus.INACTIVE:
                return "Inactive";
            case BannerStatus.AWAITING_APPROVAL:
                return "Awaiting Approval";
            case BannerStatus.DELETED:
                return "Deleted";
        }
    }

    const showPreview = (banner: any) => {
        setPreviewBanner(banner)
        setShowPreviewDialog(true)
    }

    const previewDialog = useMemo(() => {
        return <>
            {previewBanner && previewBanner.videoPath ?
                <>
                    <DialogContent>
                        <CardMedia
                            component="video"
                            image={`${process.env.REACT_APP_S3_ENDPOINT}/videos/${previewBanner.videoPath}`}
                            title="Video Preview"
                            controls
                            style={{
                                marginBottom: "1em",
                                height: "500px",
                                maxHeight: "95%",
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Grid container>
                            <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                                <Button variant="outlined" color="secondary" onClick={e => {
                                    setPreviewBanner(undefined)
                                    setShowPreviewDialog(false)
                                }}>Close Preview
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </>
                :
                <DialogContent><Typography>No Preview to Display</Typography></DialogContent>
            }
        </>
    }, [previewBanner])

    return <>
        <ContentHeader
            title="Manage Advertisements"
            loading={bannerDataLoading}
            options={[
                { text: "Refresh Data", action: (e: any) => { refetchBannerData() }, devOnly: true },
                { text: "Create Advertisement", action: (e: any) => history.push('/admin/advertisements/add/') }
            ]}
        />
        <TableContainer component={Paper}>
            <Table
                style={{ width: '100%' }}
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell width="140px">AdvertisementID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Updated</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Views</TableCell>
                        <TableCell>Budgeted</TableCell>
                        <TableCell>Used</TableCell>
                        <TableCell>Remaining</TableCell>
                        <TableCell align="center" width={240}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        bannerDataLoading &&
                        <TableRow>
                            <TableCell align="center" colSpan={10}>
                                Fetching Data...
                            </TableCell>
                        </TableRow>
                    }
                    {!bannerDataLoading && bannerData &&
                        (
                            bannerData.getAllReviveBanners.length <= 0 ?
                                <TableRow>
                                    <TableCell align="center" colSpan={9}>No Advertisements to display</TableCell>
                                </TableRow>
                                :
                                bannerData.getAllReviveBanners.map((banner) => {
                                    const currentBudget: number = banner.advertiserBanner.currentBudget ? banner.advertiserBanner.currentBudget : 0.00;
                                    const usedBudget: number = banner.advertiserBanner.usedBudget ? banner.advertiserBanner.usedBudget : 0.00;
                                    const remainingBudget = (currentBudget - usedBudget > 0 ? currentBudget - usedBudget : 0);

                                    return <TableRow key={banner.bannerId}>
                                        <TableCell>{banner.bannerId}</TableCell>
                                        <TableCell>{banner.name}</TableCell>
                                        <TableCell>{new Date(banner.updated).toDateString()}</TableCell>
                                        <TableCell>{getStatus(banner.status)}</TableCell>
                                        <TableCell>{banner.stats.complete}</TableCell>
                                        <TableCell>${currentBudget.toFixed(2)}</TableCell>
                                        <TableCell>${usedBudget.toFixed(2)}</TableCell>
                                        <TableCell>${remainingBudget.toFixed(2)}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Preview Video">
                                                <IconButton onClick={e => showPreview(banner)}>
                                                    <PlayArrow />
                                                </IconButton>
                                            </Tooltip>
                                            <IconButton
                                                onClick={e => {
                                                    history.push('/admin/reports/?banner=' + banner.bannerId)
                                                }}
                                            >
                                                <Tooltip title="Advertiser Stats Performance">
                                                    <BarChartTwoTone />
                                                </Tooltip>
                                            </IconButton>
                                            <Tooltip title="Edit">
                                                <IconButton disabled={deleteBannerLoading} onClick={e => history.push('/admin/advertisements/edit/' + banner.bannerId)}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton disabled={deleteBannerLoading} onClick={e => handleOpenDeleteAdvertisement(banner)}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                })
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <Dialog open={showDeleteDialog} title={"Delete Advertisement"}>
            <DialogTitle>Confirm Advertisement Deletion</DialogTitle>
            <DialogContent>
                {deleteDailogText}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary"
                    onClick={e => {
                        setShowDeleteDialog(false)
                        setDeleteThisBanner(null)
                    }}
                >Cancel</Button>
                <Button color="primary" variant="contained"
                    onClick={e => {
                        setShowDeleteDialog(false);
                        addError("info", "Deleting Advertisement...");
                        handleDeleteAdvertisement()
                    }}
                >Delete</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showPreviewDialog} title="Preview Advertisement">
            <DialogTitle>Preview Advertisement</DialogTitle>
            {previewDialog}
        </Dialog>
    </>
}