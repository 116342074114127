/**
 * Yes, it's context and not a hook - but the hooks folder feels more like providers than only hooks :)
 */
import React, { useState, useCallback, PropsWithChildren } from 'react'

interface IErrorMessage {
    id?: string,
    type: "success" | "info" | "error",
    message: string,
    timer?: number,
    code?: string
}

interface IErrorsContext {
    errors: IErrorMessage[],
    addError: (type: "success" | "info" | "error", message:string, timer?: number, code?: string) => void,
    removeError: () => void
}

export const ErrorContext = React.createContext<IErrorsContext>({
    errors: [],
    addError: (type: "success" | "info" | "error", message:string, timer?: number, code?: string) => {},
    removeError: () => {}
})


export const ErrorProvider = ({ children }: PropsWithChildren<{}>) => {
    const [errors, setErrors] = useState<IErrorMessage[]>([])
    
    const addError = (type: "success" | "info" | "error", message: string, timer?: number, code?: string) => {
        //If a code is provided, lets check if that error code exists in the error stack
        //If it does, let's not requeue the error
        if(code) {
            const dedupeErrors = errors.filter(error => {
                return error.code === code
            })
            if(dedupeErrors.length > 0) {
                return
            }
        }

        setErrors(errors => {
            const id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
            const tempNewError: IErrorMessage = {
                id, type, message, timer, code
            }
            return [...errors, tempNewError]
        })
    }

    // const getNextError = (): IErrorMessage | false => {
    //     if(!errors || errors.length <= 0) {
    //         return false
    //     }
    //     const tempErr = errors.shift()
    //     setErrors(errors)
    //     return (!tempErr ? false : tempErr)
    // }

    // const removeLastError = () => {
    //     setErrors(err => {
    //         err.pop()
    //         return [...err]
    //     })
    // }

    const removeNextError = () => {
        setErrors(err => {
            err.shift()
            return [...err]
        })
    }

    const contextValue = {
        errors,
        addError: useCallback((type: "success" | "info" | "error", message: string, timer?: number, code?: string) => 
            addError(type, message, timer, code
        ), []),
        removeError: useCallback(() => removeNextError(), [])
    }

    return <ErrorContext.Provider value={contextValue}>
        {children}
    </ErrorContext.Provider>

}