import { Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Warning } from '@material-ui/icons';

export function NotFound() {
    const { replace } = useHistory()

    return <>
        <Box style={{ textAlign: "center",paddingTop:"3rem"}}>
            <Warning style={{fontSize:"90px",color:"#ff0000"}} />
            <h2 style={{ fontSize: "3rem" }}>404: Not Found</h2>
            <div style={{ fontSize: "1.5rem" }}>It seems the page you are looking for isn't here!</div>
            <div style={{ marginTop: "4rem" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={e => replace('/admin/dashboard')}
                >Return to Dashboard</Button>
            </div>
        </Box>
    </>
}