import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Button,
    CircularProgress,
    MenuItem,
    Grid,
    Paper,
    TextField,
} from '@material-ui/core';
import { useError } from '../../hooks/useError';
import FormLoadingOverlay from '../../components/common/FormLoadingOverlay';

import { UpdateNewsMutation, UpdateNewsMutationVariables, AdminGetNewsByIdQuery, AdminGetNewsByIdQueryVariables } from '../../generated/graphql';
import { ContentHeader } from '../../components/common/ContentHeader';

import { useForm, Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const EDIT_NEWS_MUTATION = loader('../../graphql/News/UpdateNews.graphql');
const GET_NEWS_QUERY = loader('../../graphql/News/AdminGetNewsById.graphql');

export function AdminEditVolunteers() {
    const { newsId } = useParams<{ newsId: string }>()
    const history = useHistory()
    const { control, handleSubmit, reset, setError } = useForm();
    const { addError } = useError()

    const { data: newsData, loading: newsDataLoading } = useQuery<AdminGetNewsByIdQuery, AdminGetNewsByIdQueryVariables>(GET_NEWS_QUERY, {
        variables: {
            id: parseInt(newsId)
        },
        fetchPolicy: "network-only"
    });
    const [editNews, { data: editNewsResponse, loading: editNewsLoading }] = useMutation<UpdateNewsMutation, UpdateNewsMutationVariables>(EDIT_NEWS_MUTATION);

    useEffect(() => {
        //Unmount - destroy the data object
        return () => {
            reset()
        }
    }, [reset])

    const handleEditNews = async (data: any, e: any) => {
        e.preventDefault()

        data.type = "volunteers";
        if(data.ongoing === true) {
            data.eventDate = null;
            data.timeString = null;
        }

        editNews({
            variables: {
                ...data,
                id: parseInt(newsId)
            }
        })
    }

    const handleComplete = (method?: string) => {
        reset()
        history.push('/admin/volunteers/');
    }

    useEffect(() => {
        if (editNewsResponse) {
            if (editNewsResponse.updateNews.response === "success") {
                if (editNewsResponse.updateNews.errors && editNewsResponse.updateNews.errors.length > 0) {
                    history.push(`/admin/volunteers/edit/${editNewsResponse.updateNews.responseData}`);
                    addError("info", "Volunteer item saved with errors. Please check data.")
                    editNewsResponse.updateNews.errors.forEach((value: any, index: number) => {
                        setError(value.field, { message: value.message });
                    })
                } else {
                    addError("success", editNewsResponse.updateNews.message);
                    handleComplete();
                }
                return;
            }

            addError("error", "Unable to save Volunteer item. Please check errors and try again.")
            if (editNewsResponse.updateNews.errors) {
                editNewsResponse.updateNews.errors.forEach((value: any, index: number) => {
                    setError(value.field, value.message)
                })
            }
        }
    }, [addError, history, setError, editNewsResponse])

    return <>
        <form style={{ width: "100%" }} onSubmit={handleSubmit(handleEditNews)} name="UpdateNews">
            <Grid container>
                <Grid item xs={12}>
                    <ContentHeader
                        title="Edit Volunteer Event"
                        loading={newsDataLoading}
                        options={[
                            { text: "Return to Manage Volunteer Events", action: (e: any) => history.go(-1) }
                        ]}
                    />
                </Grid>
                <Paper className="form-paper">
                    {!newsDataLoading && newsData && newsData.getNewsById ?
                        <>
                            <TextField
                                margin="none"
                                label="Owner"
                                disabled
                                value={newsData.getNewsById!.notForProfit ? newsData.getNewsById.notForProfit.name : "Error"}
                                fullWidth
                            />
                            <Controller
                                name="name"
                                defaultValue={newsData.getNewsById.name}
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        margin="none"
                                        label="Title / Event Name"
                                        fullWidth
                                        {...field}
                                    />
                                }
                            />
                            <Controller
                                name="description"
                                defaultValue={newsData.getNewsById.description}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        type="text"
                                        margin="dense"
                                        label="Full Description"
                                        fullWidth
                                        multiline
                                        rows={5}
                                        maxRows={10}
                                        {...field}
                                    />
                                }
                            />
                            <Controller
                                name="contact"
                                defaultValue={newsData.getNewsById.contact}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        margin="none"
                                        label="Contact Name"
                                        fullWidth
                                        {...field}
                                    />
                                }
                            />
                            <Controller
                                name="contactPhone"
                                defaultValue={newsData.getNewsById.contactPhone}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        margin="none"
                                        label="Contact Phone"
                                        fullWidth
                                        {...field}
                                    />
                                }
                            />
                            <Controller
                                name="contactEmail"
                                defaultValue={newsData.getNewsById.contactEmail}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        margin="none"
                                        label="Contact Email"
                                        fullWidth
                                        {...field}
                                    />
                                }
                            />
                            <Controller
                                name="website"
                                defaultValue={newsData.getNewsById.website}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        margin="none"
                                        label="Information URL"
                                        fullWidth
                                        {...field}
                                    />
                                }
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Controller
                                    name="eventDate"
                                    control={control}
                                    defaultValue={(newsData.getNewsById.eventDate ? new Date(newsData.getNewsById.eventDate).toLocaleDateString() : "")}
                                    render={({ field }) =>
                                        <DatePicker
                                            label="Event Date"
                                            fullWidth
                                            {...field}
                                        />
                                    }
                                />
                                <Controller
                                    name="timeString"
                                    control={control}
                                    defaultValue={newsData.getNewsById.timeString}
                                    render={({ field }) =>
                                        <TimePicker
                                            margin="none"
                                            label="Event Time"
                                            fullWidth
                                            {...field}
                                        />
                                    }
                                />
                            </MuiPickersUtilsProvider>
                            <div style={{ marginTop: 15 }}>
                                <Controller
                                    name="status"
                                    defaultValue={newsData.getNewsById.status}
                                    control={control}
                                    render={({ field }) => <TextField select label="Status" fullWidth margin="dense" {...field}
                                        onChange={e => field.onChange(e.target.value)}
                                    >
                                        <MenuItem key={"status-active"} value={"active"}>Active</MenuItem>
                                        <MenuItem key={"status-inactive"} value={"inactive"}>Inactive</MenuItem>
                                    </TextField>
                                    }
                                />
                            </div>
                            <div style={{ marginTop: "1em" }}>
                                <Button disabled={editNewsLoading} type="submit" color="primary" variant="contained">
                                    {editNewsLoading && <CircularProgress size={12} style={{ marginRight: "20px" }} />} Submit
                                </Button>
                            </div>
                        </>
                        :
                        <FormLoadingOverlay show={true} />
                    }
                </Paper>
            </Grid>
        </form>
    </>
}