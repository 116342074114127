import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Button,
    CircularProgress,
    MenuItem,
    Grid,
    Paper,
    TextField,
} from '@material-ui/core';
import { useError } from '../../hooks/useError';

import { CreateNewsMutation, CreateNewsMutationVariables } from '../../generated/graphql';
import { ContentHeader } from '../../components/common/ContentHeader';

import { useForm, Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const GET_NEWS_QUERY = loader('../../graphql/News/GetNews.graphql');
const CREATE_NEWS_MUTATION = loader('../../graphql/News/CreateNews.graphql')

export function NotForProfitCreateNews() {
    const history = useHistory()

    const { control, handleSubmit, reset, setError, watch } = useForm();
    const { addError } = useError()

    const watchType = watch("type", "news");

    const [activeMode, setActiveMode] = useState<"add" | "edit" | "disabled">("add");
    const [createNews, { data: createNewsResponse, loading: createNewsLoading }] = useMutation<CreateNewsMutation, CreateNewsMutationVariables>(CREATE_NEWS_MUTATION);

    useEffect(() => {
        //Unmount - destroy the data object
        return () => {
            reset()
            setActiveMode("add")
        }
    }, [reset])

    const handleAddNews = async (data: any, e: any) => {
        e.preventDefault()

        if (activeMode === "disabled") {
            addError("error", "Save is disabled due to an encountered error.")
            return
        }

        // creating
        createNews({
            variables: {
                ...data
            },
            refetchQueries: [
                GET_NEWS_QUERY
            ]
        })
    }

    const handleComplete = (method?: string) => {
        reset()
        history.push('/admin/news/');
    }

    useEffect(() => {
        if (createNewsResponse) {
            if (createNewsResponse.createNews.response === "success") {
                if (createNewsResponse.createNews.errors && createNewsResponse.createNews.errors.length > 0) {
                    history.push(`/admin/news/edit/${createNewsResponse.createNews.responseData}`);
                    addError("info", "News item saved with errors. Please check data.")
                    createNewsResponse.createNews.errors.forEach((value: any, index: number) => {
                        setError(value.field, { message: value.message });
                    })
                } else {
                    addError("success", createNewsResponse.createNews.message);
                    handleComplete();
                }
                return;
            }

            addError("error", "Unable to save News item. Please check errors and try again.")
            if (createNewsResponse.createNews.errors) {
                createNewsResponse.createNews.errors.forEach((value: any, index: number) => {
                    setError(value.field, value.message)
                })
            }
        }
    }, [addError, history, setError, createNewsResponse])

    return <>
        <form style={{ width: "100%" }} onSubmit={handleSubmit(handleAddNews)} name="CreateNews">
            <Grid container>
                <Grid item xs={12}>
                    <ContentHeader
                        title="Create News or Event"
                        options={[
                            { text: "Return to Manage News", action: (e: any) => history.go(-1) }
                        ]}
                    />
                </Grid>
                <Paper className="form-paper">
                    <Controller
                        name="type"
                        defaultValue={"news"}
                        control={control}
                        render={({ field }) => <TextField select label="Type" fullWidth margin="dense" {...field}
                            onChange={e => field.onChange(e.target.value)}
                        >
                            <MenuItem key={"type-news"} value={"news"}>News</MenuItem>
                            <MenuItem key={"type-event"} value={"events"}>Event</MenuItem>
                        </TextField>
                        }
                    />
                    <Controller
                        name="name"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) =>
                            <TextField
                                margin="none"
                                label="Title / Event Name"
                                fullWidth
                                {...field}
                            />
                        }
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                type="text"
                                margin="dense"
                                label="Full Description"
                                fullWidth
                                multiline
                                rows={5}
                                maxRows={10}
                                {...field}
                            />
                        }
                    />
                    <Controller
                        name="contact"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                margin="none"
                                label="Contact Name"
                                fullWidth
                                {...field}
                            />
                        }
                    />
                    <Controller
                        name="contactPhone"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                margin="none"
                                label="Contact Phone"
                                fullWidth
                                {...field}
                            />
                        }
                    />
                    <Controller
                        name="contactEmail"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                margin="none"
                                label="Contact Email"
                                fullWidth
                                {...field}
                            />
                        }
                    />
                    <Controller
                        name="website"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                margin="none"
                                label="Information URL"
                                fullWidth
                                {...field}
                            />
                        }
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {watchType === "events" &&
                            <>
                                <Controller
                                    name="eventDate"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) =>
                                        <DatePicker
                                            label="Event Date"
                                            fullWidth
                                            {...field}
                                        />
                                    }
                                />
                                <Controller
                                    name="timeString"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) =>
                                        <TimePicker 
                                            margin="none"
                                            label="Event Time"
                                            fullWidth
                                            {...field}
                                        />
                                    }
                                />
                            </>
                        }
                    </MuiPickersUtilsProvider>
                    <div style={{ marginTop: 15 }}>
                        <Controller
                            name="status"
                            defaultValue={"active"}
                            control={control}
                            render={({ field }) => <TextField select label="Status" fullWidth margin="dense" {...field}
                                onChange={e => field.onChange(e.target.value)}
                            >
                                <MenuItem key={"status-active"} value={"active"}>Active</MenuItem>
                                <MenuItem key={"status-inactive"} value={"inactive"}>Inactive</MenuItem>
                            </TextField>
                            }
                        />
                    </div>
                    <div style={{ marginTop: "1em" }}>
                        <Button disabled={createNewsLoading || activeMode === "disabled"} type="submit" color="primary" variant="contained">
                            {createNewsLoading && <CircularProgress size={12} style={{ marginRight: "20px" }} />} Submit
                        </Button>
                    </div>
                </Paper>
            </Grid>
        </form>
    </>
}