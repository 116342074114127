import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Paper,
    TextField,
} from '@material-ui/core';
import { useError } from '../../hooks/useError';
import { FileUploader } from '../../components/common/FileUploader'
import FormLoadingOverlay from '../../components/common/FormLoadingOverlay';

import {
    CreateBannerMutation, CreateBannerMutationVariables,
    GetLocationsQuery,
    GetLocationsQueryVariables,
    GetMyAdvertiserQuery, GetMyAdvertiserQueryVariables,
    //GetTagsQuery, GetTagsQueryVariables
} from '../../generated/graphql';
import { ContentHeader } from '../../components/common/ContentHeader';

import { useForm, Controller } from 'react-hook-form';
import { UserAgeRanges } from '../../graphql/Fragments/User.enum';

const GET_BANNERS_QUERY = loader('../../graphql/Advertisements/GetBanners.graphql')
const GET_MY_ADVERTISER = loader('../../graphql/Advertisers/GetMyAdvertiser.graphql')
const CREATE_BANNER_MUTATION = loader('../../graphql/Advertisements/CreateBanner.graphql')
const GET_LOCATIONS_QUERY = loader('../../graphql/NotForProfits/GetLocations.graphql')

export function AdvertiserCreateAdvertisement() {
    const history = useHistory();

    const { control, handleSubmit, reset, setError } = useForm();
    const { addError } = useError();

    const { data: advertiserData, loading: advertiserDataLoading } = useQuery<GetMyAdvertiserQuery, GetMyAdvertiserQueryVariables>(GET_MY_ADVERTISER);
    const { data: locationData, loading: locationDataLoading } = useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GET_LOCATIONS_QUERY);

    const [activeMode, setActiveMode] = useState<"add" | "edit" | "disabled">("add");
    const [createBanner, { data: createBannerResponse, loading: createBannerLoading }] = useMutation<CreateBannerMutation, CreateBannerMutationVariables>(CREATE_BANNER_MUTATION, {
        refetchQueries: [
            GET_BANNERS_QUERY
        ]
    });
    //const { data: tagsData, loading: tagsLoading } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS_QUERY, { variables: { tagType: "advertisement" } });

    const [uploadFile, setUploadFile] = useState<File>();
    const [uploadType, setUploadType] = useState<"video" | "image">("video");

    useEffect(() => {
        //Unmount - destroy the data object
        return () => {
            reset()
            setActiveMode("add")
        }
    }, [reset])

    const handleAddAdvertisement = async (data: any, e: any) => {
        e.preventDefault()

        if (activeMode === "disabled") {
            addError("error", "Save is disabled due to an encountered error.")
            return
        }

        // let builtTags: Array<{ tagId: number }> = [];
        // if (data.tag) {
        //     data.tag.forEach((value: boolean, index: number) => {
        //         if (value) {
        //             builtTags.push({ tagId: index })
        //         }
        //     })
        // }

        const ageGroups = Object.keys(data.ageGroups).filter((k) => data.ageGroups[k]);
        const locations = Object.keys(data.locations).filter((k) => data.locations[k]);

        // creating
        createBanner({
            variables: {
                name: data.name,
                ageGroups: ageGroups,
                locations: locations,
                type: uploadType,
                currentBudget: parseFloat(data.currentBudget),
                videoDuration: parseInt(data.videoDuration),
                clickThroughUrl: data.clickThroughUrl,
                status: 1,
                file: uploadFile,
                //tags: builtTags
            }
        })
    }

    const handleComplete = (method?: string) => {
        reset()
        history.push('/admin/advertisements/');
    }

    useEffect(() => {
        if (createBannerResponse) {
            if (createBannerResponse.createBanner.response === "success") {
                if (createBannerResponse.createBanner.errors && createBannerResponse.createBanner.errors.length > 0) {
                    history.push(`/admin/advertisements/edit/${createBannerResponse.createBanner.responseData}`);
                    addError("info", "Advertisement saved with errors. Please check data.")
                    createBannerResponse.createBanner.errors.forEach((value: any, index: number) => {
                        setError(value.field, { message: value.message });
                    })
                } else {
                    addError("success", createBannerResponse.createBanner.message);
                    handleComplete();
                }
                return;
            }

            addError("error", "Unable to save Advertisement. Please check errors and try again.")
            if (createBannerResponse.createBanner.errors) {
                createBannerResponse.createBanner.errors.forEach((value: any, index: number) => {
                    setError(value.field, value.message)
                })
            }
        }
    }, [addError, history, setError, createBannerResponse])

    return <>
        <form style={{ width: "100%" }} onSubmit={handleSubmit(handleAddAdvertisement)} name="CreateBanner">
            <Grid container>
                <Grid item xs={12}>
                    <ContentHeader
                        title={(activeMode === "edit" ? "Edit" : "Create") + " Advertisement"}
                        //loading={tagsLoading || advertiserDataLoading}
                        loading={advertiserDataLoading}
                        options={[
                            { text: "Return to Advertisements", action: (e: any) => history.go(-1) }
                        ]}
                    />
                </Grid>
                <Paper className="form-paper">
                    {/* !tagsData || !advertiserData ? */}
                    {!advertiserData ?
                        <FormLoadingOverlay show={true} />
                        :
                        <>
                            <h3>Details</h3>
                            <Grid item xs={12} container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="name"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) =>
                                            <TextField
                                                margin="none"
                                                label="Banner Name"
                                                fullWidth
                                                {...field}
                                            />
                                        }
                                    />
                                    <Controller
                                        name="videoDuration"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) =>
                                            <TextField
                                                margin="dense"
                                                label="Video Duration (in seconds)"
                                                fullWidth
                                                {...field}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="clickThroughUrl"
                                        defaultValue={""}
                                        control={control}
                                        render={({ field }) =>
                                            <TextField
                                                type="url"
                                                margin="dense"
                                                label={`Click Through URL`}
                                                fullWidth
                                                {...field}
                                            />
                                        }
                                    />
                                    <Controller
                                        name="currentBudget"
                                        rules={{
                                            required: true,
                                            min: 0,
                                            max: (advertiserData.getMyAdvertiser.currentBalance ? advertiserData.getMyAdvertiser.currentBalance : 0)
                                        }}
                                        control={control}
                                        render={({ field }) =>
                                            <TextField
                                                type="number"
                                                inputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    min: 0,
                                                    max: (advertiserData.getMyAdvertiser.currentBalance ? advertiserData.getMyAdvertiser.currentBalance : 0),
                                                    step: 0.01
                                                }}
                                                margin="dense"
                                                label={`Max Ad Spend (Max $${(advertiserData.getMyAdvertiser.currentBalance ? advertiserData.getMyAdvertiser.currentBalance : 0)})`}
                                                fullWidth
                                                {...field}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <h3>Advertisement</h3>
                            <Grid item xs={12} container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <p style={{ marginBottom: 0 }}>Acceptable Formats:</p>
                                    <ul style={{ marginTop: 0 }}>
                                        <li><b>Videos:</b> MP4, 320px wide by 480px height</li>
                                        <li><b>Images:</b> Jpeg, Gif or PNG, 320px wide by 480px height</li>
                                    </ul>
                                    <p><small>Note: Videos and images are automatically adjusted to suit the screen on the device on which they are displayed.</small></p>
                                    <br />
                                    <Grid item xs={12} container spacing={4}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl style={{ marginBottom: "1em" }}>
                                                <FileUploader
                                                    accept={"video/mp4,application/mp4,image/jpg,image/jpeg,image/png"}
                                                    handleDrop={(files: FileList) => {
                                                        if (["image/jpg", "image/jpeg", "image/png"].includes(files[0].type)) {
                                                            setUploadType("image");
                                                        }
                                                        setUploadFile(files[0])
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <h4>Targeted Age</h4>
                                    <p>Note: It is highly recommended to leave all locations and ages selected unless necessary.</p>
                                    <div>
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_18}]`}
                                                    control={control}
                                                    defaultValue={true}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"Under 18"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_25}]`}
                                                    control={control}
                                                    defaultValue={true}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"18 - 24"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_35}]`}
                                                    control={control}
                                                    defaultValue={true}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"25 - 34"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_45}]`}
                                                    control={control}
                                                    defaultValue={true}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"35 - 44"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_55}]`}
                                                    control={control}
                                                    defaultValue={true}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"45 - 54"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_65}]`}
                                                    control={control}
                                                    defaultValue={true}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"55 - 64"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.OVER_65}]`}
                                                    control={control}
                                                    defaultValue={true}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"65+"}
                                        />
                                    </div>
                                    <h4 style={{marginTop:"1em"}}>Locations</h4>
                                    <div>
                                        {
                                            locationData && locationData.getLocations.map(loc => {
                                                return <FormControl key={`loc-${loc}`}>
                                                    <FormControlLabel
                                                        control={
                                                            <Controller
                                                                name={`locations[${loc}]`}
                                                                control={control}
                                                                defaultValue={true}
                                                                render={({ field: props }) =>
                                                                    <Checkbox
                                                                        {...props}
                                                                        checked={props.value}
                                                                        onChange={e => {
                                                                            props.onChange(e.target.checked)
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        label={loc}
                                                    />
                                                </FormControl>
                                            })
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            {/* <h3>Tags</h3>
                            <Grid item xs={12} container spacing={4}>
                                <Grid item xs={12}>
                                    {tagsData?.getTags.map((tag: any) => {
                                        return <FormControl key={tag.id}>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name={`tag[${tag.id}]`}
                                                        control={control}
                                                        defaultValue={false}
                                                        render={({ field: props }) =>
                                                            <Checkbox
                                                                {...props}
                                                                checked={props.value}
                                                                onChange={e => {
                                                                    props.onChange(e.target.checked)
                                                                }}
                                                            />
                                                        }
                                                    />
                                                }
                                                label={tag.name}
                                            />
                                        </FormControl>
                                    })}
                                </Grid>
                            </Grid> */}
                            <div style={{ marginTop: "1em" }}>
                                <Button disabled={createBannerLoading || activeMode === "disabled"} type="submit" color="primary" variant="contained">
                                    {createBannerLoading && <CircularProgress size={12} style={{ marginRight: "20px" }} />} Submit
                                </Button>
                            </div>
                        </>
                    }
                </Paper>
            </Grid>
        </form>
    </>
}