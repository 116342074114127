import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { loader } from 'graphql.macro';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    TextField,
} from '@material-ui/core';
import { useError } from '../../hooks/useError';
import { useForm, Controller } from 'react-hook-form';
import { ContentHeader } from '../../components/common/ContentHeader'

import {
    GetTagsQuery, GetTagsQueryVariables,
    GetMyAdvertiserQuery, GetMyAdvertiserQueryVariables,
    UpdateAdvertiserMutation, UpdateAdvertiserMutationVariables,
    ChangeMyPasswordMutation, ChangeMyPasswordMutationVariables
} from '../../generated/graphql';
import { FileUploader } from '../../components/common/FileUploader';

const GET_TAGS = loader('../../graphql/Tags/GetTags.graphql')
const GET_ADVERTISER_PROFILE = loader('../../graphql/Advertisers/GetMyAdvertiser.graphql')
const UPDATE_PROFILE = loader('../../graphql/Advertisers/UpdateAdvertiser.graphql')
const CHANGE_MY_PASSWORD = loader('../../graphql/Users/ChangeMyPassword.graphql')

export function AdvertiserProfile() {
    const history = useHistory();
    const { addError } = useError()

    const [changePasswordError, setChangePasswordError] = useState<{ "type": string, "message": string }>();

    const { data: advertiserData, loading: advertiserDataLoading, error: advertiserDataError } = useQuery<GetMyAdvertiserQuery, GetMyAdvertiserQueryVariables>(GET_ADVERTISER_PROFILE);
    const { data: tagsData } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS, {
        variables: {
            tagType: "advertiser"
        }
    });
    const [updateProfile, { data: updateProfileResponse, loading: updateProfileLoading }] = useMutation<UpdateAdvertiserMutation, UpdateAdvertiserMutationVariables>(UPDATE_PROFILE,
        {
            refetchQueries: [GET_ADVERTISER_PROFILE],
            awaitRefetchQueries: true
        }
    );
    const [changeMyPassword, { loading: changePasswordLoading }] = useMutation<ChangeMyPasswordMutation, ChangeMyPasswordMutationVariables>(CHANGE_MY_PASSWORD);

    const { handleSubmit, control, setError, reset } = useForm({ shouldUnregister: false })
    const { handleSubmit: handleChangeSubmit, control: handleChangeControl, setError: setChangeError, reset: resetChange } = useForm({
        shouldUnregister: false,
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: ""
        }
    })

    const [uploadFile, setUploadFile] = useState<File>();

    useEffect(() => {
        if (advertiserDataError) {
            addError("error", "Error fetching data: " + advertiserDataError.message);
            return;
        }
    }, [advertiserDataError, addError])

    const submitProfile = async (data: any) => {
        let builtTags: Array<{ tagId: number }> = [];

        if (data.tag) {
            data.tag.forEach((value: boolean, index: number) => {
                if (value) {
                    builtTags.push({ tagId: index })
                }
            })
        }

        updateProfile({
            variables: {
                advertiserData: {
                    name: data.name,
                    contactEmail: data.contactEmail,
                    contactPhone: data.contactPhone
                },
                logo: uploadFile,
                userData: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    email: data.email,
                },
                tags: builtTags
            },
            refetchQueries: [GET_ADVERTISER_PROFILE]
        })
    }

    const submitPasswordChange = (data: any) => {
        if (!data.currentPassword || !data.newPassword || !data.newPasswordConfirm || data.newPassword !== data.newPasswordConfirm) {
            setChangePasswordError(value => { return { type: "error", message: "Please be sure to complete all fields." } });
            return;
        }

        changeMyPassword({
            variables: {
                password: data.currentPassword,
                newPassword: data.newPassword
            }
        }).then(resp => {
            if (resp.data?.changeMyPassword === true) {
                setChangePasswordError(value => { return { type: "success", message: "Password updated successfully" } });
                resetChange();
                return;
            }
            setChangePasswordError(value => { return { type: "error", message: "Error updating password" } });
            resetChange();
        }).catch(error => {
            setChangePasswordError(value => { return { type: "error", message: error.message } });
            resetChange();
        });
    }

    useEffect(() => {
        if (updateProfileResponse) {
            if (updateProfileResponse.updateAdvertiser.response === "success") {
                addError("success", "Successfully updated Profile");
                history.push('/admin/dashboard/');
                return
            }

            addError("error", "Unable to save Profile. Please check errors and try again.")
            if (updateProfileResponse.updateAdvertiser.errors) {
                updateProfileResponse.updateAdvertiser.errors.forEach((value: any, index: number) => {
                    setError(value.field, { message: value.message })
                })
            }
        }
    }, [updateProfileResponse, history, addError, setError])

    if (!advertiserDataLoading && !advertiserData) {
        return <>Error Loading Profile</>
    }

    return <>
        <Grid container>
            <Grid item xs={12}>
                <ContentHeader title="Update Profile" loading={advertiserDataLoading} />
            </Grid>
            {advertiserData &&
                <Paper className="form-paper">
                    <form onSubmit={handleSubmit(submitProfile)} name="UpdateProfile">
                        <h3>Profile</h3>
                        <Grid xs={12} container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={advertiserData?.getMyAdvertiser.name}
                                    render={({ field }) =>
                                        <TextField label="Organization Name" margin="dense" fullWidth required {...field} />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <h2>Logo</h2>
                                <FormControl style={{ marginBottom: "1em" }}>
                                    {advertiserData && advertiserData.getMyAdvertiser.logo &&
                                        <div className="profile-image">
                                            <img
                                                src={`${process.env.REACT_APP_S3_ENDPOINT}/advlogos/${advertiserData.getMyAdvertiser.logo}`}
                                                alt=""
                                                style={{ maxWidth: "100%", maxHeight: "350px" }}
                                            />
                                        </div>
                                    }
                                    <FileUploader
                                        accept={"image/png,image/jpeg,image/gif"}
                                        handleDrop={(files: FileList) => {
                                            setUploadFile(files[0])
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <h3>Account and Contact Details</h3>
                        <Grid xs={12} container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={advertiserData?.getMyAdvertiser.user?.firstName}
                                    render={({ field }) =>
                                        <TextField label="Contact First Name" margin="dense" fullWidth {...field} />
                                    }
                                />
                                <Controller
                                    name="lastName"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={advertiserData?.getMyAdvertiser.user?.lastName}
                                    render={({ field }) =>
                                        <TextField label="Contact Last Name" margin="dense" fullWidth {...field} />
                                    }
                                />
                                <Controller
                                    name="phone"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) =>
                                        <TextField label="Contact Phone" margin="dense" fullWidth {...field} />
                                    }
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={advertiserData?.getMyAdvertiser.user?.userEmail}
                                    render={({ field }) =>
                                        <TextField label="Contact Email" margin="dense" fullWidth {...field} />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="contactEmail"
                                    defaultValue={advertiserData?.getMyAdvertiser.contactEmail}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            label="Public Contact Email"
                                            inputMode="email"
                                            fullWidth
                                            {...field}
                                        />
                                    }
                                />
                                <Controller
                                    name="contactPhone"
                                    defaultValue={advertiserData?.getMyAdvertiser.contactPhone}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            label="Public Contact Phone"
                                            inputMode="tel"
                                            fullWidth
                                            {...field}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                        <div style={{ display: "none" }}>
                            <h3>Tags</h3>
                            <Grid item xs={12} container spacing={4}>
                                <Grid item xs={12}>
                                    {tagsData?.getTags.map((tag: any) => {
                                        let searchMyData: any;
                                        if (advertiserData?.getMyAdvertiser.tags) {
                                            searchMyData = advertiserData?.getMyAdvertiser.tags.filter((value: any, index: any) => {
                                                return (value.id === tag.id)
                                            })
                                        }

                                        return <FormControl>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name={`tag[${tag.id}]`}
                                                        control={control}
                                                        defaultValue={searchMyData && searchMyData.length > 0}
                                                        render={({ field: props }) =>
                                                            <Checkbox
                                                                {...props}
                                                                checked={props.value}
                                                                onChange={e => props.onChange(e.target.checked)}
                                                            />
                                                        }
                                                    />
                                                }
                                                label={tag.name}
                                            />
                                        </FormControl>
                                    })}
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: "2rem" }}>
                            <Button disabled={updateProfileLoading} type="submit" color="primary" variant="contained"
                            >{updateProfileLoading && <CircularProgress size={11} style={{ marginRight: "11px" }} />}Save Profile</Button>
                        </div>
                    </form>
                    <div style={{ marginTop: "4em" }}>
                        <form onSubmit={handleChangeSubmit(submitPasswordChange)} name="UpdatePassword">
                            <h3>Security</h3>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <p>You can change your password using the fields below.</p>
                                    {
                                        changePasswordError ?
                                            (changePasswordError.type === "error" ?
                                                <div style={{ border: "2px solid red", borderRadius: "3px", fontSize: "1.125em", padding: "5px", backgroundColor: "#ffeeee;" }}>
                                                    {changePasswordError.message}
                                                </div>
                                                :
                                                <div style={{ border: "2px solid green", borderRadius: "3px", fontSize: "1.125em", padding: "5px", backgroundColor: "#eeffee;" }}>
                                                    {changePasswordError.message}
                                                </div>
                                            )
                                            :
                                            ""
                                    }
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Controller
                                        name="currentPassword"
                                        defaultValue={""}
                                        control={handleChangeControl}
                                        render={({ field }) =>
                                            <TextField
                                                label="Current Password"
                                                fullWidth
                                                type="password"
                                                {...field}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Controller
                                        name="newPassword"
                                        defaultValue={""}
                                        control={handleChangeControl}
                                        render={({ field }) =>
                                            <TextField
                                                label="New Password"
                                                fullWidth
                                                type="password"
                                                {...field}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Controller
                                        name="newPasswordConfirm"
                                        defaultValue={""}
                                        control={handleChangeControl}
                                        render={({ field }) =>
                                            <TextField
                                                label="Confirm New Password"
                                                fullWidth
                                                type="password"
                                                {...field}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit" disabled={changePasswordLoading}>
                                        {changePasswordLoading && <CircularProgress size={11} style={{ marginRight: "11px" }} />}Change Password
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Paper>
            }
        </Grid>
    </>
}
