import React, { MouseEventHandler } from 'react'
import { CheckCircle, Error, Info } from '@material-ui/icons'

import { withStyles } from "@material-ui/styles";
import { Box, Button, TextField, Tooltip, Typography } from "@material-ui/core";
import styled from "styled-components";

export { Tab, Tabs, TabPanel } from './TopTabs'

export const StyledTable = styled.table`  
  width:100%;
  color:white;
  fontSize:11px;
  border-spacing:0;
  border-collapse:collapse;

  td, th {
    padding:5px;
  }

  thead {
    th {
      position:sticky;
      top:0;
      background: #313131;
      color: white;
      border-top:1px solid #717171;
      border-bottom:#717171;
    }
  }
  tbody {
    td {
      color: white;
      border-bottom-color:#717171;
    }
  }
`;

export const LoginTextField = withStyles({
  root: {
    marginBottom: "10px",
    width: "100%",

    // "& label": {
    //   // color: "#2E2E2E",
    //   color: "#AEAEAE",
    // },
    // "& label.Mui-focused": {
    //   color: "#AEAEAE",
    // },
    // "& .MuiInput-underline:after": {
    //   borderBottom: "1px solid #AEAEAE",
    // },
    // "& .MuiInput-underline:before": {
    //   borderBottom: "1px solid #2E2E2E",
    // },
  },
})(TextField);

//background-image: url('${(props: {background:string}) => props.background}');
export const LoginBoxContainer = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
`;

//background-image: url('${(props: {background:string}) => props.background}');
export const ContentContainer = styled.div`
  background-size: cover;
  background-attachment:fixed;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding:120px 0;
`;

export const ContentBox = styled.div`
  padding:2em 1em 3em;
  width: 85%;
  max-width:1400px;
  background: radial-gradient(
    206.43% 106.83% at 0% 0%,
    #363636 0%,
    #000000 100%
  );
  border: 0.5px solid #00df9a;
  box-sizing: border-box;
  border-radius: 15px;
  display:flex;
  flex-direction:column;
`;

export const ContentColumns = styled.div`
    display:flex;
    flex-direction:row;
    padding:0 2em;
`;

export const ContentHeader = withStyles(() => ({
  root: {
    textAlign: "center",
    width: "100%",

    "& h1": {
      color: '#00df9a',
      fontSize: "22px",
      fontWeight: 800,
      textTransform: "uppercase"
    },
    "& h2": {
      fontSize: "2.85rem",
      fontWeight: 800,
      color: "white",
    }
  }
}))(Box)

export const ContentSidebar = styled.div`
  position:sticky;
  top:0;
  width:350px;

  ul {
    list-style-type:none;

    li {
      padding:0 0 15px;
      
      a {
        color:white;
        text-decoration:none;
        font-weight:700;
        font-size:18px;
      }

      ul {
        margin-top:0.5rem;
      }
    }
  }
`;

export const ContentBody = withStyles((hasSidebar: boolean) => ({
  root: {
    flexGrow: 1,
    color: "white",
    fontSize: "1.25rem",
    lineHeight: "1.5em",
    padding: "0 2em",
    "& h4, & h5": {
      color: "#00c588",
      borderBottom: "1px solid #00c588",
      margin: "0 0 0.5rem",
      fontWeight: "800",
    },
    "& p": {
      margin: "1em 0",
      fontSize: "18px",
    },
    "& p:first-of-type": {
      marginTop: 0,
    },
    "& p:last-child": {
      marginBottom: 0
    },
    "& > div": {
      marginBottom: "2rem"
    },
    "& div ul": {
      listStyleType: "none",
      margin: "0"
    },
    "&.hasSidebar": {
      width: "100%"
    }
  }
}))(Box)

export const WelcomeText = styled.div`
  font-family: Montserrat,sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0;
  color: #093444;
  margin:10px 0 30px;
  font-size: calc(2.8 * 1rem);
`;

export const ArrowSubmit = styled.button`
    border:none;
    background:none;
    cursor:pointer;

    & span {
      display:none;
    }
`;

export const SignupLabel = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-size: 20px;
  letter-spacing: -0.07em;
  color: #00df9a;
`;

export const CardBox = styled.div`
  background: #101015;
  border: 1px solid #202030;
  border-radius: 10px;
  padding: 10px;
  line-height: 1;
  margin-bottom: 15px;
  font-size: 12px;
  color: #828f91;
  position: relative;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
`;

export const CardContent = styled.div`
  padding: .5rem 0 0;
`

export const CardTitle = styled.h4`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  color: #fff;
`

export const CloseButton = styled.button`
  background: linear-gradient(90deg,#a90202 -6.25%,#ff710b 100%);
  position: relative;
  border: 0;
  font-size: 11px;
  line-height: 1;
  text-align: center;
  border-radius: 5px;
  padding: .4rem 1rem;
  width: 90%;
  color: #fff;
  display: inline-block;
  ::before {
    content: "";
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 5px;
  }
`

export const BlackAndWhiteButton = withStyles({
  root: {
    height: "24px",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 11,
    border: "1px solid",
    borderRadius: "5px",
    backgroundColor: "#000",
    borderColor: "#fff",
    color: "white",
    textDecoration: "underline",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
    "&:disabled": {
      backgroundColor: "#828f91",
      borderColor: "#242424",
    },
  },
})(Button);


export const CssTextField = withStyles({
  root: {
    "&:before": {
      border: "none",
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:hover:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
    background: "#292a33",
    borderRadius: "2px",
    padding: "0px 4px",
    margin: "2px 0px",
    border: "none",
    "& label.Mui-focused": {
      color: "green",
    }
  },
})(TextField);


export const ClosePositionButton = withStyles({
  root: {
    "&:hover:before": {
      opacity: 0,
    },
    "&:before": {
      content: '""',
      background: "#000",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "1px",
      width: "calc(100% - 2px)",
      height: "calc(100% - 2px)",
      borderRadius: "5px",
    },
    height: "24px",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 11,
    borderRadius: "5px",
    background: "linear-gradient(90deg,#a90202 -6.25%,#ff710b 100%)",
    color: "white",
    textDecoration: "underline",
    "&:disabled": {
      backgroundColor: "#828f91",
      borderColor: "#242424",
    },
  },
})(Button);

export const InputTextField = withStyles(() => ({
  root: {
    width: "100%",
    margin: "0 0 1.5rem",

    '& label': {
      color: "white",
    },
    '& label.Mui-focused': {
      color: "rgb(0, 197, 136)",
    },
    '& .MuiInputBase-input': {
      color: "white"
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgb(0, 197, 136)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgb(0, 197, 136)',
      },
      '&:hover fieldset': {
        borderColor: '#aeaeae',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#aeaeae',
      },
    },
  }
}))(TextField)

const AlertBarText = withStyles(() => ({
  root: {
    position: "absolute",
    marginTop: "1em",
    left: "1em",
    right: "1em",
    zIndex: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    color: "white",
    fontSize: "0.75rem",
    lineHeight: "1.95em",
    border: "1px solid transparent",
    borderRadius: "4px",
    padding: "0.5em 1em 0.5em 0.6875em",
    boxShadow: "2px 2px 3px 0px rgba(0,0,0,0.25)",
    "&.error": {
      borderColor: '#f44336;',
      backgroundColor: '#f44336;'
    },
    "&.warning": {
      borderColor: '#ff9800',
      backgroundColor: '#ff9800'
    },
    "&.info": {
      borderColor: '#2196f3',
      backgroundColor: '#2196f3'
    },
    "&.success": {
      borderColor: '#4caf50',
      backgroundColor: '#4caf50'
    },
    "&.none": {
      borderColor: "white",
      backgroundColor: "white",
      color: "black"
    },
    "&.relative": {
      position: "relative",
      left: "auto",
      right: "auto",
      marginBottom: "2em",
      width: "100%",
    }
  }
}))(Box)

type AlertBarProps = {
  type?: "error" | "warning" | "info" | "success" | "none",
  title?: string,
  message?: string,
  showLoader?: boolean,
  buttonText?: string | boolean,
  buttonLink?: string,
  positionRelative?: boolean,
  onClick?: MouseEventHandler
}

export function AlertBar({
  type = "error",
  message = "We apologize, but an error has been encountered. Please try again.",
  showLoader = false,
  buttonText = false,
  buttonLink = "",
  positionRelative = true,
  onClick = () => { }
}: AlertBarProps) {
  return (
    <AlertBarText className={type + (positionRelative ? " relative" : "absolute")}>
      <div>{
        (type === "success" ?
          <CheckCircle style={{ verticalAlign: "middle" }} />
          :
          (
            type === "error" ?
              <Error style={{ verticalAlign: "middle" }} />
              :
              <Info style={{ verticalAlign: "middle" }} />
          )
        )
      }</div>
      <div style={{ marginLeft: "10px", flexGrow: 1 }}>{message}</div>
      {
        buttonText && <Typography align="center">
          {
            buttonLink ?
              <Button
                href={buttonLink}
                size="small"
                color="default"
                variant="contained"
              >{buttonText}</Button>
              :
              <Button
                onClick={onClick}
                size="small"
                color="default"
                variant="contained"
              >{buttonText}</Button>
          }
        </Typography>
      }
    </AlertBarText>
  )
}

export const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "white",
    color: "rgb(0, 0, 0)",
    boxShadow: "rgba(255,255,255,0.5)",
    border: "1px solid rgb(0, 197, 136)",
    borderRadius: 5,
    fontSize: 13,
  },
}))(Tooltip);

export const LoginBoxFooter = withStyles(() => ({
  root: {
    display: "grid",
    justifyContent: "center",
    width: "100%",
    gridAutoFlow: "column",
    alignItems: "center",
    alignSelf: "center",
    marginTop: "3em"
  }
}))(Box)