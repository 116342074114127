import React from 'react';
import {
    CircularProgress
} from '@material-ui/core'

export default function FormLoadingOverlay(props: {show: boolean, size?: number, background?: boolean}) {
    const useSize = (props.size ? props.size : 30)
    const useClass = "form-overlay" + (props.background === undefined || props.background === true ? "" : " no-background");
    return (props.show ? 
        <div className={useClass}>
            <div className="loader"><CircularProgress size={useSize} /></div>
        </div>
        :
        <></>
    )
}