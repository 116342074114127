import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    IconButton,
    MenuItem,
    Paper,
    Select,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    TablePagination,

} from '@material-ui/core'
import { BarChartTwoTone, Delete, Edit } from '@material-ui/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { loader } from "graphql.macro";
import { useQuery, useMutation } from '@apollo/client';
import { ContentHeader } from '../../components/common/ContentHeader'
import { useError } from '../../hooks/useError';

import { UserStatus } from '../../graphql/Fragments/User.enum';

import {
    GetNfpsQuery, GetNfpsQueryVariables,
    UpdateNotForProfitStatusMutation, UpdateNotForProfitStatusMutationVariables,
    DeleteNotForProfitMutation, DeleteNotForProfitMutationVariables,
} from '../../generated/graphql'
import FormLoadingOverlay from '../../components/common/FormLoadingOverlay';

const GET_NFP_QUERY = loader('../../graphql/NotForProfits/GetNotForProfits.graphql')
const UPDATE_NFP_STATUS = loader('../../graphql/NotForProfits/UpdateStatus.graphql')
const DELETE_NFP_MUTATION = loader('../../graphql/NotForProfits/DeleteNotForProfit.graphql')

export function ManageNotForProfits() {
    document.title = "Manage Not For Profits - ManySeeds";

    const history = useHistory()
    const { addError } = useError()

    const { data: nfpData, loading: nfpDataLoading, error: nfpDataError, refetch: refetchNfpData } = useQuery<GetNfpsQuery, GetNfpsQueryVariables>(GET_NFP_QUERY);
    const [updateStatus] = useMutation<UpdateNotForProfitStatusMutation, UpdateNotForProfitStatusMutationVariables>(UPDATE_NFP_STATUS);
    const [deleteNfp, { data: deleteNfpResponse, loading: deleteNfpLoading }] = useMutation<DeleteNotForProfitMutation, DeleteNotForProfitMutationVariables>(DELETE_NFP_MUTATION);

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [updatingThisNfp, setUpdatingThisNfp] = useState<number>(0);
    const [deleteThisNfp, setDeleteThisNfp] = useState<any>()

    const [tablePage, setTablePage] = useState<number>(0);
    const [tableRowsPerPage, setRowsPerPage] = useState<number>(10);

    useEffect(() => {
        //Refetch on component re-mounting
        if (nfpData && !nfpDataLoading) {
            console.log('Refreshing');
            refetchNfpData()
        }
    }, [])

    const handleUpdateStatus = (nfp: any, newStatus: UserStatus) => {
        setUpdatingThisNfp(nfp.id)

        updateStatus({
            variables: {
                nfpId: nfp.id,
                status: newStatus
            },
            refetchQueries: [GET_NFP_QUERY]
        }).catch(error => {
            addError("error", "Error updating Not For Profit: " + error.message)
        }).then(() => {
            addError("success", "Successfully updated Not For Profit")
            setUpdatingThisNfp(0)
        })
    }

    const handleOpenDeleteNfp = (nfp?: any) => {
        setDeleteThisNfp(nfp)
        setShowDeleteDialog(true)
    }

    const handleDeleteNfp = async () => {
        console.log('Trigger Delete')
        if (!deleteThisNfp) {
            addError("error", "An error was encountered while trying to delete the selected item.")
        }

        deleteNfp({
            variables: {
                nfpId: deleteThisNfp.id
            },
            refetchQueries: [GET_NFP_QUERY]
        })
        setDeleteThisNfp(undefined)
    }

    useEffect(() => {
        if (deleteNfpResponse) {
            if (deleteNfpResponse.deleteNfp) {
                addError('success', "Successfully deleted NFP")
                refetchNfpData()
                return
            }
            addError('error', "Error encountered while trying to delete the selected item.")
        }
    }, [deleteNfpResponse, addError, refetchNfpData])

    const deleteUserText = useMemo(() => {
        if (deleteThisNfp) {
            return <DialogContentText color="primary">
                Are you sure you want to delete {deleteThisNfp.name} ? <strong>This action cannot be reverted</strong>
            </DialogContentText>
        }
    }, [deleteThisNfp])

    useEffect(() => {
        if (nfpDataError) {
            addError("error", "Error fetching data: " + nfpDataError.message)
        }
    }, [nfpDataError, addError])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setTablePage(newPage);
    };

    return (
        <React.Fragment>
            <ContentHeader
                title="Not For Profits"
                loading={nfpDataLoading}
                options={[
                    { text: "Refresh Data", action: (e: any) => { refetchNfpData() }, devOnly: true },
                    { text: "Create Not For Profit", action: (e: any) => { history.push('/admin/not-for-profits/add'); } }
                ]}
            />
            <TableContainer component={Paper}>
                <Table
                    style={{ width: '100%' }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Contact</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell width="220px">Status</TableCell>
                            <TableCell width="200px" align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nfpDataLoading && !nfpData &&
                            <TableRow>
                                <TableCell colSpan={5} align="center">Fetching Data...</TableCell>
                            </TableRow>
                        }
                        {!nfpDataLoading && nfpData && nfpData.getNfps.length <= 0 &&
                            <TableRow>
                                <TableCell colSpan={5} align="center">No Records to Display</TableCell>
                            </TableRow>
                        }
                        {nfpData && nfpData.getNfps
                            .slice(tablePage * tableRowsPerPage, tablePage * tableRowsPerPage + tableRowsPerPage)
                            .map((nfp: any) => {
                                return (<TableRow key={nfp.id}>
                                    <TableCell>{nfp.name}</TableCell>
                                    <TableCell>{nfp.user.firstName + " " + nfp.user.lastName}</TableCell>
                                    <TableCell>{nfp.user.userEmail}</TableCell>
                                    <TableCell>{new Date(nfp.createdAt).toDateString()}</TableCell>
                                    <TableCell style={{ textTransform: "capitalize", position: "relative" }}>
                                        <FormLoadingOverlay show={(updatingThisNfp === nfp.id)} background={false} size={25} />
                                        <Select
                                            fullWidth
                                            value={nfp.status}
                                            onChange={e => {
                                                const newStatus = e.target.value as UserStatus;
                                                handleUpdateStatus(nfp, newStatus)
                                            }}
                                        >
                                            <MenuItem value={UserStatus.ACTIVE}>Active</MenuItem>
                                            <MenuItem value={UserStatus.INACTIVE}>Inactive</MenuItem>
                                            <MenuItem value={UserStatus.SUSPENDED}>Suspended</MenuItem>
                                            <MenuItem value={UserStatus.BANNED}>Banned</MenuItem>
                                            <MenuItem value={UserStatus.AWAITING_APPROVAL} disabled={true}>Awaiting Approval</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormLoadingOverlay show={(deleteNfpLoading && deleteThisNfp && deleteThisNfp.id === nfp.id)} />
                                        <IconButton
                                            onClick={e => {
                                                history.push('/admin/reports/?notforprofit=' + nfp.id)
                                            }}
                                        >
                                            <Tooltip title="Chartity Stats Performance">
                                                <BarChartTwoTone />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton onClick={e => {
                                            e.preventDefault()
                                            history.push('/admin/not-for-profits/edit/' + nfp.id)
                                        }}>
                                            <Tooltip title="Edit">
                                                <Edit />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton onClick={e =>
                                            handleOpenDeleteNfp(nfp)
                                        }>
                                            <Tooltip title="Delete">
                                                <Delete />
                                            </Tooltip>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component={Paper}
                count={(nfpData?.getNfps ? nfpData.getNfps.length : 0)}
                rowsPerPage={tableRowsPerPage}
                page={tablePage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={showDeleteDialog} title={"Delete Not For Profit"}>
                <DialogTitle>Confirm Not For Profit Deletion</DialogTitle>
                <DialogContent>
                    {deleteUserText}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary"
                        onClick={e => {
                            setShowDeleteDialog(false)
                            setDeleteThisNfp(null)
                        }}
                    >Cancel</Button>
                    <Button color="primary" variant="contained"
                        onClick={e => {
                            setShowDeleteDialog(false)
                            addError('info', "Deleting Not For Profit...")
                            handleDeleteNfp()
                        }}
                    >Delete</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
