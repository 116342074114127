import React, { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from "@apollo/client";
import logo from "../assets/manyseeds-logo.png";
import { Button, CircularProgress, Grid, Link, TextField, Typography } from "@material-ui/core";
import { LoginBoxContainer, LoginBoxFooter, WelcomeText } from "../components/common";
import { useError } from "../hooks/useError";
import { loader } from "graphql.macro";

import { RegisterAccountMutation, RegisterAccountMutationVariables } from '../generated/graphql'
import { Link as RouterLink, useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
const REGISTER_ACCOUNT_MUTATION = loader('../graphql/Users/RegisterAccount.graphql')

export function Register() {
    const history = useHistory();
    const { addError } = useError()
    const { register, control, formState: { errors }, getValues, handleSubmit, reset } = useForm({
        defaultValues: {
            organization: "",
            email: "",
            charitableNumber: "",
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
        }
    })
    const [remoteErrors, setRemoteErrors] = useState<{ [key: string]: string }>({});
    const [type, selectType] = useState<"nfp" | "advertiser">()

    const [registerAccount, { data: registerData, loading: registerLoading, error: registerError }] = useMutation<RegisterAccountMutation, RegisterAccountMutationVariables>(REGISTER_ACCOUNT_MUTATION);


    const submitData = (data: any) => {
        if (!type) {
            return;
        }

        // creating
        try {
            registerAccount({
                variables: {
                    email: data.email,
                    organization: data.organization,
                    charitableNumber: (data.charitableNumber ? data.charitableNumber : ""),
                    firstName: data.firstName,
                    lastName: data.lastName,
                    password: data.password,
                    userRole: type
                }
            })
        } catch (error) {
            addError("error", "Sorry, but an error was encountered")
        }
    }

    useEffect(() => {
        if (registerData) {
            if (registerData.registerAccount.response === "success") {
                setRemoteErrors({});
                reset(undefined, { keepValues: false })
                //addError("success", "Your account has been created. You will be notified once an Administrator has approved your account.")
                addError("success", "Your account has been created. You may login and setup your profile, but it will not be visible on the App. You will be notified once an Administrator has approved your account.");
                history.push('/admin/login')
                return;
            } else {
                registerData.registerAccount.errors?.forEach(item => {
                    setRemoteErrors(re => {
                        re[item.field] = item.message;
                        return { ...re }
                    })
                });
            }

            addError("error", "Unable to register: " + registerData.registerAccount.message)
            reset()
        }
    }, [registerLoading, registerError, registerData, addError, reset])

    return (
        <form name="RegisterForm" onSubmit={handleSubmit(submitData)}>
            <LoginBoxContainer>
                <div className="login-box">
                    <WelcomeText>Register</WelcomeText>
                    <Typography align="center" style={{ marginBottom: "1em", fontSize: "calc(1.1 * 1rem)", color: "#01c38a", fontWeight: "bold" }}>Thanks for getting started with Many Seeds!</Typography>
                    {(!type || !["advertiser", "nfp"].includes(type)) &&
                        <>
                            <Typography align="center" style={{ margin: "0 0 1em", fontSize: "calc(1.1 * 1rem)", color: "#01c38a", fontWeight: "bold" }}>Please select your type of organization:</Typography>
                            <Grid container>
                                <Grid item xs={12} style={{ textAlign: "center", marginTop: "2em" }}>
                                    <Button variant="contained" className="register-button nfp" onClick={e => selectType('nfp')}>Not For Profit</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={{ textAlign: "center", position: "relative" }}>
                                        <span style={{ display: "inline-block", background: "black", verticalAlign: "middle", height: "1px", width: "20px", margin: "0 5px" }}></span> OR <span style={{ display: "inline-block", background: "black", verticalAlign: "middle", height: "1px", width: "20px", margin: "0 5px" }}></span>
                                    </p>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Button variant="contained" className="register-button asAdvert" onClick={e => selectType('advertiser')}>Advertiser</Button>
                                </Grid>
                            </Grid>
                        </>
                    }
                    {type &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <label htmlFor="organization">Organization Name</label>
                                <input type="text" {...register("organization", { required: true, minLength: { value: 3, message: "You must specific a valid organization name" } })} />
                                {errors?.organization && <p className="error">A valid organization name is required.</p>}
                                {remoteErrors.organization && <p className="error">{remoteErrors.organization}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <label htmlFor="email">Email</label>
                                <input type="text" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                                {errors?.email && <p className="error">A valid email address is required.</p>}
                                {remoteErrors.email && <p className="error">{remoteErrors.email}</p>}
                            </Grid>
                            {type === "nfp" &&
                                <Grid item xs={12}>
                                    <label htmlFor="charitableNumber">Registered Not-for-Profit #</label>
                                    <input type="text" {...register("charitableNumber")} />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <fieldset>
                                    <legend>Name</legend>
                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1em" }}>
                                        <div style={{ width: "49%" }}>
                                            <input type="text" {...register("firstName", { required: true })} />
                                            <label htmlFor="firstName">First Name</label>
                                            {errors?.firstName && <p className="error">A valid first name is required.</p>}
                                            {remoteErrors.firstName && <p className="error">{remoteErrors.firstName}</p>}
                                        </div>
                                        <div style={{ width: "49%" }}>
                                            <input type="text" {...register("lastName", { required: true })} />
                                            <label htmlFor="lastName">Last Name</label>
                                            {errors?.lastName && <p className="error">A valid last  name is required.</p>}
                                            {remoteErrors.lastName && <p className="error">{remoteErrors.lastName}</p>}
                                        </div>
                                    </div>
                                </fieldset>
                            </Grid>
                            <Grid item xs={12}>
                                <fieldset>
                                    <legend>Password</legend>
                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1em" }}>
                                        <div style={{ width: "49%" }}>
                                            <input type="password" {...register("password", { required: true })} />
                                            <label htmlFor="password">Password</label>
                                            {errors?.password && <p className="error">You must choose a password to register and create your account.</p>}
                                            {remoteErrors.password && <p className="error">{remoteErrors.password}</p>}
                                        </div>
                                        <div style={{ width: "49%" }}>
                                            <input type="password" {...register("confirmPassword", { required: true, validate: value => value === getValues("password") })} />
                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                            {errors?.confirmPassword && <p className="error">The "Password" and "Confirm Password" do not match.</p>}
                                            {remoteErrors.confirmPassword && <p className="error">{remoteErrors.confirmPassword}</p>}
                                        </div>
                                    </div>
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Button disabled={registerLoading} variant="contained" color="primary" type="submit"
                                >{registerLoading && <CircularProgress size={12} style={{ marginRight: "10px" }} />}Submit</Button>
                            </Grid>
                        </Grid>
                    }
                    <LoginBoxFooter>
                        <Link
                            component={RouterLink}
                            to="/admin/login/"
                            style={{ color: "#AEAEAE" }}
                        ><ArrowBack fontSize="small" style={{ verticalAlign: "middle" }} /> Return to Login</Link>
                    </LoginBoxFooter>
                </div>
            </LoginBoxContainer>
        </form >
    )
}
