import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CheckBox, CheckBoxOutlineBlank, ExpandMore } from '@material-ui/icons';
import { ContentHeader } from '../../components/common/ContentHeader'
import { Autocomplete, AutocompleteRenderOptionState } from '@material-ui/lab';
import { ExportCSV, ExportXLSX } from '../../utils/exporter';

import {
    GetFiltersQuery, GetFiltersQueryVariables,
    GetAllTransactionsQuery, GetAllTransactionsQueryVariables,
    FilterGroup
} from '../../generated/graphql';

const GET_FILTERS = loader('../../graphql/Reporting/GetFilters.graphql')
const GET_ALL_TRANSACTIONS_QUERY = loader('../../graphql/Advertisers/GetAllTransactions.graphql')

export function TransactionHistory() {
    document.title = "Transaction History - ManySeeds"

    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    const [showFilterAccordion, setShowFilterAccordion] = useState<boolean>(false);

    const [showExportDialog, setShowExportDialog] = useState<boolean>(false)
    const [exportFormat, setExportFormat] = useState<string>("excel");
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const baseDate = new Date();
    const [dateRangeStart, setDateRangeStart] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth(), 1));
    const [dateRangeEnd, setDateRangeEnd] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 0));

    const [searchFilter, setSearchFilter] = useState<{
        dateRangeStart?: Date,
        dateRangeEnd?: Date,
        advertisers?: number[],
    }>({ dateRangeStart, dateRangeEnd });

    const { data: filterData, refetch: refetchFilters } = useQuery<GetFiltersQuery, GetFiltersQueryVariables>(GET_FILTERS);
    const { data: transactionsData, loading: transactionsLoading, error: transactionsError, refetch: refetchTransactions } = useQuery<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>(GET_ALL_TRANSACTIONS_QUERY, {
        variables: {
            dateRangeStart, dateRangeEnd
        }
    })

    const handleDateChange = (source: "start" | "end", newDate: any) => {
        switch (source) {
            case "start":
                setSearchFilter(filters => ({ ...filters, dateRangeStart: newDate }));
                setDateRangeStart(newDate)
                break;
            case "end":
                setSearchFilter(filters => ({ ...filters, dateRangeEnd: newDate }));
                setDateRangeEnd(newDate)
                break;
        }
    }

    useEffect(() => {
        if (searchFilter) {
            //console.log('searchFilter', searchFilter);
        }
    }, [searchFilter])

    const refetchData = () => {
        refetchTransactions();
    }

    const handleSubmit = () => {
        refetchTransactions({ ...searchFilter })
    }

    const exportData = () => {
        if (!transactionsData || !["csv", "excel"].includes(exportFormat)) {
            return;
        }

        setExportLoading(true);

        const headersTop = ["Advertiser", "Type", "Amount", "Notes", "Process Date"];
        const headersBottom = ["Totals", "", 0.00,];
        const rowData = transactionsData.getAllTransactions.map((transx: any) => {
            return [
                transx.advertiser.name,
                transx.transactionType.toLocaleUpperCase(),
                "$" + transx.amount.toFixed(2),
                transx.note,
                new Date(transx.createdAt).toDateString(),
            ];
        });

        if (exportFormat === "csv") {
            ExportCSV(
                "transaction_report_" + new Date().toDateString().replaceAll(' ', '-') + ".csv",
                { headersTop, headersBottom, rowData }
            );
        } else if (exportFormat === "excel") {
            ExportXLSX(
                "transaction_report_" + new Date().toDateString().replaceAll(' ', '-') + ".xlsx",
                { headersTop, headersBottom, rowData }
            );
        }
        setExportLoading(false);
        setShowExportDialog(false);
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ContentHeader
                    title="Transaction History"
                    loading={transactionsLoading}
                    options={[
                        { text: "Refresh Filters", action: (e: any) => { refetchFilters() }, devOnly: true },
                        { text: "Refresh Data", action: (e: any) => { refetchData() }, devOnly: true },
                        { text: "Export Data", action: (e: any) => { setShowExportDialog(true) } },
                    ]}
                />
            </Grid>
            <Grid item xs={12}>
                <Accordion expanded={showFilterAccordion} onChange={e => setShowFilterAccordion(sfa => !sfa)}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography style={{ fontSize: 20, fontWeight: "bold" }}>Filter</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container style={{ marginTop: "2em" }} spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DatePicker
                                        disableToolbar
                                        variant="inline"
                                        label="Range Start"
                                        value={dateRangeStart}
                                        style={{ width: "100%" }}
                                        onChange={e => handleDateChange('start', e)}
                                    />&nbsp;
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DatePicker
                                        disableToolbar
                                        variant="inline"
                                        label="Range End"
                                        value={dateRangeEnd}
                                        style={{ width: "100%" }}
                                        onChange={e => handleDateChange('end', e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    {filterData?.getFilters.advertisers &&
                                        <Autocomplete
                                            multiple
                                            options={filterData.getFilters.advertisers}
                                            disableCloseOnSelect
                                            getOptionLabel={option => option.name}
                                            onChange={(e, options) => setSearchFilter(filters => ({ ...filters, advertisers: [...options.map(item => item.id)] }))}
                                            renderOption={(option: FilterGroup, { selected }: AutocompleteRenderOptionState) => (
                                                <>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                        key={option.id}
                                                    />
                                                    {option.name}
                                                </>
                                            )}
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Advertiser(s)" />
                                            )}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} style={{ alignSelf: "center" }}>
                                    <Button variant="contained" color="primary" onClick={e => handleSubmit()}>Submit Filters</Button>
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Advertiser</TableCell>
                                <TableCell width={20}>Type</TableCell>
                                <TableCell align="right">Amount</TableCell>
                                <TableCell>Notes</TableCell>
                                <TableCell>Process Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactionsData && transactionsData.getAllTransactions.length > 0 ?
                                transactionsData.getAllTransactions.map((transx: any) => {
                                    return <TableRow key={transx.id}>
                                        <TableCell>{transx.advertiser.name}</TableCell>
                                        <TableCell>{transx.transactionType.toLocaleUpperCase()}</TableCell>
                                        <TableCell align="right" style={{
                                            color:
                                                (transx.transactionType === "deposit" ? "green" : "red")
                                        }}>
                                            ${transx.amount.toFixed(2)}
                                        </TableCell>
                                        <TableCell>{transx.note}</TableCell>
                                        <TableCell>{new Date(transx.createdAt).toDateString()}</TableCell>
                                    </TableRow>
                                })
                                :
                                !transactionsLoading &&
                                <TableRow>
                                    <TableCell colSpan={4}>No transactions to display</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
        <Dialog open={showExportDialog} title="Export Format" onClose={() => setShowExportDialog(show => !show)} maxWidth="sm">
            <DialogTitle>Export Data</DialogTitle>
            <DialogContent>
                <TextField
                    select
                    disabled={exportLoading}
                    label="Export Format"
                    type="number"
                    value={exportFormat}
                    onChange={e => {
                        setExportFormat(e.target.value);
                    }}
                    fullWidth
                >
                    <MenuItem
                        key="exportExcel"
                        value="excel"
                    >Excel (XLSX)</MenuItem>
                    <MenuItem
                        key="exportCsv"
                        value="csv"
                    >CSV</MenuItem>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button disabled={exportLoading} variant="outlined" color="secondary" onClick={e => {
                    exportData()
                }}>{exportLoading && <CircularProgress size={20} style={{ marginRight: "20px" }} />}Export</Button>
            </DialogActions>
        </Dialog>
    </>
}
