import React from "react";
import { NavLink } from "react-router-dom";
import { AccountCircle, AttachMoney, Dashboard, Comment, EmojiPeople } from "@material-ui/icons";

export function NotForProfitNavigation(props: {clickHandler: (e: any) => void}) {
    return <>
        <h2>General</h2>
        <ul>
            <li><NavLink to="/admin/dashboard" onClick={props.clickHandler}><Dashboard />Dashboard</NavLink></li>
            <li><NavLink to="/admin/profile" onClick={props.clickHandler}><AccountCircle />Profile</NavLink></li>
            <li><NavLink to="/admin/news" onClick={props.clickHandler}><Comment />News and Events</NavLink></li>
            <li><NavLink to="/admin/volunteers" onClick={props.clickHandler}><EmojiPeople />Volunteer Positions</NavLink></li>
        </ul>
        <h2>Reporting</h2>
        <ul>
            <li><NavLink to="/admin/reports" onClick={props.clickHandler}><AttachMoney />Reporting</NavLink></li>
        </ul>
    </>
}