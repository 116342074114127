import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

// const httpLink = new HttpLink({
//   uri: process.env.REACT_APP_ENDPOINT ? `https://${process.env.REACT_APP_ENDPOINT}/graphql` : "https://localhost:4000/graphql",
//   credentials: 'include'
// });

let useEndPoint: string | undefined;
switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'local':
    useEndPoint = "http://localhost:4001/graphql";
    break;
  case 'development':
    useEndPoint = "https://dev-many-seeds-server.pragmaflowservers.com/graphql";
    break;
  case 'test':
  case 'qa':
    useEndPoint = "https://qa-many-seeds-server.pragmaflowservers.com/graphql";
    break
  case 'production':
  default:
    useEndPoint = "https://app.many-seeds.com/graphql";
    break;
}
if(!useEndPoint) useEndPoint = "https://app.many-seeds.com/graphql";

const uploadLink = createUploadLink({
  uri: useEndPoint,
  credentials: 'include'
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: true,
  //link: httpLink
  link:(uploadLink as unknown) as ApolloLink,
  defaultOptions: {
    watchQuery:{
      notifyOnNetworkStatusChange:true
    }
  }
});

export default client
