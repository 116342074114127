import {
    Button,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    IconButton,
    InputAdornment,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    TablePagination,
} from '@material-ui/core'
import { Delete, Edit, Visibility, VisibilityOff } from '@material-ui/icons'
import React, { useEffect, useMemo, useState } from 'react'

import { loader } from "graphql.macro";
import { IFormFieldValidation, validateField, resetErrors, hasErrors } from '../../utils/validation'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { ContentHeader } from '../common/ContentHeader'
import { useError } from '../../hooks/useError';

import {
    GetUsersByRoleQuery, GetUsersByRoleQueryVariables,
    UpdateUserMutation, UpdateUserMutationVariables,
    DeleteUserMutation, DeleteUserMutationVariables,
    CreateUserMutation, CreateUserMutationVariables,
    GetUserNotificationsQuery, GetUserNotificationsQueryVariables
} from '../../generated/graphql'
import { useAdminAuth } from '../../hooks/useAdminAuth';

const GET_USERS_QUERY = loader('../../graphql/Users/GetUsersByRole.graphql')
const GET_USER_NOTIFICATIONS_QUERY = loader('../../graphql/Users/GetUserNotifications.graphql')
const CREATE_USER_MUTATION = loader('../../graphql/Users/CreateUser.graphql')
const UPDATE_USER_MUTATION = loader('../../graphql/Users/UpdateUser.graphql')
const DELETE_USER_MUTATION = loader('../../graphql/Users/DeleteUser.graphql')

export function ManageUsersTab(props: { userRole: string, label: string }) {
    const { me } = useAdminAuth()
    const { addError } = useError()
    const useThisRole: string = props.userRole;

    const [tablePage, setTablePage] = useState<number>(0);
    const [tableRowsPerPage, setRowsPerPage] = useState<number>(10);

    const [showPassword, setShowPassword] = useState(false);

    const [deleteUser, { data: deleteUserResponse, loading: deleteUserLoading }] = useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DELETE_USER_MUTATION);
    const [createUser, { data: createUserResponse, loading: createUserLoading }] = useMutation<CreateUserMutation, CreateUserMutationVariables>(CREATE_USER_MUTATION);
    const [updateUser, { data: updateUserResponse, loading: updateUserLoading }] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER_MUTATION);

    const { data: userData, loading: userDataLoading, refetch: userDataRefresh } = useQuery<GetUsersByRoleQuery, GetUsersByRoleQueryVariables>(GET_USERS_QUERY, {
        variables: {
            userRole: useThisRole
        }
    });

    const [getNotificationsQuery] = useLazyQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(GET_USER_NOTIFICATIONS_QUERY);

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [deleteThisUser, setDeleteThisUser] = useState<any>()

    const [showCreate, setShowCreate] = useState(false)
    const [addOrEditData, setAddOrEditData] = useState<{
        type: 'create' | 'update',
        id: number,
        email: string
        lastName: string
        firstName: string,
        password: string,
        userRole: string,
        sendEmail: boolean,
        generatePassword: boolean
    }>({
        type: 'create',
        id: 0,
        email: '',
        lastName: '',
        firstName: '',
        password: '',
        userRole: useThisRole,
        sendEmail: false,
        generatePassword: false
    })

    const [formFields, setFormFields] = useState<IFormFieldValidation>({
        email: {
            required: true,
            checks: [{ name: "Valid Email", type: "email" }]
        },
        firstName: {
            required: true,
            checks: [{ name: "First Name", type: "name" }]
        },
        lastName: {
            required: true,
            checks: [{ name: "Last Name", type: "name" }]
        },
        password: {
            required: false,
        }
    })

    //This will handle the form validation...
    const handleFieldUpdate = (fieldName: string, newValue: string | number) => {
        const newValidData = validateField(formFields, fieldName, newValue)
        setFormFields({ ...formFields, ...newValidData })
    }

    const addOrUpdateUsers = async () => {
        if (!addOrEditData.email || !addOrEditData.firstName || !addOrEditData.lastName) {
            return
        }
        if (hasErrors(formFields)) {
            return
        }

        if (addOrEditData.type === 'update' && addOrEditData.id > 0) {
            // updating

            if (!addOrEditData.id) {
                addError("error", "Error updating user");
                return
            }

            updateUser({
                variables: {
                    userId: addOrEditData.id,
                    email: addOrEditData.email,
                    firstName: addOrEditData.firstName,
                    lastName: addOrEditData.lastName,
                    password: addOrEditData.password,
                    userRole: useThisRole,
                    sendEmail: addOrEditData.sendEmail,
                    generatePassword: addOrEditData.generatePassword
                }
            })
        } else {
            // creating
            createUser({
                variables: {
                    email: addOrEditData.email,
                    firstName: addOrEditData.firstName,
                    lastName: addOrEditData.lastName,
                    password: addOrEditData.password,
                    userRole: useThisRole,
                    sendEmail: addOrEditData.sendEmail,
                    generatePassword: addOrEditData.generatePassword
                }
            })
        }

        // setAddOrEditData({
        //     type: 'create',
        //     id: 0,
        //     email: '',
        //     lastName: '',
        //     firstName: '',
        //     password: '',
        //     userRole:useThisRole,
        //     sendEmail: false
        // })
        // setShowCreate(false)
    }

    const handleClickOpen = (id: number, email: string, lastName: string, firstName: string) => {
        let type: 'create' | 'update' = 'create'
        if (id && id > 0) {
            type = 'update'

            getNotificationsQuery({
                variables: {
                    userId: id
                }
            });
        }

        setAddOrEditData({
            type,
            id,
            email,
            lastName,
            firstName,
            password: "",
            userRole: useThisRole,
            sendEmail: false,
            generatePassword: false,
        })
        setShowCreate(true)
    }

    const handleClose = (method?: string) => {
        const cleanFormFieldData = resetErrors(formFields);
        setFormFields({ ...formFields, ...cleanFormFieldData });

        setAddOrEditData({
            type: 'create',
            id: 0,
            email: '',
            lastName: '',
            firstName: '',
            password: '',
            userRole: useThisRole,
            sendEmail: false,
            generatePassword: false
        })
        userDataRefresh()
        setShowCreate(false)
    }

    useEffect(() => {
        if (createUserResponse) {
            if (createUserResponse.createUser.response === "success") {
                addError("success", "Successfully saved " + props.label);
                handleClose();
                return
            }

            addError("error", "Unable to save " + props.label + ". Please check errors and try again.")
            if (createUserResponse.createUser.errors) {
                createUserResponse.createUser.errors.forEach((value: any, index: number) => {
                    setFormFields(fFields => {
                        let tempFormFields = fFields;
                        tempFormFields[value.field].errors = true
                        tempFormFields[value.field].errorMessages = value.message

                        return { ...fFields, ...tempFormFields }
                    })
                })
            }
        }
    }, [addError, props.label, createUserResponse])

    useEffect(() => {
        if (updateUserResponse) {
            if (updateUserResponse.updateUser.response === "success") {
                addError("success", "Successfully saved " + props.label);
                handleClose();
                return
            }

            addError("error", "Unable to save " + props.label + ". Please check errors and try again.")
            if (updateUserResponse.updateUser.errors) {
                updateUserResponse.updateUser.errors.forEach((value: any, index: number) => {
                    setFormFields(fFields => {
                        let tempFormFields = fFields;
                        tempFormFields[value.field].errors = true
                        tempFormFields[value.field].errorMessages = value.message

                        return { ...fFields, ...tempFormFields }
                    })
                })
            }
        }
    }, [addError, props.label, updateUserResponse])

    const handleOpenDeleteUser = (user?: any) => {
        setDeleteThisUser(user)
        setShowDeleteDialog(true)
    }

    const handleDeleteUser = async () => {
        if (!deleteThisUser) {
            return;
        }

        deleteUser({
            variables: {
                userId: deleteThisUser.id
            },
            refetchQueries: [GET_USERS_QUERY]
        })
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setTablePage(newPage);
    };

    const deleteUserText = useMemo(() => {
        if (deleteThisUser) {
            return <DialogContentText color="primary">
                Are you sure you want to delete {deleteThisUser.firstName + " " + deleteThisUser.lastName}?<br /><strong>This action cannot be undone.</strong>
            </DialogContentText>
        }
    }, [deleteThisUser])

    useEffect(() => {
        if (deleteUserResponse) {
            setDeleteThisUser(null)
            setShowDeleteDialog(false)

            if (deleteUserResponse.deleteUser) {
                addError('success', "Successfully deleted User")
                return
            }
            addError('error', "Error encountered while trying to delete the selected item.")
        }
    }, [deleteUserResponse, addError, userDataRefresh])

    return (
        <React.Fragment>
            <ContentHeader
                title={props.label}
                loading={userDataLoading}
                options={[
                    { text: "Refresh Data", devOnly: true, action: (e: any) => userDataRefresh },
                    {
                        text: "Create " + props.label, action: (e: any) => {
                            setAddOrEditData({
                                type: 'create',
                                id: 0,
                                email: '',
                                lastName: '',
                                firstName: '',
                                password: '',
                                userRole: useThisRole,
                                sendEmail: false,
                                generatePassword: false
                            })
                            setShowCreate(true)
                        }
                    }
                ]}
            />
            <TableContainer component={Paper}>
                <Table
                    style={{ width: '100%' }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell width="140px">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userDataLoading && !userData &&
                            <TableRow>
                                <TableCell colSpan={5} align="center"><CircularProgress size={12} color="secondary" /> Fetching Data</TableCell>
                            </TableRow>
                        }
                        {!userDataLoading && userData && userData.getUsersByRole.length <= 0 &&
                            <TableRow>
                                <TableCell colSpan={5} align="center">No Records to Display</TableCell>
                            </TableRow>
                        }
                        {userData && userData.getUsersByRole
                            .slice(tablePage * tableRowsPerPage, tablePage * tableRowsPerPage + tableRowsPerPage)
                            .map((user: any) => {
                                return <TableRow key={user.userEmail}>
                                    <TableCell>{user.userEmail}</TableCell>
                                    <TableCell>{user.lastName}</TableCell>
                                    <TableCell>{user.firstName}</TableCell>
                                    <TableCell>{new Date(user.createdAt).toDateString()}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={e => {
                                                handleClickOpen(
                                                    user.id,
                                                    user.userEmail,
                                                    user.lastName,
                                                    user.firstName
                                                )
                                            }}>
                                            <Tooltip title="Edit">
                                                <Edit />
                                            </Tooltip>
                                        </IconButton>
                                        {me && user.id !== me.id &&
                                            <IconButton onClick={e =>
                                                handleOpenDeleteUser(user)
                                            }>
                                                <Tooltip title="Delete">
                                                    <Delete />
                                                </Tooltip>
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component={Paper}
                count={(userData?.getUsersByRole ? userData.getUsersByRole.length : 0)}
                rowsPerPage={tableRowsPerPage}
                page={tablePage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={showCreate} onClose={e => handleClose()} aria-labelledby="form-dialog-title">
                <form name="CreateUser">
                    <DialogTitle>{addOrEditData.type === 'update' ? 'Edit' : 'Create'} {props.label}</DialogTitle>
                    <DialogContent>
                        <TextField
                            value={addOrEditData.email}
                            onChange={e => {
                                const email = e.currentTarget.value.trim().toLowerCase()
                                setAddOrEditData(n => ({ ...n, email }))
                            }}
                            onBlur={e => {
                                handleFieldUpdate("email", e.currentTarget.value)
                            }}
                            error={formFields.email.errors}
                            helperText={formFields.email.errors ? formFields.email.errorMessages : ""}
                            margin="dense"
                            id="name"
                            label="Email"
                            fullWidth
                            required={true}
                        />
                        <TextField
                            value={addOrEditData.firstName}
                            onChange={e => {
                                const firstName = e.currentTarget.value
                                handleFieldUpdate("firstName", firstName)
                                setAddOrEditData(n => ({ ...n, firstName }))
                            }}
                            onBlur={e => {
                                handleFieldUpdate("firstName", e.currentTarget.value)
                            }}
                            error={formFields.firstName.errors}
                            helperText={formFields.firstName.errors ? formFields.firstName.errorMessages : ""}
                            margin="dense"
                            id="name"
                            label="First name"
                            fullWidth
                            required={true}
                        />
                        <TextField
                            value={addOrEditData.lastName}
                            onChange={e => {
                                const lastName = e.currentTarget.value
                                handleFieldUpdate("lastName", lastName)
                                setAddOrEditData(n => ({ ...n, lastName }))
                            }}
                            onBlur={e => {
                                handleFieldUpdate("lastName", e.currentTarget.value)
                            }}
                            error={formFields.lastName.errors}
                            helperText={formFields.lastName.errors ? formFields.lastName.errorMessages : ""}
                            margin="dense"
                            id="name"
                            label="Last name"
                            fullWidth
                            required={true}
                        />
                        <TextField
                            value={addOrEditData.password}
                            inputProps={{ minLength: 6 }}
                            onChange={e => {
                                const password = e.currentTarget.value
                                handleFieldUpdate("password", password)
                                setAddOrEditData(n => ({ ...n, password }))
                            }}
                            onBlur={e => {
                                handleFieldUpdate("password", e.currentTarget.value)
                            }}
                            error={formFields.password.errors}
                            helperText={formFields.password.errors ? formFields.password.errorMessages : ""}
                            margin="dense"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(e) => setShowPassword((p) => !p)}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {showPassword ? (
                                                <Visibility htmlColor="#AEAEAE" />
                                            ) : (
                                                <VisibilityOff htmlColor="#2E2E2E" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            label={addOrEditData.type === "create" ? "Password" : "New Password"}
                            fullWidth
                        />
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={addOrEditData.generatePassword}
                                        onChange={e => {
                                            setAddOrEditData(n => ({ ...n, ...{ generatePassword: e.target.checked } }))
                                        }}
                                        name="physical"
                                        color="primary"
                                    />
                                }
                                label="Generate New Password"
                            />
                        </FormControl>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={addOrEditData.sendEmail}
                                        onChange={e => {
                                            setAddOrEditData(n => ({ ...n, ...{ sendEmail: e.target.checked } }))
                                        }}
                                        name="physical"
                                        color="primary"
                                    />
                                }
                                label="Send Account Email"
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={createUserLoading || updateUserLoading} onClick={e => handleClose("cancel")} color="primary">Cancel</Button>
                        <Button
                            disabled={createUserLoading || updateUserLoading}
                            onClick={addOrUpdateUsers}
                            variant="contained"
                            color="primary"
                        >{(createUserLoading || updateUserLoading) && <CircularProgress size={11} style={{ marginRight: "10px" }} />}{addOrEditData.type === 'create' ? 'Add' : 'Update'}</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={showDeleteDialog} title={"Delete " + props.label}>
                <DialogTitle>Confirm {props.label} Deletion</DialogTitle>
                <DialogContent>
                    {deleteUserText}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary"
                        disabled={deleteUserLoading}
                        onClick={e => {
                            setShowDeleteDialog(false)
                            setDeleteThisUser(null)
                        }}
                    >Cancel</Button>
                    <Button color="primary" variant="contained"
                        disabled={deleteUserLoading}
                        onClick={e =>
                            handleDeleteUser()
                        }
                    >{deleteUserLoading && <CircularProgress size={11} style={{ marginRight: "10px" }} />}Delete</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
