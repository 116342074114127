import {
    Button,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    CircularProgress,
    TextField,
    MenuItem,
    TablePagination,
} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import React, { useEffect, useMemo, useState } from 'react'

import { loader } from "graphql.macro";
import { useQuery, useMutation } from '@apollo/client';
import { ContentHeader } from '../../components/common/ContentHeader'
import { useError } from '../../hooks/useError';

import {
    GetTagsQuery, GetTagsQueryVariables,
    CreateTagMutation, CreateTagMutationVariables,
    UpdateTagMutation, UpdateTagMutationVariables,
    DeleteTagMutation, DeleteTagMutationVariables
} from '../../generated/graphql'

const GET_TAGS = loader('../../graphql/Tags/GetTags.graphql')
const CREATE_TAG = loader('../../graphql/Tags/CreateTag.graphql')
const UPDATE_TAG = loader('../../graphql/Tags/UpdateTag.graphql')
const DELETE_TAG = loader('../../graphql/Tags/DeleteTag.graphql')

enum TagTypes {
    ADVERTISEMENT = 'advertisement',
    ADVERTISER = 'advertiser',
    NOT_FOR_PROFIT = 'nfp'
}

export function ManageTags() {
    document.title = "Manage Tags - ManySeeds"
    const { addError } = useError()

    const { data: tagData, loading: tagDataLoading, error: tagDataError, refetch: tagDataRefetch } =
        useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS, { notifyOnNetworkStatusChange: true })

    const [createTag, { data: createTagResponse, loading: createTagLoading }] =
        useMutation<CreateTagMutation, CreateTagMutationVariables>(CREATE_TAG);

    const [updateTag, { data: updateTagResponse, loading: updateTagLoading }] =
        useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UPDATE_TAG);

    const [deleteTag, { data: deleteTagResponse, loading: deleteTagLoading }] =
        useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DELETE_TAG);

    const [tablePage, setTablePage] = useState<number>(0);
    const [tableRowsPerPage, setRowsPerPage] = useState<number>(10);

    const [editMode, setEditMode] = useState<"add" | "edit">("add")
    const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false)
    const [updateThis, setUpdateThis] = useState<any>({
        id: null,
        name: "",
        type: ""
    })

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [deleteThis, setDeleteThis] = useState<any>()

    useEffect(() => {
        //Refetch on component re-mounting
        if (tagData && !tagDataLoading) {
            tagDataRefetch()
        }
    }, [])

    const handleOpenUpdate = (tag?: any) => {
        setEditMode("edit")
        setUpdateThis(tag)
        setShowUpdateDialog(true)
    }

    const handleUpdate = () => {
        if (editMode === "edit") {
            updateTag({
                variables: {
                    tagId: updateThis.id,
                    tagName: updateThis.name,
                    type: updateThis.type
                }
            })
            return
        }
        createTag({
            variables: {
                tagName: updateThis.name,
                type: updateThis.type
            }
        })
    }

    useEffect(() => {
        if (updateTagResponse) {
            addError((updateTagResponse.updateTag.response === "success" ? "success" : "error"), updateTagResponse.updateTag.message)
            if (updateTagResponse.updateTag.response === "success") {
                setShowUpdateDialog(false)
            }
            tagDataRefetch()
            return
        }
        if (createTagResponse) {
            addError((createTagResponse.createTag.response === "success" ? "success" : "error"), createTagResponse.createTag.message)
            if (createTagResponse.createTag.response === "success") {
                setShowUpdateDialog(false)
            }
            tagDataRefetch()
            return
        }
    }, [addError, tagDataRefetch, updateTagResponse, createTagResponse])

    const handleOpenDelete = (tag?: any) => {
        setDeleteThis(tag)
        setShowDeleteDialog(true)
    }

    const handleDelete = () => {
        console.log('Trigger Delete')
        deleteTag({
            variables: {
                tagId: deleteThis.id
            }
        })
        setShowDeleteDialog(false)
        setDeleteThis(null)
    }

    const deleteUserText = useMemo(() => {
        if (deleteThis) {
            return <DialogContentText color="primary">
                Are you sure you want to delete {deleteThis.name} ? <strong>This action cannot be reverted</strong>
            </DialogContentText>
        }
    }, [deleteThis])

    useEffect(() => {
        if (deleteTagResponse) {
            if (deleteTagResponse.deleteTag) {
                addError("success", "Tag deleted successfully")
                tagDataRefetch()
            } else {
                addError("error", "Error deleteing Tag")
            }
        }
    }, [addError, tagDataRefetch, deleteTagResponse])

    useEffect(() => {
        if (tagDataError) {
            addError("error", "Error fetching data: " + tagDataError.message)
        }
    }, [tagDataError, addError])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setTablePage(newPage);
    };

    return (
        <React.Fragment>
            <ContentHeader
                title="Tags"
                options={[
                    { text: "Refresh Data", action: (e: any) => { tagDataRefetch() }, devOnly: true },
                    {
                        text: "Create Tag", action: (e: any) => {
                            setEditMode('add')
                            setUpdateThis({ id: null, name: "" })
                            setShowUpdateDialog(true)
                        }
                    }
                ]}
                loading={tagDataLoading}
            />
            <TableContainer component={Paper}>
                <Table
                    style={{ width: '100%' }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>TagID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell width="140px" align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tagDataLoading && !tagData &&
                            <TableRow>
                                <TableCell colSpan={5} align="center"><CircularProgress size={12} color="secondary" /> Fetching Data</TableCell>
                            </TableRow>
                        }
                        {!tagDataLoading && tagData && tagData.getTags.length <= 0 &&
                            <TableRow>
                                <TableCell colSpan={5} align="center">No Records to Display</TableCell>
                            </TableRow>
                        }
                        {tagData && tagData.getTags
                            .slice(tablePage * tableRowsPerPage, tablePage * tableRowsPerPage + tableRowsPerPage)
                            .map((tag: any) => {
                                return (
                                    <TableRow key={tag.id}>
                                        <TableCell>{tag.id}</TableCell>
                                        <TableCell>{tag.name}</TableCell>
                                        <TableCell>{tag.type}</TableCell>
                                        <TableCell>{new Date(tag.createdAt).toDateString()}</TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Edit">
                                                <IconButton onClick={e => handleOpenUpdate(tag)}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            {tag.protected !== 1 &&
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={e => handleOpenDelete(tag)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component={Paper}
                count={(tagData?.getTags ? tagData.getTags.length : 0)}
                rowsPerPage={tableRowsPerPage}
                page={tablePage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog fullWidth maxWidth="sm" open={showUpdateDialog} title={(editMode === "add" ? "Create" : "Edit") + " Tag"}>
                <form name="SaveTag">
                    <DialogTitle>{editMode === "add" ? "Create" : "Edit"} Tag</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Tag"
                            value={updateThis.name}
                            onChange={e => setUpdateThis({ ...updateThis, ...{ name: e.target.value } })}
                            fullWidth
                            margin="dense"
                        />
                        <TextField
                            label="Type"
                            value={updateThis.type}
                            onChange={e => setUpdateThis({ ...updateThis, ...{ type: e.target.value } })}
                            fullWidth
                            margin="dense"
                            select
                        >
                            <MenuItem selected={updateThis.type === TagTypes.ADVERTISEMENT} value={TagTypes.ADVERTISEMENT}>Advertisement</MenuItem>
                            <MenuItem selected={updateThis.type === TagTypes.ADVERTISER} value={TagTypes.ADVERTISER}>Advertiser</MenuItem>
                            <MenuItem selected={updateThis.type === TagTypes.NOT_FOR_PROFIT} value={TagTypes.NOT_FOR_PROFIT}>Not For Profit</MenuItem>
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="secondary"
                            disabled={createTagLoading || updateTagLoading}
                            onClick={e => {
                                setEditMode("add")
                                setShowUpdateDialog(false);
                                setUpdateThis({ id: null, name: "" })
                            }}
                        >Cancel</Button>
                        <Button color="primary" variant="contained"
                            disabled={createTagLoading || updateTagLoading}
                            onClick={e =>
                                handleUpdate()
                            }
                        >{(createTagLoading || updateTagLoading) && <CircularProgress size={11} style={{ marginRight: "10px" }} />}Save</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={showDeleteDialog} title={"Delete Tag"}>
                <DialogTitle>Confirm Tag Deletion</DialogTitle>
                <DialogContent>
                    {deleteUserText}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary"
                        disabled={deleteTagLoading}
                        onClick={e => {
                            setShowDeleteDialog(false)
                            setDeleteThis(null)
                        }}
                    >Cancel</Button>
                    <Button color="primary" variant="contained"
                        disabled={deleteTagLoading}
                        onClick={e =>
                            handleDelete()
                        }
                    >{deleteTagLoading && <CircularProgress size={11} style={{ marginRight: "10px" }} />}Delete</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
