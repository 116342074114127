import React from "react"
import { NavLink } from 'react-router-dom'
import {
    AccountCircle,
    AttachMoney,
    Dashboard,
    MonetizationOn,
    Web
} from '@material-ui/icons'

export function AdvertiserNavigation(props: {clickHandler: (e: any) => void}) {
    return <React.Fragment>
        <h2>General</h2>
        <ul>
            <li><NavLink to="/admin/dashboard" onClick={props.clickHandler}><Dashboard />Dashboard</NavLink></li>
            <li><NavLink to="/admin/profile" onClick={props.clickHandler}><AccountCircle />Profile</NavLink></li>
            <li><NavLink to="/admin/balance" onClick={props.clickHandler}><MonetizationOn />Balance</NavLink></li>
        </ul>
        <h2>Management</h2>
        <ul>
            <li><NavLink to="/admin/advertisements" onClick={props.clickHandler}><Web />Advertisements</NavLink></li>
        </ul>
        <h2>Reporting</h2>
        <ul>
            <li><NavLink to="/admin/reports" onClick={props.clickHandler}><AttachMoney />Financial Reporting</NavLink></li>
        </ul>
    </React.Fragment>
}