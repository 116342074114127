import React, { useEffect, useState } from 'react'
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom'
import { AdminLayout } from './layouts/AdminLayout';
import { useAdminAuth } from './hooks/useAdminAuth'

interface AdminRouteProps extends RouteProps {
    component: any,
    meData?: any,
    requiredRole?: "admin" | "nfp" | "advertiser"
}

function getComponent(
    isAdmin: boolean | undefined,
    Component: any,
    props: RouteComponentProps
) {
    if (isAdmin ===  undefined || !isAdmin) {
        return <></>
    }
    
    return (
        <AdminLayout>
            <Component {...props} />
        </AdminLayout>
    )
}

export function AdminRoute({ component: Component, meData, requiredRole, ...rest }: AdminRouteProps) {
    const { adminLoginLoading, isAdminLoggedIn } = useAdminAuth()
    const [ showLoading, setShowLoading ] = useState<boolean>(false)

    useEffect(() => {
        setShowLoading(!isAdminLoggedIn && adminLoginLoading)
    }, [isAdminLoggedIn, adminLoginLoading])

    if(showLoading) {
        return <p>Logging In...</p>
    }
    
    return (
        <Route
            {...rest}
            render={props => {
                return getComponent(isAdminLoggedIn, Component, props)
            }}
        />
    )
}
