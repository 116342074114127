import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client';

import { Card, CardContent, CardHeader, CircularProgress, Grid, Paper } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Doughnut, Line } from "react-chartjs-2";

import {
    GetActiveUsers,
    GetAdvertisers,
    GetNotForProfits,
    GetAdPerformance
} from "../../graphql/Dashboard/GetAdminDashboard";

import {
    AdminDashboardActiveUsersQuery, AdminDashboardActiveUsersQueryVariables,
    AdminDashboardAdvertisersQuery, AdminDashboardAdvertisersQueryVariables,
    AdminDashboardNotForProfitsQuery, AdminDashboardNotForProfitsQueryVariables,
    AdminDashboardAdPerformanceQuery, AdminDashboardAdPerformanceQueryVariables,
    TestSendPushNotificationsMutation, TestSendPushNotificationsMutationVariables
} from '../../generated/graphql';
import { ContentHeader } from '../../components/common/ContentHeader';

import { loader } from 'graphql.macro';
const SEND_PUSH_NOTICES = loader('../../graphql/Dashboard/TestSendPushNotifications.graphql');

export function Dashboard() {
    document.title = "Administrative Dashboard - ManySeeds";

    const baseDate = new Date();
    const [dateRangeStart, setDateRangeStart] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth(), 1));
    const [dateRangeEnd, setDateRangeEnd] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 0))

    const { data: usersData, loading: usersLoading, refetch: refetchUsers } = useQuery<AdminDashboardActiveUsersQuery, AdminDashboardActiveUsersQueryVariables>(GetActiveUsers, { variables: { dateRangeStart, dateRangeEnd } });
    const { data: advertisersData, loading: advertisersLoading, refetch: refetchAdvertisers } = useQuery<AdminDashboardAdvertisersQuery, AdminDashboardAdvertisersQueryVariables>(GetAdvertisers, { variables: { dateRangeStart, dateRangeEnd } });
    const { data: nfpsData, loading: nfpsLoading, refetch: refetchNfps } = useQuery<AdminDashboardNotForProfitsQuery, AdminDashboardNotForProfitsQueryVariables>(GetNotForProfits, { variables: { dateRangeStart, dateRangeEnd } });

    const { data: adData, loading: adLoading, refetch: refetchStats } = useQuery<AdminDashboardAdPerformanceQuery, AdminDashboardAdPerformanceQueryVariables>(GetAdPerformance, { variables: { dateRangeStart, dateRangeEnd } });

    const [sendPushNotices] = useMutation<TestSendPushNotificationsMutation, TestSendPushNotificationsMutationVariables>(SEND_PUSH_NOTICES);
    
    const [usersChartData, setUsersChartData] = useState<any>();
    const [advertisersChartData, setAdvertisersChartData] = useState<any>();
    const [nfpsChartData, setNfpsChartData] = useState<any>();
    const [adChartData, setAdChartData] = useState<any>();

    useEffect(() => {
        if (adData && !adLoading) {
            refetchUsers();
            refetchAdvertisers();
            refetchNfps();
            refetchStats();
        }
    }, [])

    useEffect(() => {
        if (usersData) {
            setUsersChartData({
                labels: ["Total Users", "Active", "Inactive", "Banned", "Awaiting Approval", "New Users"],
                datasets: [{
                    label: "Users",
                    data: [
                        usersData.adminDashboardActiveUsers.all,
                        usersData.adminDashboardActiveUsers.active,
                        usersData.adminDashboardActiveUsers.inactive,
                        usersData.adminDashboardActiveUsers.banned,
                        usersData.adminDashboardActiveUsers.awaitingApproval,
                        usersData.adminDashboardActiveUsers.new
                    ],
                    backgroundColor: ['green', 'blue', 'red', 'yellow', 'purple'],
                    hoverOffset: 4
                }]
            })
        }
    }, [usersData])

    useEffect(() => {
        if (advertisersData) {
            setAdvertisersChartData({
                labels: ["Total Users", "Active", "Inactive", "Banned", "Awaiting Approval"],
                datasets: [{
                    label: "Users",
                    data: [
                        advertisersData.adminDashboardAdvertisers.all,
                        advertisersData.adminDashboardAdvertisers.active,
                        advertisersData.adminDashboardAdvertisers.inactive,
                        advertisersData.adminDashboardAdvertisers.banned,
                        advertisersData.adminDashboardAdvertisers.awaitingApproval
                    ],
                    backgroundColor: ['green', 'blue', 'red', 'yellow', 'purple'],
                    hoverOffset: 4
                }]
            })
        }
    }, [advertisersData])

    useEffect(() => {
        if (nfpsData) {
            setNfpsChartData({
                labels: ["Total Users", "Active", "Inactive", "Banned", "Awaiting Approval"],
                datasets: [{
                    label: "Users",
                    data: [
                        nfpsData.adminDashboardNotForProfits.all,
                        nfpsData.adminDashboardNotForProfits.active,
                        nfpsData.adminDashboardNotForProfits.inactive,
                        nfpsData.adminDashboardNotForProfits.banned,
                        nfpsData.adminDashboardNotForProfits.awaitingApproval
                    ],
                    backgroundColor: ['green', 'blue', 'red', 'yellow', 'purple'],
                    hoverOffset: 4
                }]
            })
        }
    }, [nfpsData])

    useEffect(() => {
        if (adData) {
            setAdChartData({
                labels: adData.adminDashboardAdPerformance.dates,
                datasets: [
                    { data: adData.adminDashboardAdPerformance.stats.start, label: "Ad Starts", fill: false, backgroundColor: "rgb(255, 205, 86)", borderColor: "rgb(255, 205, 86)" },
                    { data: adData.adminDashboardAdPerformance.stats.complete, label: "Complete Views", fill: false, backgroundColor: "rgb(75, 192, 192)", borderColor: "rgb(75, 192, 192)" },
                    //{ data: adData.adminDashboardAdPerformance.stats.replay, label: "Replays", fill: false, backgroundColor: "rgb(255, 99, 132)", borderColor: "rgb(255, 99, 132)" },
                ]
            })
        }
    }, [adData])

    const handleDateChange = (source: "start" | "end", newDate: any) => {
        switch (source) {
            case "start":
                setDateRangeStart(newDate)
                break;
            case "end":
                setDateRangeEnd(newDate)
                break;
        }
    }

    const handleSendPush = (type: "zero" | "streak") => {
        sendPushNotices({
            variables: {
                type
            }
        })
    }

    return (
        <div className="admin-dashboard">
            <ContentHeader
                title="Overview"
                options={[
                    { text: "Send Notification", action: () => handleSendPush("zero") }
                ]}
            />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom:"15px" }}>
                <div style={{ alignSelf: "center" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div>
                            <DatePicker
                                disableToolbar
                                variant="inline"
                                label="Range Start"
                                value={dateRangeStart}
                                onChange={e => handleDateChange('start', e)}
                            />&nbsp;
                            <DatePicker
                                disableToolbar
                                variant="inline"
                                label="Range End"
                                value={dateRangeEnd}
                                onChange={e => handleDateChange('end', e)}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Card variant="outlined">
                        <CardHeader title="Users" />
                        <CardContent>
                            {usersChartData ?
                                <Doughnut
                                    data={usersChartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: true
                                    }}
                                />
                                :
                                <CircularProgress style={{ margin: "0 auto" }} size={40} />
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card variant="outlined">
                        <CardHeader title="Advertisers" />
                        <CardContent>
                            {advertisersChartData ?
                                <Doughnut
                                    data={advertisersChartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: true
                                    }}
                                />
                                :
                                <CircularProgress style={{ margin: "0 auto" }} size={40} />
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card variant="outlined">
                        <CardHeader title="Not For Profits" />
                        <CardContent>
                            {nfpsChartData ?
                                <Doughnut
                                    data={nfpsChartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: true,
                                    }}
                                />
                                :
                                <CircularProgress style={{ margin: "0 auto" }} size={40} />
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: "2em" }}>
                <Grid item xs={12}>
                    <Paper variant="outlined">
                        <CardHeader title="Ad Performance" />
                        <CardContent>
                            {adChartData ?
                                <Line
                                    data={adChartData}
                                    options={{
                                        responsive: true,
                                        scales: {
                                            y: {
                                                beginAtZero: true,
                                                ticks: {
                                                    stepSize: 1
                                                }
                                            }
                                        },
                                        maintainAspectRatio: true
                                    }}
                                />
                                :
                                <CircularProgress style={{ margin: "0 auto" }} size={40} />
                            }
                        </CardContent>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}