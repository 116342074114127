import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { AdvertiserNavigation, NotForProfitNavigation, AdminNavigation } from '../components/Navigation'
import { useAdminAuth } from '../hooks/useAdminAuth'
import { UserRole } from '../graphql/Fragments/User.enum'

import { loader } from 'graphql.macro'
import {
    GetMyNotForProfitQuery, GetMyNotForProfitQueryVariables,
    GetMyAdvertiserQuery, GetMyAdvertiserQueryVariables
} from '../generated/graphql'

const GET_NFP = loader('../graphql/NotForProfits/GetMyNotForProfit.graphql')
const GET_ADVERTISER = loader('../graphql/Advertisers/GetMyAdvertiser.graphql')

export function Navigation() {
    const { me } = useAdminAuth();
    const history = useHistory();

    const [logo, setLogo] = useState<string>();

    const [getMyNfpQuery, { data: myNfpData }] = useLazyQuery<GetMyNotForProfitQuery, GetMyNotForProfitQueryVariables>(GET_NFP);
    const [getMyAdvertiserQuery, { data: myAdvertiserData }] = useLazyQuery<GetMyAdvertiserQuery, GetMyAdvertiserQueryVariables>(GET_ADVERTISER);

    useEffect(() => {
        if(me) {
            if (me.userRole === "nfp") {
                getMyNfpQuery()
            } else if(me.userRole === "advertiser") {
                getMyAdvertiserQuery()
            }
        }
    }, [me])

    useEffect(() => {
        if(myNfpData && myNfpData.getMyNfp.logo) {
            setLogo(`charities/${myNfpData.getMyNfp.logo}`);
        }
        if(myAdvertiserData && myAdvertiserData.getMyAdvertiser.logo) {
            setLogo(`advlogos/${myAdvertiserData.getMyAdvertiser.logo}`);
        }
    }, [myNfpData, myAdvertiserData])

    const handleNavLinkClick = (e: any) => {
        //console.log(e.target.attributes.href.value)
        history.push(e.target.attributes.href.value)
    }

    return (<>
        <nav>
            {me?.userRole === UserRole.ADMIN && <AdminNavigation clickHandler={handleNavLinkClick} />}
            {me?.userRole === UserRole.ADVERTISER && <AdvertiserNavigation clickHandler={handleNavLinkClick} />}
            {me?.userRole === UserRole.NFP && <NotForProfitNavigation clickHandler={handleNavLinkClick} />}
        </nav>
        {
            logo && logo !== "" && 
                <div className="client-logo">
                    <div>
                        <img
                            src={`${process.env.REACT_APP_S3_ENDPOINT}/${logo}`}
                            alt=""
                            style={{ width:"100%", height:"auto", maxHeight: "250px" }}
                        />
                    </div>
                </div>
        }
    </>
    )
}
