import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    TablePagination
} from '@material-ui/core'
import { BarChartTwoTone, Delete, Edit } from '@material-ui/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { loader } from "graphql.macro";
import { useQuery, useMutation } from '@apollo/client';
import { ContentHeader } from '../../components/common/ContentHeader'
import { useError } from '../../hooks/useError';

import {
    GetAdvertisersQuery, GetAdvertisersQueryVariables,
    DeleteAdvertiserMutation, DeleteAdvertiserMutationVariables
} from '../../generated/graphql'
import FormLoadingOverlay from '../../components/common/FormLoadingOverlay';
import { UserStatus } from '../../graphql/Fragments/User.enum';

const GET_ADVERTISERS_QUERY = loader('../../graphql/Advertisers/GetAdvertisers.graphql');
const UPDATE_ADVERTISER_STATUS = loader('../../graphql/Advertisers/UpdateStatus.graphql');
const DELETE_ADVERTISER_MUTATION = loader('../../graphql/Advertisers/DeleteAdvertiser.graphql');

export function ManageAdvertisers() {
    document.title = "Manage Advertisers - ManySeeds"
    const history = useHistory()
    const { addError } = useError()


    const { data: advertiserData, loading: advertiserDataLoading, error: advertiserDataError, refetch: advertiserDataRefresh } = useQuery<GetAdvertisersQuery, GetAdvertisersQueryVariables>(GET_ADVERTISERS_QUERY)
    const [updateStatus] = useMutation(UPDATE_ADVERTISER_STATUS);
    const [deleteAdvertiser, { data: deleteAdvertiserResponse }] = useMutation<DeleteAdvertiserMutation, DeleteAdvertiserMutationVariables>(DELETE_ADVERTISER_MUTATION);

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [updatingThisAdvertiser, setUpdatingThisAdvertiser] = useState<number>(0)
    const [deleteThisAdvertiser, setDeleteThisAdvertiser] = useState<any>()

    const [tablePage, setTablePage] = useState<number>(0);
    const [tableRowsPerPage, setRowsPerPage] = useState<number>(10);

    useEffect(() => {
        //Refetch on component re-mounting
        if (advertiserData && !advertiserDataLoading) {
            advertiserDataRefresh()
        }
    }, [])

    const handleUpdateStatus = (advertiser: any, newStatus: UserStatus) => {
        setUpdatingThisAdvertiser(advertiser.id)

        updateStatus({
            variables: {
                advertiserId: advertiser.id,
                status: newStatus
            },
            refetchQueries: [GET_ADVERTISERS_QUERY]
        }).catch(error => {
            addError("error", "Error updating Advertiser: " + error.message)
        }).then(() => {
            addError("success", "Successfully updated Advertiser")
            setUpdatingThisAdvertiser(0)
        })
    }

    const handleOpenDeleteAdvertiser = (advertiser?: any) => {
        setDeleteThisAdvertiser(advertiser)
        setShowDeleteDialog(true)
    }

    const handleDeleteAdvertiser = async () => {
        if (!deleteThisAdvertiser) {
            addError("error", "An error was encountered while trying to delete the selected item.")
        }

        deleteAdvertiser({
            variables: {
                advertiserId: deleteThisAdvertiser.id
            },
            refetchQueries: [GET_ADVERTISERS_QUERY]
        })
        setDeleteThisAdvertiser(undefined)
    }

    useEffect(() => {
        if (deleteAdvertiserResponse) {
            if (deleteAdvertiserResponse.deleteAdvertiser) {
                addError('success', "Successfully deleted Advertiser")
                advertiserDataRefresh()
                return
            }
            addError('error', "Error encountered while trying to delete the selected item.")
        }
    }, [deleteAdvertiserResponse, addError, advertiserDataRefresh])

    const deleteUserText = useMemo(() => {
        if (deleteThisAdvertiser) {
            return <DialogContentText color="primary">
                Are you sure you want to delete {deleteThisAdvertiser.name} ? <strong>This action cannot be reverted</strong>
            </DialogContentText>
        }
    }, [deleteThisAdvertiser])

    useEffect(() => {
        if (advertiserDataError) {
            addError("error", "Error fetching data: " + advertiserDataError.message)
        }
    }, [advertiserDataError, addError])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setTablePage(newPage);
    };

    return (
        <React.Fragment>
            <ContentHeader
                title="Advertisers"
                options={[
                    { text: "Refresh Data", action: (e: any) => { advertiserDataRefresh() }, devOnly: true },
                    { text: "Create Advertiser", action: (e: any) => { history.push('/admin/advertisers/add'); } }
                ]}
                loading={advertiserDataLoading}
            />
            <TableContainer component={Paper}>
                <Table
                    style={{ width: '100%' }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Contact</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell width="200px" align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {advertiserDataLoading && !advertiserData &&
                            <TableRow>
                                <TableCell colSpan={5} align="center">Fetching Data...</TableCell>
                            </TableRow>
                        }
                        {!advertiserDataLoading && advertiserData && advertiserData.getAdvertisers.length <= 0 &&
                            <TableRow>
                                <TableCell colSpan={5} align="center">No Records to Display</TableCell>
                            </TableRow>
                        }
                        {advertiserData && advertiserData.getAdvertisers
                            .slice(tablePage * tableRowsPerPage, tablePage * tableRowsPerPage + tableRowsPerPage)
                            .map((advertiser: any) => {
                                return (<TableRow key={advertiser.id}>
                                    <TableCell>{advertiser.name}</TableCell>
                                    <TableCell>{advertiser.user.firstName + " " + advertiser.user.lastName}</TableCell>
                                    <TableCell>{advertiser.user.userEmail}</TableCell>
                                    <TableCell>{new Date(advertiser.createdAt).toDateString()}</TableCell>
                                    <TableCell style={{ position: "relative" }}>
                                        <FormLoadingOverlay show={(updatingThisAdvertiser === advertiser.id)} background={false} size={25} />
                                        <Select
                                            fullWidth
                                            value={advertiser.status}
                                            onChange={e => {
                                                const newStatus = e.target.value as UserStatus;
                                                handleUpdateStatus(advertiser, newStatus)
                                            }}
                                        >
                                            <MenuItem value={UserStatus.ACTIVE}>Active</MenuItem>
                                            <MenuItem value={UserStatus.INACTIVE}>Inactive</MenuItem>
                                            <MenuItem value={UserStatus.SUSPENDED}>Suspended</MenuItem>
                                            <MenuItem value={UserStatus.BANNED}>Banned</MenuItem>
                                            <MenuItem value={UserStatus.AWAITING_APPROVAL} disabled={true}>Awaiting Approval</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={e => {
                                                history.push('/admin/reports/?advertiser=' + advertiser.id)
                                            }}
                                        >
                                            <Tooltip title="Advertiser Stats Performance">
                                                <BarChartTwoTone />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton
                                            onClick={e => {
                                                e.preventDefault()
                                                history.push('/admin/advertisers/edit/' + advertiser.id)
                                            }}>
                                            <Tooltip title="Edit">
                                                <Edit />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton onClick={e =>
                                            handleOpenDeleteAdvertiser(advertiser)
                                        }>
                                            <Tooltip title="Delete">
                                                <Delete />
                                            </Tooltip>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component={Paper}
                count={(advertiserData?.getAdvertisers ? advertiserData.getAdvertisers.length : 0)}
                rowsPerPage={tableRowsPerPage}
                page={tablePage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={showDeleteDialog} title={"Delete Advertiser"}>
                <DialogTitle>Confirm Advertiser Deletion</DialogTitle>
                <DialogContent>
                    {deleteUserText}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary"
                        onClick={e => {
                            setShowDeleteDialog(false)
                            setDeleteThisAdvertiser(null)
                        }}
                    >Cancel</Button>
                    <Button color="primary" variant="contained"
                        onClick={e => {
                            setShowDeleteDialog(false);
                            addError("info", "Deleting Advertiser...");
                            handleDeleteAdvertiser()
                        }}
                    >Delete</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
