import { useEffect, useState } from "react";
import { Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useError } from "../../hooks/useError";

export function ErrorNotification() {
    const { errors, removeError } = useError()

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
    const [snackbarType, setSnackbarType] = useState<"error" | "success" | "warning" | "info">("info")
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [snackbarTimer, setSnackbarTimer] = useState<number>(5000)

    useEffect(() => {
        if(errors && errors.length > 0) {
            setSnackbarType(errors[0].type)
            setSnackbarMessage(errors[0].message)
            setSnackbarTimer((errors[0].timer || 5000))
            setSnackbarOpen(true)
            removeError()
        }
    }, [errors, removeError])

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false)
        setSnackbarMessage("")
    }

    return (
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={snackbarTimer} 
            onClose={handleCloseSnackbar} 
            //TransitionComponent={transitionComponent} 
            anchorOrigin={{vertical:"bottom",horizontal:"left"}}
        >
            <MuiAlert onClose={handleCloseSnackbar} severity={snackbarType} elevation={6} variant="filled">
                {snackbarMessage}
            </MuiAlert>
        </Snackbar>
    )
}