import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useError } from '../../hooks/useError';
import { ContentHeader } from '../../components/common/ContentHeader'

//import { loadScript } from "@paypal/paypal-js";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

import {
    AddBalanceMutation, AddBalanceMutationVariables,
    AddPaypalTransactionMutation, AddPaypalTransactionMutationVariables,
    GetMyAdvertiserQuery, GetMyAdvertiserQueryVariables,
    GetAdvertiserTransactionsQuery, GetAdvertiserTransactionsQueryVariables,
    GetTokenQuery, GetTokenQueryVariables,
    CancelPaypalTransactionMutation, CancelPaypalTransactionMutationVariables,
    CompletePaypalTransactionMutation, CompletePaypalTransactionMutationVariables
} from '../../generated/graphql';
import { ExportCSV, ExportXLSX } from '../../utils/exporter';

const GET_ADVERTISER_PROFILE = loader('../../graphql/Advertisers/GetMyAdvertiser.graphql')
const GET_TRANSACTIONS_QUERY = loader('../../graphql/Advertisers/GetTransactions.graphql')

const GET_TOKEN = loader('../../graphql/Advertisers/GetToken.graphql');
const ADD_PP_TRANSX = loader('../../graphql/Paypal/AddPaypalTransaction.graphql');
const CANCEL_PP_TRANSX = loader('../../graphql/Paypal/CancelPaypalTransaction.graphql');
const COMPLETE_PP_TRANSX = loader('../../graphql/Paypal/CompletePaypalTransaction.graphql');

export function AdvertiserBalance() {
    const { addError } = useError()

    const [showFilterAccordion, setShowFilterAccordion] = useState<boolean>(false);

    const [paypalInputAmount, setPaypalInputAmount] = useState<number>(50.00);
    const [paypalAmount, setPaypalAmount] = useState<number>(50.00);
    const [paypalTaxAmount, setPaypalTaxAmount] = useState<number>(6.5);

    const [showExportDialog, setShowExportDialog] = useState<boolean>(false)
    const [exportFormat, setExportFormat] = useState<string>("excel");
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const baseDate = new Date();
    const [dateRangeStart, setDateRangeStart] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth(), 1));
    const [dateRangeEnd, setDateRangeEnd] = useState<Date>(new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 0));

    const [searchFilter, setSearchFilter] = useState<{
        dateRangeStart?: Date,
        dateRangeEnd?: Date,
    }>({ dateRangeStart, dateRangeEnd });

    const { data: tokenData, refetch: refetchToken } = useQuery<GetTokenQuery, GetTokenQueryVariables>(GET_TOKEN);
    const { data: advertiserData, refetch: refetchMyAdvertiser } = useQuery<GetMyAdvertiserQuery, GetMyAdvertiserQueryVariables>(GET_ADVERTISER_PROFILE);
    const { data: transactionsData, loading: transactionsLoading, refetch: refetchTransactions } = useQuery<GetAdvertiserTransactionsQuery, GetAdvertiserTransactionsQueryVariables>(GET_TRANSACTIONS_QUERY, {
        variables: searchFilter,
        nextFetchPolicy: "network-only"
    })

    const [showPaypal, setShowPaypal] = useState<boolean>(false)

    const [ppOrderId, setPPOrderId] = useState<string>();
    const [transId, setTransId] = useState<number>();

    const [addPaypalTransx] = useMutation<AddPaypalTransactionMutation, AddPaypalTransactionMutationVariables>(ADD_PP_TRANSX);
    const [cancelPaypalTransx, { data: ppCancelResponse }] = useMutation<CancelPaypalTransactionMutation, CancelPaypalTransactionMutationVariables>(CANCEL_PP_TRANSX);
    const [completePaypalTransx, { data: ppCompleteResponse }] = useMutation<CompletePaypalTransactionMutation, CompletePaypalTransactionMutationVariables>(COMPLETE_PP_TRANSX);

    //Clear out the values on re-mount, just incase.. SPA's and caching.. yay.
    useEffect(() => {
        console.log('balance mounting');
        cleanUp();
    }, [])

    useEffect(() => {
        if (ppOrderId) {
            addPaypalTransx({
                variables: {
                    orderId: ppOrderId
                }
            }).then(response => {
                if (response.data?.addPaypalTransaction.id) {
                    setTransId(response.data?.addPaypalTransaction.id);
                    return
                }
                throw new Error("Transaction error");
            }).catch(error => {
                addError("error", "Error encountered while processing transaction");
            });
        }
    }, [ppOrderId]);

    const handlePPCreate = (orderId: any) => {
        setPPOrderId(orderId);
    }

    const handlePPApprove = (data: any, actions: any) => {
        //console.log('handlePPApprove', data, transId);
        if (!data.orderID) {
            alert('Approve Error encountered');
            cleanUp();
            return;
        }

        return completePaypalTransx({
            variables: {
                orderId: data.orderID
            }
        }).then(() => {
            refetchMyAdvertiser();
            refetchTransactions();
        }).finally(() => {
            cleanUp();
        })
    }

    const handlePPCancel = (data: any, actions: any) => {
        //console.log('Cancel', data);
        if (!data.orderID) {
            alert('Cancel Error encountered');
            cleanUp();
            return;
        }

        return cancelPaypalTransx({
            variables: {
                orderId: data.orderID
            }
        }).finally(() => {
            cleanUp();
        })
    }

    const cleanUp = () => {
        refetchToken();
        setTransId(undefined);
        setPPOrderId(undefined);
    }

    const handleSubmit = () => {
        refetchTransactions({ ...searchFilter })
    }

    const handleDateChange = (source: "start" | "end", newDate: any) => {
        switch (source) {
            case "start":
                setSearchFilter(filters => ({ ...filters, dateRangeStart: newDate }));
                setDateRangeStart(newDate)
                break;
            case "end":
                setSearchFilter(filters => ({ ...filters, dateRangeEnd: newDate }));
                setDateRangeEnd(newDate)
                break;
        }
    }

    const exportData = () => {
        if (!transactionsData || !["csv", "excel"].includes(exportFormat)) {
            return;
        }

        setExportLoading(true);

        const headersTop = ["Type", "Amount", "Notes", "Process Date"];
        const headersBottom = [""];
        const rowData = transactionsData.getAdvertiserTransactions.map((transx: any) => {
            return [
                transx.transactionType.toLocaleUpperCase(),
                "$" + transx.amount.toFixed(2),
                transx.note,
                new Date(transx.createdAt).toDateString(),
            ];
        });

        if (exportFormat === "csv") {
            ExportCSV(
                "transaction_report_" + new Date().toDateString().replaceAll(' ', '-') + ".csv",
                { headersTop, headersBottom, rowData }
            );
        } else if (exportFormat === "excel") {
            ExportXLSX(
                "transaction_report_" + new Date().toDateString().replaceAll(' ', '-') + ".xlsx",
                { headersTop, headersBottom, rowData }
            );
        }
        setExportLoading(false);
        setShowExportDialog(false);
    }

    useEffect(() => {
        if (ppCancelResponse) {
            //console.log('cancel response', ppCancelResponse);
        }
        if (ppCompleteResponse) {
            //console.log('complete response', ppCompleteResponse);
        }
    }, [ppCancelResponse, ppCompleteResponse])

    useEffect(() => {
        if (tokenData && tokenData.getToken) {
            setShowPaypal(true);
        }
    }, [tokenData])

    if (!transactionsLoading && !transactionsData) {
        return <>Error Loading Data</>
    }

    return <>
        <Grid container>
            <Grid item xs={12}>
                <ContentHeader title="Balance and Transactions" loading={transactionsLoading} />
                {/* <div style={{ margin: "2em 0",width:500 }}>
                    <Typography>This is only for debugging purposes</Typography>
                    <TextField label="" fullWidth value={ppOrderId} disabled={true} /><br />
                    <TextField label="" fullWidth value={transId} disabled={true} /><br />
                    <TextField label="" fullWidth value={tokenData?.getToken} disabled={true} />
                </div> */}
            </Grid>
            <Grid item xs={12}>
                <Paper className="form-paper">
                    <h3>Current Balance</h3>
                    <div>$<b>{advertiserData?.getMyAdvertiser?.currentBalance?.toFixed(2)}</b></div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <h4>Add Funds via Paypal</h4>
                            {showPaypal && paypalAmount && paypalTaxAmount && tokenData && process.env.REACT_APP_PAYPAL_CLIENTID &&
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-amount"
                                                    type="number"
                                                    value={paypalInputAmount}
                                                    onChange={e => {
                                                        const newValue = parseFloat(e.target.value.replace(/[^0-9.]/gi, ''));
                                                        setPaypalInputAmount(newValue);
                                                        //setPaypalTaxAmount(newValue * 0.13);
                                                    }}
                                                    onBlur={e => {
                                                        let newValue = parseFloat(e.target.value.replace(/[^0-9.]/gi, ''));
                                                        if(newValue < 10) {
                                                            alert('Minimum amount of $10');
                                                            newValue = 10.00;
                                                            setPaypalInputAmount(newValue);
                                                        }
                                                        setPaypalAmount(newValue);
                                                        //setPaypalTaxAmount(newValue * 0.13);
                                                    }}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PayPalScriptProvider options={{
                                                "client-id": process.env.REACT_APP_PAYPAL_CLIENTID,
                                                components: "buttons",
                                                currency: "CAD"
                                            }}>
                                                <PayPalButtons
                                                    style={{ layout: "vertical" }}
                                                    disabled={false}
                                                    forceReRender={[paypalAmount, tokenData.getToken]}
                                                    fundingSource="paypal"
                                                    createOrder={(data, actions,) => {
                                                        const taxAmount = paypalAmount * 0.13;
                                                        console.log('A', taxAmount);

                                                        return actions.order
                                                            .create({
                                                                purchase_units: [
                                                                    {
                                                                        description: `ManySeeds Balance Deposit ${paypalAmount.toFixed(2)}`,
                                                                        custom_id: tokenData.getToken,
                                                                        amount: {
                                                                            currency_code: "CAD",
                                                                            breakdown: {
                                                                                tax_total: {
                                                                                    currency_code: "CAD",
                                                                                    value: (+taxAmount).toFixed(2)
                                                                                },
                                                                                item_total: {
                                                                                    currency_code: "CAD",
                                                                                    value: paypalAmount.toFixed(2)
                                                                                }
                                                                            },
                                                                            value: (paypalAmount < 10 ? "11.30" : (paypalAmount + taxAmount).toFixed(2)),
                                                                        },
                                                                    },
                                                                ],
                                                            })
                                                            .then((orderId) => {
                                                                // Your code here after create the order
                                                                handlePPCreate(orderId);
                                                                return orderId;
                                                            });
                                                    }}
                                                    onCancel={(data, actions) => handlePPCancel(data, actions)}
                                                    onApprove={(data, actions) => {
                                                        return actions.order.capture().then(function () {
                                                            handlePPApprove(data, actions);
                                                        });
                                                    }}
                                                />
                                            </PayPalScriptProvider>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                            <p><small>Please Note: Taxes (Ontario HST) will be added to the amount selected above.</small></p>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        <Grid container style={{ marginTop: "2em" }} spacing={2}>
            <Grid item xs={12}>
                <Paper className="form-paper">
                    <h3>Transaction History
                        <div style={{ float: "right" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={e => setShowExportDialog(true)}
                            >Export Data</Button>
                        </div>
                    </h3>
                    <Accordion expanded={showFilterAccordion} onChange={e => setShowFilterAccordion(sfa => !sfa)}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography style={{ fontSize: 20 }}>Filter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container style={{ marginTop: "2em" }} spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <DatePicker
                                            disableToolbar
                                            variant="inline"
                                            label="Range Start"
                                            value={dateRangeStart}
                                            style={{ width: "100%" }}
                                            onChange={e => handleDateChange('start', e)}
                                        />&nbsp;
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <DatePicker
                                            disableToolbar
                                            variant="inline"
                                            label="Range End"
                                            value={dateRangeEnd}
                                            style={{ width: "100%" }}
                                            onChange={e => handleDateChange('end', e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} style={{ alignSelf: "center" }}>
                                        <Button variant="contained" color="primary" onClick={e => handleSubmit()}>Submit Filters</Button>
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </AccordionDetails>
                    </Accordion>
                    <TableContainer component={Paper} style={{ marginTop: "2em" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={20}>Type</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                    <TableCell>Notes</TableCell>
                                    <TableCell>Process Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactionsData && transactionsData.getAdvertiserTransactions.length > 0 ?
                                    transactionsData.getAdvertiserTransactions.map((transx: any) => {
                                        return <TableRow key={transx.id}>
                                            <TableCell>{transx.transactionType.toLocaleUpperCase()}</TableCell>
                                            <TableCell align="right" style={{ color: (transx.transactionType === "deposit" ? "green" : "red") }}>
                                                ${transx.amount.toFixed(2)}
                                            </TableCell>
                                            <TableCell>{transx.note}</TableCell>
                                            <TableCell>{new Date(transx.createdAt).toDateString()}</TableCell>
                                        </TableRow>
                                    })
                                    :
                                    !transactionsLoading &&
                                    <TableRow>
                                        <TableCell colSpan={4}>No transactions to display</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
        <Dialog open={showExportDialog} title="Export Format" onClose={() => setShowExportDialog(show => !show)} maxWidth="sm">
            <DialogTitle>Export Data</DialogTitle>
            <DialogContent>
                <TextField
                    select
                    disabled={exportLoading}
                    label="Export Format"
                    type="number"
                    value={exportFormat}
                    onChange={e => {
                        setExportFormat(e.target.value);
                    }}
                    fullWidth
                >
                    <MenuItem
                        key="exportExcel"
                        value="excel"
                    >Excel (XLSX)</MenuItem>
                    <MenuItem
                        key="exportCsv"
                        value="csv"
                    >CSV</MenuItem>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button disabled={exportLoading} variant="outlined" color="secondary" onClick={e => {
                    exportData()
                }}>{exportLoading && <CircularProgress size={20} style={{ marginRight: "20px" }} />}Export</Button>
            </DialogActions>
        </Dialog>
    </>
}
