import React, {
    useState,
    useEffect,
    PropsWithChildren,
    useContext,
    createContext,
} from 'react'
import gql from 'graphql-tag'
import {
    useQuery,
    useMutation,
    useApolloClient,
    useLazyQuery,
    FetchResult,
} from '@apollo/client'
import { User } from '../generated/graphql'
import { loader } from 'graphql.macro'

const ADMIN_LOGGED_IN = gql`
    query adminLoggedIn {
        isAdminLoggedIn
    }
`

const ADMIN_LOGIN_MUTATION = gql`
    mutation AdminLoginMutation($email: String!, $password: String!) {
        adminLogin(email: $email, password: $password) 
    }
`

const ADMIN_LOGOUT = gql`
    mutation ADMIN_LOGOUT {
        adminLogout
    }
`

const ME = loader('../graphql/Me.graphql')

const RESET_PASSWORD_MUTATION = gql`
    mutation PasswordReset($token: String!, $hashed: String!, $newPassword: String!) {
        passwordReset(token: $token, hashed: $hashed, newPassword: $newPassword)
    }
`

const FORGOT_PASSWORD_MUTATION = gql`
    mutation SendResetPassword($email: String!) {
        sendPasswordReset(email: $email)
    }
`

interface IAuthContext {
    isAdminLoggedIn?: boolean,
    adminLogin: (usrename: string, password: string) => void,
    adminLoginLoading: boolean,
    adminLoginFailed: boolean,
    logout: () => void,
    logoutLoading: boolean,
    forgotPassword: (email: string) => Promise<FetchResult<Boolean>>,
    resetPassword: (token: string, hashed: string, newPassword: string) => Promise<FetchResult<{ passwordReset: boolean }>>
    //changePassword: (
        //email: string,
        //currentPassword: string,
        //newPassword: string
    //) => Promise<FetchResult<ChangePasswordMutation>>
    me?: User,
}

export const AdminAuthContext = createContext<IAuthContext>({
    isAdminLoggedIn: false,
    adminLogin: () => null,
    adminLoginLoading: false,
    adminLoginFailed: false,
    logout: () => null,
    logoutLoading: false,
    forgotPassword: email => new Promise(r => true),
    resetPassword: (token, newPassword) => new Promise(r => true),
    //changePassword: (email, currentPassword, newPassword) => new Promise(r => true),
})

export const AdminAuthProvider = ({ children }: PropsWithChildren<{}>) => {
    const client = useApolloClient()

    const [me, setMe] = useState<User>()
    const [meQuery, { data: meData }] = useLazyQuery(ME, {fetchPolicy:"network-only"})

    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState<boolean>(false)
    const [adminLoginFailed, setAdminLoginFailed] = useState<boolean>(false)
    const [adminLoginLoading, setAdminLoginLoading] = useState<boolean>(false)
    const { loading, data } = useQuery(ADMIN_LOGGED_IN)
    const [adminLoginMutation] = useMutation(ADMIN_LOGIN_MUTATION, {
        refetchQueries: [
            {
                query: ADMIN_LOGGED_IN,
            },
        ],
    })
    const [logoutMutation, { loading: logoutLoading }] = useMutation(ADMIN_LOGOUT, {
        refetchQueries: [
            {
                query: ADMIN_LOGGED_IN,
            },
        ],
    })

    const [resetPasswordMutation] = useMutation(RESET_PASSWORD_MUTATION)
    const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD_MUTATION)
    //const [changePasswordMutation] = useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(CHANGE_PASSWORD)

    const forgotPassword = async (email: string) => {
        return forgotPasswordMutation({ variables: { email } })
    }

    const resetPassword = async (token: string, hashed: string, newPassword: string) => {
        return resetPasswordMutation({
            variables: {
                token,
                hashed,
                newPassword,
            },
        })
    }

    // const changePassword = async (email: string, currentPassword: string, newPassword: string) => {
    //   return changePasswordMutation({
    //       variables: {
    //           email,
    //           currentPassword,
    //           newPassword,
    //       },
    //   })
    // }

    const logout = () => {
        logoutMutation()
        client.resetStore()
        setIsAdminLoggedIn(false)
    }

    const adminLogin = async (email: string, password: string) => {
        setAdminLoginLoading(true)
        setAdminLoginFailed(false)
        let results: any = {};
        try {
            results = await adminLoginMutation({
                variables: { email: email, password: password },
            })
        } catch (error) {
            //console.log('useAdminAuth adminLogin Error', error)
        }
        if (results.data && results.data.adminLogin) {
            setIsAdminLoggedIn(true)
            setAdminLoginFailed(false)
            meQuery()
        } else {
            setIsAdminLoggedIn(false)
            setAdminLoginFailed(true)
        }
        setAdminLoginLoading(false)
    }

    useEffect(() => {
        setAdminLoginLoading(loading)
    }, [loading])

    useEffect(() => {
        if (data) {
            setIsAdminLoggedIn(data.isAdminLoggedIn)
            if(data.isAdminLoggedIn) {
                meQuery()
            }
        }
    }, [data, meQuery])

    useEffect(() => {
        if (meData) {
            setMe(meData.me)
        }
    }, [meData])

    return (
        <AdminAuthContext.Provider
            value={{
                isAdminLoggedIn,
                adminLogin,
                adminLoginLoading,
                adminLoginFailed,
                logout,
                logoutLoading,
                forgotPassword,
                resetPassword,
                //changePassword
                me
            }}
        >
            {children}
        </AdminAuthContext.Provider>
    )
}

export function useAdminAuth() {
    return useContext(AdminAuthContext)
}
