import { Redirect, useLocation, Route, Switch } from 'react-router-dom'
// import { useHistory } from 'react-router-dom'
import { useAdminAuth } from './hooks/useAdminAuth'
import { UserRole } from './graphql/Fragments/User.enum'

import { AdminLayout } from './layouts/AdminLayout'
import { AdminRoute } from './AdminRoute'
import { NotFound } from './pages/NotFound'

//Admin
import { Dashboard } from './pages/admin/Dashboard'
import { ManageUsers } from './pages/admin/ManageUsers'
import { ManageAdvertisers } from './pages/admin/ManageAdvertisers'
import { CreateAdvertiser } from './pages/admin/CreateAdvertiser'
import { EditAdvertiser } from './pages/admin/EditAdvertiser'
import { ManageNotForProfits } from './pages/admin/ManageNotForProfits'
import { CreateNotForProfit } from './pages/admin/CreateNotForProfit'
import { EditNotForProfit } from './pages/admin/EditNotForProfit'
import { ManageAdvertisements } from './pages/admin/ManageAdvertisements'
import { EditAdvertisement } from './pages/admin/EditAdvertisement'
import { ManageTags } from './pages/admin/ManageTags'
import { Reporting } from './pages/admin/Reporting';
import { ReportingByCharity } from './pages/admin/ReportingByCharity';
import { TransactionHistory } from './pages/admin/TransactionHistory';
import { AdminManageNews } from './pages/admin/ManageNews';
import { AdminEditNews } from './pages/admin/EditNews';
import { AdminManageVolunteers } from './pages/admin/ManageVolunteers'
import { AdminEditVolunteers } from './pages/admin/EditVolunteers'

//Advertisers
import { AdvertiserDashboard } from './pages/advertiser/Dashboard'
import { AdvertiserProfile } from './pages/advertiser/Profile'
import { AdvertiserBalance } from './pages/advertiser/Balance'
import { AdvertiserManageAdvertisements } from './pages/advertiser/ManageAdvertisements'
import { AdvertiserCreateAdvertisement } from './pages/advertiser/CreateAdvertisement'
import { AdvertiserEditAdvertisement } from './pages/advertiser/EditAdvertisement'
import { AdvertiserReporting } from './pages/advertiser/Reporting'

//NotForProfits
import { NotForProfitDashboard } from './pages/nfp/Dashboard'
import { NotForProfitProfile } from './pages/nfp/Profile'
import { NotForProfitReporting } from './pages/nfp/Reporting'
import { NotForProfitManageNews } from './pages/nfp/ManageNews';
import { NotForProfitCreateNews } from './pages/nfp/CreateNews';
import { NotForProfitEditNews } from './pages/nfp/EditNews';
import { NotForProfitManageVolunteers } from './pages/nfp/ManageVolunteers'
import { NotForProfitCreateVolunteers } from './pages/nfp/CreateVolunteers'
import { NotForProfitEditVolunteers } from './pages/nfp/EditVolunteers'

const enableDebug: boolean = (process.env.REACT_APP_ENVIRONMENT === "local" ? true : false);

const DebugRouter = ({ children }: { children: any }) => {
  //const { location } = useHistory()

  //if (enableDebug) {
    //console.log(`AdminRouter >> Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`)
  //}
  return children
}

export function useQueryParameters() {
  const { search } = useLocation();
  return new URLSearchParams(search);
}

export function AdminRouter() {
  const { adminLoginLoading, isAdminLoggedIn, me } = useAdminAuth()

  if (isAdminLoggedIn && !me) {
    return <p>Loading...</p>
  }

  return (
    <DebugRouter>
      {!adminLoginLoading && !isAdminLoggedIn && !me && <RedirectToLogin />}

      { /** For the ADMIN */}
      {adminRoutes(me)}

      { /** For the Advertisers */}
      {advertiserRoutes(me)}

      { /** For the Not For Profit */}
      {nfpRoutes(me)}
    </DebugRouter>
  )
}

function adminRoutes(meData: any) {
  if (!meData || meData.userRole !== UserRole.ADMIN) {
    return <></>
  }

  return <Switch>
    <AdminRoute exact path="/admin/dashboard" component={Dashboard} />
    <AdminRoute exact path="/admin/users/" component={ManageUsers} />

    <AdminRoute exact path="/admin/advertisers/" component={ManageAdvertisers} />
    <AdminRoute exact path="/admin/advertisers/add/" component={CreateAdvertiser} />
    <AdminRoute path="/admin/advertisers/edit/:advertiserId?" component={EditAdvertiser} />

    <AdminRoute exact path="/admin/not-for-profits/" component={ManageNotForProfits} />
    <AdminRoute exact path="/admin/not-for-profits/add/" component={CreateNotForProfit} />
    <AdminRoute path="/admin/not-for-profits/edit/:nfpId?" component={EditNotForProfit} />

    <AdminRoute exact path="/admin/tags/" component={ManageTags} />
    <AdminRoute exact path="/admin/advertisements/" component={ManageAdvertisements} />
    <AdminRoute path="/admin/advertisements/edit/:bannerId?" component={EditAdvertisement} />

    <AdminRoute path="/admin/reports/:advertiser?/:notforprofit?/:banner?" component={Reporting} />
    <AdminRoute path="/admin/charity-reports/:advertiser?/:notforprofit?/:banner?" component={ReportingByCharity} />
    <AdminRoute exact path="/admin/transactions" component={TransactionHistory} />

    <AdminRoute exact path="/admin/news" component={AdminManageNews} />
    <AdminRoute path="/admin/news/edit/:newsId?" component={AdminEditNews} />

    <AdminRoute exact path="/admin/volunteers" component={AdminManageVolunteers} />
    <AdminRoute path="/admin/volunteers/edit/:newsId?" component={AdminEditVolunteers} />

    <Route component={Route404} />
  </Switch>
}

function advertiserRoutes(meData: any) {
  if (!meData || meData.userRole !== UserRole.ADVERTISER) {
    return
  }

  return <Switch>
    <AdminRoute exact path="/admin/dashboard" component={AdvertiserDashboard} />
    <AdminRoute exact path="/admin/profile" component={AdvertiserProfile} />
    <AdminRoute exact path="/admin/balance" component={AdvertiserBalance} />

    <AdminRoute exact path="/admin/advertisements/" component={AdvertiserManageAdvertisements} />
    <AdminRoute exact path="/admin/advertisements/add/" component={AdvertiserCreateAdvertisement} />
    <AdminRoute path="/admin/advertisements/edit/:bannerId?" component={AdvertiserEditAdvertisement} />

    <AdminRoute exact path="/admin/reports" component={AdvertiserReporting} />
    <Route component={Route404} />
  </Switch>
}

function nfpRoutes(meData: any) {
  if (!meData || meData.userRole !== UserRole.NFP) {
    return
  }

  return <Switch>
    <AdminRoute exact path="/admin/dashboard" component={NotForProfitDashboard} />
    <AdminRoute exact path="/admin/profile" component={NotForProfitProfile} />
    <AdminRoute exact path="/admin/reports" component={NotForProfitReporting} />
    <AdminRoute exact path="/admin/news" component={NotForProfitManageNews} />
    <AdminRoute exact path="/admin/news/add" component={NotForProfitCreateNews} />
    <AdminRoute path="/admin/news/edit/:newsId?" component={NotForProfitEditNews} />
    <AdminRoute exact path="/admin/volunteers" component={NotForProfitManageVolunteers} />
    <AdminRoute exact path="/admin/volunteers/add" component={NotForProfitCreateVolunteers} />
    <AdminRoute path="/admin/volunteers/edit/:newsId?" component={NotForProfitEditVolunteers} />
    <Route component={Route404} />
  </Switch>
}

/**
* The below functions are only here to debug random routing issues
 */
function RedirectToLogin() {
  if (enableDebug) console.log('AdminRouter >> Redirecting to Login')
  return <Redirect from="*" to="/admin/login" />
}

function Route404() {
  if (enableDebug) console.log('AdminRouter >> Routing 404')
  return <AdminLayout><NotFound /></AdminLayout>
}