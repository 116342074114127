/**
 * NOTE: If you update this, don't forget the server/src/entity/User.ts file needs to be updated to match
 */
export enum UserRole {
    ADMIN = "admin",
    ADVERTISER = "advertiser",
    NFP = "nfp",
    USER = "user",
}

export enum UserStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
    SUSPENDED = 'suspended',
    BANNED = 'banned',
    AWAITING_APPROVAL = 'awaiting approval'
}

export enum UserAgeRanges {
    UNDER_18 = "UNDER_18",
    UNDER_25 = "UNDER_25",
    UNDER_35 = "UNDER_35",
    UNDER_45 = "UNDER_45",
    UNDER_55 = "UNDER_55",
    UNDER_65 = "UNDER_65",
    OVER_65 = "OVER_65"
}