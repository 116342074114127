import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
    Button,
    CardMedia,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Paper,
    TextField,
} from '@material-ui/core';
import { useError } from '../../hooks/useError';
import { FileUploader } from '../../components/common/FileUploader'

import {
    GetBannerQuery, GetBannerQueryVariables,
    GetLocationsQuery,
    GetLocationsQueryVariables,
    GetMyAdvertiserQuery, GetMyAdvertiserQueryVariables,
    UpdateBannerMutation, UpdateBannerMutationVariables,
    //GetTagsQuery, GetTagsQueryVariables
} from '../../generated/graphql';
import { ContentHeader } from '../../components/common/ContentHeader';

import { useForm, Controller } from 'react-hook-form';
import { UserAgeRanges } from '../../graphql/Fragments/User.enum';

const GET_MY_ADVERTISER = loader('../../graphql/Advertisers/GetMyAdvertiser.graphql')
const UPDATE_BANNER_MUTATION = loader('../../graphql/Advertisements/UpdateBanner.graphql')
const GET_BANNER = loader('../../graphql/Advertisements/GetBanner.graphql')
const GET_BANNERS_QUERY = loader('../../graphql/Advertisements/GetBanners.graphql')
const GET_LOCATIONS_QUERY = loader('../../graphql/NotForProfits/GetLocations.graphql')

export function AdvertiserEditAdvertisement() {
    const history = useHistory()
    const { bannerId } = useParams<{ bannerId: string }>()

    const { control, handleSubmit, reset, setError, setValue } = useForm();
    const { addError } = useError()

    const { data: advertiserData } = useQuery<GetMyAdvertiserQuery, GetMyAdvertiserQueryVariables>(GET_MY_ADVERTISER);
    const { data: locationData, loading: locationDataLoading } = useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GET_LOCATIONS_QUERY);

    const [activeMode, setActiveMode] = useState<"edit" | "disabled">(bannerId ? "edit" : "disabled")
    const [updateBanner, { data: updateBannerResponse, loading: updateBannerLoading, error: updateBannerError }] = useMutation<UpdateBannerMutation, UpdateBannerMutationVariables>(UPDATE_BANNER_MUTATION, {
        refetchQueries: [
            GET_BANNERS_QUERY
        ]
    });
    //const { data: tagsData, loading: tagsLoading } = useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS_QUERY, {variables:{tagType: "advertisement"}});

    const [showPreview, setShowPreview] = useState<boolean>(false)
    const [uploadFile, setUploadFile] = useState<File>();
    const [uploadType, setUploadType] = useState<"video" | "image">("video");

    const [minAdSpend, setMinAdSpend] = useState<number>(0.00);
    const [maxAdSpend, setMaxAdSpend] = useState<number>(0.00);

    const [editBannerId, setEditBannerId] = useState<number | undefined>(parseInt(bannerId.replace(/[^0-9]/i, '')))
    const { data: getBannerData, loading: getBannerLoading, error: getBannerError } = useQuery<GetBannerQuery, GetBannerQueryVariables>(GET_BANNER, {
        variables: {
            bannerId: parseInt(bannerId.replace(/[^0-9]/i, ''))
        },
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        //Unmount - destroy the data object
        return () => {
            setEditBannerId(undefined)
            setActiveMode("disabled");
            reset()
        }
    }, [reset])

    useEffect(() => {
        if (getBannerError) {
            setActiveMode("disabled")
            addError("error", "Error fetching Advertisement data: " + getBannerError.message);
            return;
        }

        if (getBannerData) {
            if (!getBannerData.getReviveBannerById) {
                return
            }

            console.log('bannerData', getBannerData.getReviveBannerById);

            setValue("name", getBannerData.getReviveBannerById.name)
            setValue("videoDuration", getBannerData.getReviveBannerById.videoDuration)
            setMinAdSpend(getBannerData.getReviveBannerById.advertiserBanner.usedBudget ? getBannerData.getReviveBannerById.advertiserBanner.usedBudget : 0);
            setMaxAdSpend((getBannerData.getReviveBannerById.advertiser.currentBalance ? +getBannerData.getReviveBannerById.advertiser.currentBalance : 0) + (getBannerData.getReviveBannerById?.advertiserBanner?.currentBudget ? getBannerData.getReviveBannerById.advertiserBanner.currentBudget : 0))
        }
    }, [addError, setValue, getBannerData, getBannerError])

    const handleUpdateBanner = async (data: any, e: any) => {
        e.preventDefault()

        if (activeMode === "disabled") {
            addError("error", "Save is disabled due to an encountered error.")
            return
        }

        if (!editBannerId) {
            addError("error", "Error saving advertiser: Invalid or no item specified.")
            return
        }

        const ageGroups = Object.keys(data.ageGroups).filter((k) => data.ageGroups[k]);
        const locations = Object.keys(data.locations).filter((k) => data.locations[k]);

        // updating
        updateBanner({
            variables: {
                bannerId: editBannerId,
                name: data.name,
                type: uploadType,
                newCurrentBudget: parseFloat(data.newCurrentBudget),
                videoDuration: parseInt(data.videoDuration),
                clickThroughUrl: data.clickThroughUrl,
                status: 1,
                file: uploadFile,
                ageGroups,
                locations
            },
            refetchQueries: [
                GET_BANNERS_QUERY
            ]
        })
    }

    const handleComplete = (method?: string) => {
        reset()
        history.push('/admin/advertisements/');
    }

    useEffect(() => {
        if (updateBannerError) {
            addError("error", updateBannerError.message);
            return;
        }

        if (updateBannerResponse) {
            if (updateBannerResponse.updateBanner.response === "success") {
                addError("success", updateBannerResponse.updateBanner.message);
                if (updateBannerResponse.updateBanner.errors && updateBannerResponse.updateBanner.errors.length > 0) {
                    updateBannerResponse.updateBanner.errors.forEach((value: any, index: number) => {
                        setError(value.field, { message: value.message });
                    })
                } else {
                    handleComplete();
                }
            }

            addError("error", "Unable to save Advertisement. Please check errors and try again.")
            if (updateBannerResponse.updateBanner.errors) {
                updateBannerResponse.updateBanner.errors.forEach((value: any, index: number) => {
                    setError(value.field, value.message)
                })
            }
        }
    }, [addError, setError, updateBannerError, updateBannerResponse])

    return <>
        <Grid container>
            <Grid item xs={12}>
                <ContentHeader
                    title="Edit Advertisement"
                    //loading={getBannerLoading || tagsLoading}
                    loading={getBannerLoading}
                    options={[
                        { text: "Return to Advertisements", action: (e: any) => history.go(-1) }
                    ]}
                />
            </Grid>
            {/* {getBannerData && tagsData && advertiserData &&  */}
            {getBannerData && advertiserData &&
                <>
                    <form style={{ width: "100%" }} onSubmit={handleSubmit(handleUpdateBanner)} name="EditBanner">
                        <Paper className="form-paper">
                            <h3>Details</h3>
                            <Grid item xs={12} container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="name"
                                        rules={{ required: true }}
                                        control={control}
                                        defaultValue={getBannerData.getReviveBannerById.name}
                                        render={({ field }) =>
                                            <TextField
                                                margin="none"
                                                label="Banner Name"
                                                fullWidth
                                                {...field}
                                            />
                                        }
                                    />
                                    <Controller
                                        name="videoDuration"
                                        rules={{ required: true }}
                                        control={control}
                                        defaultValue={getBannerData.getReviveBannerById.videoDuration}
                                        render={({ field }) =>
                                            <TextField
                                                margin="dense"
                                                label="Video Duration (in seconds)"
                                                fullWidth
                                                {...field}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="clickThroughUrl"
                                        defaultValue={getBannerData.getReviveBannerById.vastElement.clickThroughUrl}
                                        control={control}
                                        render={({ field }) =>
                                            <TextField
                                                type="url"
                                                margin="dense"
                                                label={`Click Through URL`}
                                                fullWidth
                                                {...field}
                                            />
                                        }
                                    />
                                    <Controller
                                        name="newCurrentBudget"
                                        rules={{
                                            required: true,
                                            min: minAdSpend,
                                            max: maxAdSpend
                                        }}
                                        defaultValue={getBannerData.getReviveBannerById.advertiserBanner.currentBudget}
                                        control={control}
                                        render={({ field }) =>
                                            <TextField
                                                type="number"
                                                inputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    min: minAdSpend,
                                                    max: maxAdSpend,
                                                    step:0.01
                                                }}
                                                margin="dense"
                                                label={`Remaining Budget (Max $${maxAdSpend.toFixed(2)}, Min $${minAdSpend.toFixed(2)})`}
                                                fullWidth
                                                {...field}
                                            />
                                        }
                                    />
                                    <Controller
                                        name="unusedBudgetField"
                                        defaultValue={getBannerData.getReviveBannerById.advertiserBanner.usedBudget}
                                        control={control}
                                        render={({ field }) =>
                                            <TextField
                                                type="number"
                                                inputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    disabled: true
                                                }}
                                                margin="dense"
                                                label={`Used Budget`}
                                                fullWidth
                                                {...field}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <h3>Advertisement</h3>
                            <Grid item xs={12} container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <p style={{ marginBottom: 0 }}>Acceptable Formats:</p>
                                    <ul style={{ marginTop: 0 }}>
                                        <li><b>Videos:</b> MP4, 320px wide by 480px height</li>
                                        <li><b>Images:</b> Jpeg, Gif or PNG, 320px wide by 480px height</li>
                                    </ul>
                                    <p><small>Note: Videos and images are automatically adjusted to suit the screen on the device on which they are displayed.</small></p>
                                    <br />
                                    {getBannerData && getBannerData.getReviveBannerById && getBannerData.getReviveBannerById.videoPath &&
                                        <div style={{ marginTop: "1em" }}>
                                            <Button variant="contained" color="primary" onClick={e => setShowPreview(showPreview => !showPreview)}>
                                                {showPreview ? "Hide Preview" : "Preview Existing Advertisement"}
                                            </Button>
                                        </div>
                                    }
                                    {getBannerData?.getReviveBannerById.videoPath && showPreview &&
                                        <CardMedia
                                            height={200}
                                            component="video"
                                            image={`${process.env.REACT_APP_S3_ENDPOINT}/videos/${getBannerData.getReviveBannerById.videoPath}`}
                                            title="Video Preview"
                                            controls
                                            style={{ marginTop: "1em" }}
                                        />
                                    }
                                    <FormControl style={{ marginTop: "1em" }}>
                                        <FileUploader
                                            accept={"video/mp4,application/mp4,image/jpg,image/jpeg,image/png"}
                                            handleDrop={(files: FileList) => {
                                                if (["image/jpg", "image/jpeg", "image/png"].includes(files[0].type)) {
                                                    setUploadType("image");
                                                }
                                                setUploadFile(files[0])
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <h4>Targeted Age</h4>
                                    <p>Note: It is highly recommended to leave all locations and ages selected unless necessary.</p>
                                    <div>
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_18}]`}
                                                    control={control}
                                                    defaultValue={(getBannerData.getReviveBannerById.advertiserBanner.advertiserBannerAgeGroups?.find(item => item.ageGroup === UserAgeRanges.UNDER_18))}
                                                    render={({ field: props }) =>
                                                        <Checkbox 
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"Under 18"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_25}]`}
                                                    control={control}
                                                    defaultValue={(getBannerData.getReviveBannerById.advertiserBanner.advertiserBannerAgeGroups?.find(item => item.ageGroup === UserAgeRanges.UNDER_25))}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"18 - 24"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_35}]`}
                                                    control={control}
                                                    defaultValue={(getBannerData.getReviveBannerById.advertiserBanner.advertiserBannerAgeGroups?.find(item => item.ageGroup === UserAgeRanges.UNDER_35))}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"25 - 34"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_45}]`}
                                                    control={control}
                                                    defaultValue={(getBannerData.getReviveBannerById.advertiserBanner.advertiserBannerAgeGroups?.find(item => item.ageGroup === UserAgeRanges.UNDER_45))}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"35 - 44"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_55}]`}
                                                    control={control}
                                                    defaultValue={(getBannerData.getReviveBannerById.advertiserBanner.advertiserBannerAgeGroups?.find(item => item.ageGroup === UserAgeRanges.UNDER_55))}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"45 - 54"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.UNDER_65}]`}
                                                    control={control}
                                                    defaultValue={(getBannerData.getReviveBannerById.advertiserBanner.advertiserBannerAgeGroups?.find(item => item.ageGroup === UserAgeRanges.UNDER_65))}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"55 - 64"}
                                        />
                                        <FormControlLabel control={
                                                <Controller
                                                    name={`ageGroups[${UserAgeRanges.OVER_65}]`}
                                                    control={control}
                                                    defaultValue={(getBannerData.getReviveBannerById.advertiserBanner.advertiserBannerAgeGroups?.find(item => item.ageGroup === UserAgeRanges.OVER_65))}
                                                    render={({ field: props }) =>
                                                        <Checkbox
                                                            {...props}
                                                            checked={props.value}
                                                            onChange={e => {
                                                                props.onChange(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label={"65+"}
                                        />
                                    </div>
                                    <h4 style={{marginTop:"1em"}}>Locations</h4>
                                    <div>
                                        {
                                            locationData && locationData.getLocations.map(loc => {
                                                return <FormControl key={`loc-${loc}`}>
                                                    <FormControlLabel
                                                        control={
                                                            <Controller
                                                                name={`locations[${loc}]`}
                                                                control={control}
                                                                defaultValue={(getBannerData.getReviveBannerById.advertiserBanner.advertiserBannerLocations?.find(item => item.location === loc))}
                                                                render={({ field: props }) =>
                                                                    <Checkbox
                                                                        {...props}
                                                                        checked={props.value}
                                                                        onChange={e => {
                                                                            props.onChange(e.target.checked)
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        label={loc}
                                                    />
                                                </FormControl>
                                            })
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            {/* <h3>Tags</h3>
                            <Grid item xs={12} container spacing={4}>
                                <Grid item xs={12}>
                                    {tagsData?.getTags.map((tag: any) => {
                                        let searchMyData: any;
                                        if(getBannerData?.getReviveBannerById?.tags) {
                                            searchMyData = getBannerData.getReviveBannerById.tags.filter((value: any, index:any) => {
                                                return (value.id === tag.id)
                                            })
                                        }

                                        return <FormControl key={tag.id}>
                                            <FormControlLabel
                                                control={
                                                    <Controller 
                                                        name={`tag[${tag.id}]`} 
                                                        control={control} 
                                                        defaultValue={searchMyData && searchMyData.length > 0}
                                                        render={({field: props}) => 
                                                            <Checkbox
                                                                {...props}
                                                                checked={props.value} 
                                                                onChange={e => {
                                                                    props.onChange(e.target.checked)
                                                                }}
                                                            />                                            
                                                        }
                                                    />
                                                }
                                                label={tag.name}
                                            />
                                        </FormControl>
                                    })}
                                </Grid>
                            </Grid> */}
                            <div style={{ marginTop: "1em" }}>
                                <Button disabled={updateBannerLoading || activeMode === "disabled"} type="submit" color="primary" variant="contained">
                                    {updateBannerLoading && <CircularProgress size={12} style={{ marginRight: "20px" }} />} Submit
                                </Button>
                            </div>
                        </Paper>
                    </form>
                </>
            }
        </Grid >
    </>
}