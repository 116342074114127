import React from "react";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./ApolloClient";
import { Router } from "./Router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "./Theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { AdminAuthProvider } from "./hooks/useAdminAuth";
import { ErrorProvider } from "./hooks/useErrors";
import { ErrorNotification } from './components/common/ErrorNotification'

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <AdminAuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline /> 
          <ErrorProvider>
            <Router />
            <ErrorNotification />
          </ErrorProvider>
        </ThemeProvider>
      </AdminAuthProvider>
    </ApolloProvider>
  );
}

export default App;
