import React from "react"
import { NavLink } from 'react-router-dom'
import {
    AttachMoney,
    Comment,
    Dashboard,
    DeviceHub,
    Dvr,
    EmojiPeople,
    GroupWork,
    MenuBook,
    PeopleAlt,
    Web
} from '@material-ui/icons'

export function AdminNavigation(props: {clickHandler: (e: any) => void}) {
    return <React.Fragment>
        <h2>General</h2>
        <ul>
            <li><NavLink to="/admin/dashboard" onClick={props.clickHandler}><Dashboard />Dashboard</NavLink></li>
        </ul>
        <h2>Management</h2>
        <ul>
            <li><NavLink to="/admin/users" onClick={props.clickHandler}><PeopleAlt />Users</NavLink></li>
            <li><NavLink to="/admin/advertisers" onClick={props.clickHandler}><DeviceHub />Advertisers</NavLink></li>
            <li><NavLink to="/admin/not-for-profits" onClick={props.clickHandler}><GroupWork />Not-For-Profits</NavLink></li>
            <li><NavLink to="/admin/tags" onClick={props.clickHandler}><Dvr />Tags</NavLink></li>
        </ul>
        <h2>Content</h2>
        <ul>
            <li><NavLink to="/admin/advertisements" onClick={props.clickHandler}><Web />Advertisements</NavLink></li>
            <li><NavLink to="/admin/news" onClick={props.clickHandler}><Comment />News and Events</NavLink></li>
            <li><NavLink to="/admin/volunteers" onClick={props.clickHandler}><EmojiPeople />Volunteer Positions</NavLink></li>
        </ul>
        <h2>Reporting</h2>
        <ul>
            <li><NavLink to="/admin/reports" onClick={props.clickHandler}><AttachMoney />Financial Reporting</NavLink></li>
            <li><NavLink to="/admin/charity-reports" onClick={props.clickHandler}><AttachMoney />Charity Reporting</NavLink></li>
            <li><NavLink to="/admin/transactions" onClick={props.clickHandler}><MenuBook />Transaction History</NavLink></li>
        </ul>
    </React.Fragment>
}