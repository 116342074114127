import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    CardMedia,
    Typography,
    TablePagination
} from '@material-ui/core'
import { BarChartTwoTone, Delete, Edit, PlayArrow } from '@material-ui/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { loader } from "graphql.macro";
import { useQuery, useMutation } from '@apollo/client';
import { ContentHeader } from '../../components/common/ContentHeader'
import { useError } from '../../hooks/useError';

import { BannerStatus } from '../../graphql/Fragments/Banner.enum';

import {
    GetBannersQuery, GetBannersQueryVariables,
    UpdateBannerStatusMutation, UpdateBannerStatusMutationVariables,
    DeleteBannerMutation, DeleteBannerMutationVariables, ReviveBanner
} from '../../generated/graphql'
import FormLoadingOverlay from '../../components/common/FormLoadingOverlay';

const GET_BANNERS_QUERY = loader('../../graphql/Advertisements/GetBanners.graphql');
const UPDATE_BANNER_MUTATION = loader('../../graphql/Advertisements/UpdateStatus.graphql');
const DELETE_BANNER_MUTATION = loader('../../graphql/Advertisements/DeleteBanner.graphql');

export function ManageAdvertisements() {
    document.title = "Manage Advertisements - ManySeeds"
    const history = useHistory()
    const { addError } = useError()

    const { data: bannerData, loading: bannerDataLoading, error: bannerDataError, refetch: bannerDataRefetch } = useQuery<GetBannersQuery, GetBannersQueryVariables>(GET_BANNERS_QUERY)
    const [updateStatus, { loading: updateStatusLoading }] = useMutation<UpdateBannerStatusMutation, UpdateBannerStatusMutationVariables>(UPDATE_BANNER_MUTATION);
    const [deleteBanner, { data: deleteBannerResponse }] = useMutation<DeleteBannerMutation, DeleteBannerMutationVariables>(DELETE_BANNER_MUTATION);

    const [previewBanner, setPreviewBanner] = useState<ReviveBanner | undefined>()
    const [showPreviewDialog, setShowPreviewDialog] = useState<boolean>(false)

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [updatingThisBanner, setUpdatingThisBanner] = useState<number>(0)
    const [deleteThisBanner, setDeleteThisBanner] = useState<any>()

    const [tablePage, setTablePage] = useState<number>(0);
    const [tableRowsPerPage, setRowsPerPage] = useState<number>(10);

    useEffect(() => {
        //Refetch on component re-mounting
        if (bannerData && !bannerDataLoading) {
            bannerDataRefetch()
        }
    }, [])

    const handleUpdateStatus = (banner: any, newStatus: number, fromPreview?: boolean) => {
        if (![0, 1].includes(newStatus)) {
            addError("error", "Error updating status");
            return
        }

        setUpdatingThisBanner(banner.bannerId)

        updateStatus({
            variables: {
                bannerId: banner.bannerId,
                status: newStatus
            },
            refetchQueries: [GET_BANNERS_QUERY]
        }).then(() => {
            addError("success", "Successfully updated Advertisement")
            setUpdatingThisBanner(0)
            bannerDataRefetch();
        }).catch(error => {
            addError("error", "Error updating Advertisement: " + error.message)
        }).finally(() => {
            if (fromPreview) {
                setPreviewBanner(undefined)
                setShowPreviewDialog(false)
            }
        })
    }

    const handleOpenDeleteBanner = (banner?: any) => {
        setDeleteThisBanner(banner)
        setShowDeleteDialog(true)
    }

    const handleDeleteBanner = async () => {
        if (!deleteThisBanner) {
            addError("error", "An error was encountered while trying to delete the selected item.")
        }

        deleteBanner({
            variables: {
                bannerId: deleteThisBanner.bannerId
            },
            refetchQueries: [GET_BANNERS_QUERY]
        })
        setDeleteThisBanner(undefined)
    }

    useEffect(() => {
        if (deleteBannerResponse) {
            if (deleteBannerResponse.deleteBanner) {
                addError('success', "Successfully deleted Advertisement")
                bannerDataRefetch()
                return
            }
            addError('error', "Error encountered while trying to delete the selected item.")
        }
    }, [deleteBannerResponse, addError, bannerDataRefetch])

    const deleteDialogText = useMemo(() => {
        if (deleteThisBanner) {
            return <DialogContentText color="primary">
                Are you sure you want to PERMANENTLY delete {deleteThisBanner.name}?<br />
                This may effect any stats that have been accrued for this advertisement (if previously active).<br />
                <br /><strong>This action cannot be undone</strong>
            </DialogContentText>
        }
    }, [deleteThisBanner])

    const showPreview = (banner: any) => {
        setPreviewBanner(banner)
        setShowPreviewDialog(true)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setTablePage(newPage);
    };

    const previewDialog = useMemo(() => {
        return <>
            {previewBanner && previewBanner.videoPath ?
                <>
                    <DialogContent>
                        <CardMedia
                            component="video"
                            image={previewBanner.videoPath}
                            title="Video Preview"
                            controls
                            style={{
                                marginBottom: "1em",
                                height: "500px",
                                maxHeight: "95%",
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Button disabled={updateStatusLoading} variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={e => {
                                    handleUpdateStatus(previewBanner, BannerStatus.ACTIVE, true)
                                }}>{updateStatusLoading && <CircularProgress size={20} style={{ marginRight: "10px" }} />}Approve
                                </Button>
                                <Button disabled={updateStatusLoading} variant="outlined" color="secondary" onClick={e => {
                                    handleUpdateStatus(previewBanner, BannerStatus.INACTIVE, true)
                                }}>{updateStatusLoading && <CircularProgress size={20} style={{ marginRight: "10px" }} />}Decline</Button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                                <Button variant="outlined" color="secondary" onClick={e => {
                                    setPreviewBanner(undefined)
                                    setShowPreviewDialog(false)
                                }}>Close Preview
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </>
                :
                <DialogContent><Typography>No Preview to Display</Typography></DialogContent>
            }
        </>
    }, [handleUpdateStatus, updateStatusLoading, previewBanner])

    useEffect(() => {
        if (bannerDataError) {
            addError("error", "Error fetching data: " + bannerDataError.message)
        }
    }, [bannerDataError, addError])

    return (
        <React.Fragment>
            <ContentHeader
                title="Advertisements"
                loading={bannerDataLoading}
                options={[
                    { text: "Refresh Data", action: (e: any) => { bannerDataRefetch() }, devOnly: true }
                ]}
            />
            <TableContainer component={Paper}>
                <Table
                    style={{ width: '100%' }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Advertiser</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Views</TableCell>
                            <TableCell>Budgeted</TableCell>
                            <TableCell>Remaining</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell width="240px" align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bannerDataLoading && !bannerData &&
                            <TableRow>
                                <TableCell colSpan={15} align="center">Fetching Data...</TableCell>
                            </TableRow>
                        }
                        {!bannerDataLoading && bannerData && bannerData.getAllReviveBanners.length <= 0 &&
                            <TableRow>
                                <TableCell colSpan={15} align="center">No Records to Display</TableCell>
                            </TableRow>
                        }
                        {bannerData && bannerData.getAllReviveBanners
                            .slice(tablePage * tableRowsPerPage, tablePage * tableRowsPerPage + tableRowsPerPage)
                            .map((banner: any) => {
                                const currentBudget: number = banner.advertiserBanner.currentBudget ? banner.advertiserBanner.currentBudget : 0.00;
                                const usedBudget: number = banner.advertiserBanner.usedBudget ? banner.advertiserBanner.usedBudget : 0.00;
                                const remainingBudget = currentBudget - usedBudget;

                                return (<TableRow key={banner.bannerId}>
                                    <TableCell>{banner.name}</TableCell>
                                    <TableCell>{banner.advertiser.name}</TableCell>
                                    <TableCell>{new Date(banner.updated).toDateString()}</TableCell>
                                    <TableCell>{banner.stats.impressions}</TableCell>
                                    <TableCell>${currentBudget.toFixed(2)}</TableCell>
                                    <TableCell>${remainingBudget.toFixed(2)}</TableCell>
                                    <TableCell style={{ position: "relative" }}>
                                        <FormLoadingOverlay show={(updatingThisBanner === banner.bannerId)} background={false} size={25} />
                                        <Select
                                            fullWidth
                                            value={banner.status}
                                            onChange={e => {
                                                const newStatus = e.target.value as number;
                                                handleUpdateStatus(banner, newStatus)
                                            }}
                                        >
                                            <MenuItem value={BannerStatus.ACTIVE}>Approved</MenuItem>
                                            <MenuItem value={BannerStatus.INACTIVE}>Disabled</MenuItem>
                                            <MenuItem value={BannerStatus.AWAITING_APPROVAL}>Awaiting Approval</MenuItem>
                                            <MenuItem value={BannerStatus.DELETED}>Deleted</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Preview Video">
                                            <IconButton onClick={e => showPreview(banner)}>
                                                <PlayArrow />
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton
                                            onClick={e => {
                                                history.push('/admin/reports/?banner=' + banner.bannerId)
                                            }}
                                        >
                                            <Tooltip title="Advertiser Stats Performance">
                                                <BarChartTwoTone />
                                            </Tooltip>
                                        </IconButton>
                                        <Tooltip title="Edit">
                                            <IconButton
                                                onClick={e => {
                                                    e.preventDefault()
                                                    history.push('/admin/advertisements/edit/' + banner.bannerId)
                                                }}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton>
                                                <Delete onClick={e =>
                                                    handleOpenDeleteBanner(banner)
                                                } />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component={Paper}
                count={(bannerData?.getAllReviveBanners ? bannerData?.getAllReviveBanners.length : 0)}
                rowsPerPage={tableRowsPerPage}
                page={tablePage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={showDeleteDialog} title={"Delete Advertisement"}>
                <DialogTitle>Confirm Permanent Deletion</DialogTitle>
                <DialogContent>
                    {deleteDialogText}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary"
                        onClick={e => {
                            setShowDeleteDialog(false)
                            setDeleteThisBanner(null)
                        }}
                    >Cancel</Button>
                    <Button color="primary" variant="contained"
                        onClick={e => {
                            setShowDeleteDialog(false);
                            addError("info", "Deleting Banner...");
                            handleDeleteBanner()
                        }}
                    >Delete</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showPreviewDialog} title="Preview Advertisement">
                <DialogTitle>Preview Advertisement</DialogTitle>
                {previewDialog}
            </Dialog>
        </React.Fragment>
    )
}
