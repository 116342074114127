import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { HeaderBar } from './HeaderBar'
import { Navigation } from './Navigation'
import "../assets/css/layout.admin.css";

export function AdminLayout({ children }: React.PropsWithChildren<{}>) {
    useEffect(() => {
        document.body.classList.add("admin")
    }, [])
    
    return (
        <div className="admin-container">
            <HeaderBar />
            <Grid container spacing={0} direction="row" className="grid-container">
                <Grid item className="nav-container">
                    <Navigation />
                </Grid>
                <Grid item className="body-container">
                    <div className="body-padding">
                        {children}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}